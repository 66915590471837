import DatePicker from "react-datepicker";

const CustomDatePicker = ({
  type,
  placeholder,
  disabled,
  value,
  onChange,
  style,
  className,
  label,
  leftIcon,
  rightIcon,
  error,
  defaultValue,
  rule,
  onKeyPress,
  maxLength,
  selected,
  dateFormat,
  timeFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  readOnly,
  displayColumn,
}) => {
  return (
    <div className={`input-wrapper ${error ? "error" : ""}`}>
      <div className={`${!displayColumn ? "row" : ""}`}>
        <div className={`${!displayColumn ? "col-md-2 d-flex align-items-center" : "mb-2"}`}>
          <span style={{ fontWeight: "700", fontSize: "14px" }}>{label}</span>
        </div>
        <div className={`${!displayColumn ? "col-md-10" : ""}`}>
          <div className="input-box-wrapper">
            {leftIcon ? leftIcon : ``}
            <DatePicker
              type={type}
              placeholder={placeholder}
              defaultValue={defaultValue}
              rule={rule}
              onChange={onChange}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              timeCaption={timeCaption}
              minDate={false}
              selected={selected}
              className={className}
              value={value}
              disabled={disabled}
              style={style}
              onKeyPress={onKeyPress}
              maxLength={maxLength}
              readOnly={readOnly}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              popperModifiers={[
                {
                  name: "arrow",
                  options: { padding: ({ popper }) => popper.width / 2 },
                },
              ]}
            />
            {rightIcon ? rightIcon : ``}
          </div>
          <span className="error-msg">{error}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
