import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ModalDelete from "../../../../components/report/ModalDelete";
import { tambahReport } from "../../../../store/actions/farm/report";

export const ModalDeletePenjualan = (props) => {
  const handleDelete = () => {
    // remove foto specific index
    props.setFoto(props.foto.filter((f , _index) => props.data.index !== _index))
    props.setFotoPreview(props.fotoPreview.filter((fprev , _index) => props.data.index !== _index))
    // hide modal
    props.setPenjualan(props.penjualan.filter((penjualan, _index) => props.data.index !== _index));
    props.setError(props.error.filter((error, _index) => props.data.index !== _index));
    setTimeout(() => {
      props.setShow(false);
    }, 50)
  };
  return (
    <ModalDelete show={props.show} setShow={props.setShow} handleDelete={handleDelete} disableSubmit={props?.penjualan ? false : true} />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps,{tambahReport})(ModalDeletePenjualan);
