import {
  UPDATE_DOC_PAGE,
  LIST_DOC_PENDING,
  LIST_DOC_SUCCESS,
  LIST_DOC_ERROR,
  UPDATE_FILTER_DOC,
  UPDATE_SEARCH_DOC,
  TAMBAH_DOC_PENDING,
  TAMBAH_DOC_SUCCESS,
  TAMBAH_DOC_ERROR,
  EDIT_DOC_PENDING,
  EDIT_DOC_SUCCESS,
  EDIT_DOC_ERROR,
  DELETE_DOC_PENDING,
  DELETE_DOC_SUCCESS,
  DELETE_DOC_ERROR,
} from "../../actions/doc";

const initialState = {
  listDocs: [],
  pending: false,
  error: null,
  pageData: {
    total: null,
    lengthItem: 10,
    page: 1,
  },
  filters: {
    grade_doc: {},
  },
  search_text: "",
};

export const listDoc = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        listDocs: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
      };
    case LIST_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_DOC_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_DOC:
      return {
        ...state,
        filters: {
          grade_doc: action.data.grade_doc,
        },
      };
    case UPDATE_SEARCH_DOC:
      return {
        ...state,
        search_text: action.data,
      };
    default:
      return state;
  }
};

export const tambahDoc = (state = initialState, action) => {
  switch (action.type) {
    case TAMBAH_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case TAMBAH_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case TAMBAH_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const editDoc = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export function deleteDoc(state = initialState, action) {
  switch (action.type) {
    case DELETE_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteDoc: action.data,
      };
    case DELETE_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListDoc = (state) => state.listDocs;
export const getListDocPending = (state) => state.pending;
export const getListDocError = (state) => state.error;
export const getPageData = (state) => state.pageData;
export const getFilters = (state) => state.filters;
export const getSearchText = (state) => state.search_text;
export const getDeleteDocPending = (state) => state.pending;
export const getDeleteDoc = (state) => state.deleteDoc;
export const getDeleteDocError = (state) => state.error;
