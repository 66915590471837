import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Form } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../components/commons/forms/select";
import Input from "../../components/commons/forms/authentication/input";
import NumericFormat from "react-number-format";
import TextArea from "../../components/commons/forms/textarea";
import { connect } from "react-redux";
import { numberWithCommas } from "../../utils/constants";

import { listKepKan, listFarm, editFarm, detailFarm } from "../../store/actions/farm";

import { getListKepKan, getFilters, getPageData, getSearchText } from "../../store/reducers/farm";
import { useHistory } from "react-router-dom";

const ModalEditFarm = (props) => {
  const history = useHistory();
  const [id, setId] = useState("");
  const [kapasitas_kandang, setKapasitas] = useState();
  const [roleNameKepkan, setRoleNameKepkan] = useState("");
  const [kepala_kandang, setKepalaKandang] = useState("");
  const [lokasi_kandang, setLokasiKandang] = useState("");
  const [name, setName] = useState("");
  const [status_farm, setStatusFarm] = useState("");

  const [errorName, setErrorName] = useState("");
  const [errorKepalaKandang, setErrorKepalaKandang] = useState("");
  const [errorStatusFarm, setErrorStatusFarm] = useState("");
  const [errorKapasitas, setErrorKapasitas] = useState("");
  const [errorLokasiKandang, setErrorLokasiKandang] = useState("");

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const statusOptions = [
    { value: "Kosong", label: "Kosong" },
    { value: "Persiapan Kandang", label: "Persiapan Kandang" },
    { value: "Cuci Kandang", label: "Cuci Kandang" },
    { value: "Chick In", label: "Chick In" },
    { value: "Panen", label: "Panen" },
  ];

  const { listKepKanSuccess, pageData, filters, searchText } = props;

  useEffect(() => {
    props.listKepKan();
  }, []);

  useEffect(() => {
    if (props.data) {
      setId(props.data.id);
      setName(props.data.name);
      setStatusFarm(props.data.status_farm);
      setKapasitas(numberWithCommas(String(props.data.kapasitas_kandang)));
      setLokasiKandang(props.data.lokasi_kandang);

      setErrorName("");
      setErrorLokasiKandang("");
      setErrorStatusFarm("");
      setErrorKapasitas("");

      if (props.data.kepala_kandang) {
        setRoleNameKepkan(props.data.kepala_kandang.name);
        setKepalaKandang(props.data.kepala_kandang.id);
        setErrorKepalaKandang("");
      }
    }
  }, [props.data]);

  const isStatus = (l) => {
    return statusOptions.indexOf(l) >= 0;
  };

  const handleInput = (e, set, error, customRule = false) => {
    let value;
    let label = true;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
      label = false;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (label) {
      if (!e.target) {
        setRoleNameKepkan(isStatus(e) ? roleNameKepkan : e.label);
      }
    }

    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    data.name ? setErrorName("") : setErrorName("Nama Kandang wajib diisi");
    data.kepala_kandang ? setErrorKepalaKandang("") : setErrorKepalaKandang("Kepala Kandang wajib diisi");
    data.status_farm ? setErrorStatusFarm("") : setErrorStatusFarm("Status Kandang wajib diisi");
    data.kapasitas_kandang ? setErrorKapasitas("") : setErrorKapasitas("Kapasitas Kandang wajib diisi");
    data.lokasi_kandang ? setErrorLokasiKandang("") : setErrorLokasiKandang("Lokasi Kandang wajib diisi");
  };

  const handleButtonBatal = (e) => {
    setDefaultState();
    props.setShow(false);
  };
  const submitForm = () => {
    setDisableBtnSubmit(true);
    const data = {
      id,
      kapasitas_kandang,
      kepala_kandang,
      lokasi_kandang,
      name,
      status_farm,
    };

    handleError(data);

    for (var key in data) {
      if (data[key] == "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    props.editFarm(data, successEdit, failedEdit);
  };

  const successEdit = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
      props.listFarm({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        kepala_kandang: filters.kepala_kandang.value,
        status_farm: filters.status_farm.value,
      });
      setDisableBtnSubmit(false);
      if (props?.fromDetail) history.push({ pathname: "/farmmanagement/" });
    }, 1000);
  };

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };

  const setDefaultState = () => {
    setId(props.data.id);
    setName(props.data.name);
    setRoleNameKepkan(props.data.kepala_kandang.name);
    setKepalaKandang(props.data.kepala_kandang.id);
    setStatusFarm(props.data.status_farm);
    setKapasitas(props.data.kapasitas_kandang);
    setLokasiKandang(props.data.lokasi_kandang);

    setErrorName("");
    setErrorKepalaKandang("");
    setErrorLokasiKandang("");
    setErrorStatusFarm("");
    setErrorKapasitas("");
  };

  return (
    <>
      <Modal size={"md"} onHide={handleButtonBatal} show={props.show} centered>
        <ModalHeader closeButton>Edit Kandang</ModalHeader>
        <ModalBody>
          <Input label={"Nama Kandang"} placeholder={"Masukkan nama kandang"} onChange={(e) => handleInput(e, setName, setErrorName)} value={name || ""} rule={"Nama Kandang"} error={errorName} />
          <SelectComponent
            options={listKepKanSuccess ? listKepKanSuccess : {}}
            label={"Kepala Kandang"}
            value={kepala_kandang ? { value: kepala_kandang, label: roleNameKepkan } : { value: "", label: "Masukkan kepala kandang" }}
            isAsync={true}
            asyncUrl={"/v1/master_data/user?role_code=kepalakandang"}
            onChange={(e) => handleInput(e, setKepalaKandang, setErrorKepalaKandang, "Kepala Kandang")}
            isError={errorKepalaKandang !== ``}
            error={errorKepalaKandang}
          />

          <div className="mb-3"></div>

          <SelectComponent
            options={statusOptions}
            label={"Status Kandang"}
            value={status_farm ? { value: status_farm, label: status_farm } : { value: "", label: "Masukkan status kandang" }}
            isAsync={true}
            onChange={(e) => handleInput(e, setStatusFarm, setErrorStatusFarm, "Status Kandang")}
            isError={errorStatusFarm !== ``}
            error={errorStatusFarm}
          />
          <NumericFormat
            label={"Kapasitas Kandang"}
            customInput={Input}
            placeholder={"Masukkan kapasitas kandang"}
            value={kapasitas_kandang || ""}
            thousandSeparator="."
            decimalSeparator=","
            onChange={(e) => handleInput(e, setKapasitas, setErrorKapasitas)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            rule={`Kapasitas Kandang`}
            error={errorKapasitas}
          />
          <TextArea
            label={"Lokasi Kandang"}
            placeholder={"Masukkan lokasi kandang"}
            onChange={(e) => handleInput(e, setLokasiKandang, setErrorLokasiKandang, "Lokasi Kandang")}
            error={errorLokasiKandang}
            isError={errorLokasiKandang !== ``}
            value={lokasi_kandang || ""}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlined"
            disableElevation
            className="btn-btl-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleButtonBatal}
          >
            Batal
          </Button>

          <Button
            variant="contained"
            disableElevation
            className="btn-simpan-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}
          >
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ listFarm, listKepKan }) => {
  return {
    listKepKanSuccess: getListKepKan(listKepKan),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, { listKepKan, listFarm, editFarm, detailFarm })(ModalEditFarm);
