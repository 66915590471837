import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ModalDelete from "../../../../components/report/ModalDelete";
import { deleteObat } from "../../../../store/actions/farm/report";

export const ModalDeleteObat = (props) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const handleDelete = () => {
    setDisableSubmit(true);
    if (props.data.id) props.deleteObat(props.data.id);
    props.setObat(props.obat.filter((obat, _index) => props.data.index !== _index));
    props.setError(props.error.filter((error, _index) => props.data.index !== _index));
    props.setShow(false);
    setTimeout(() => setDisableSubmit(false), 1000);
  };
  return <ModalDelete show={props.show} setShow={props.setShow} handleDelete={handleDelete} disableSubmit={disableSubmit} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { deleteObat };

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteObat);
