import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import AccordionBase from "../../../../components/report/AccordionBase";
import AccordionList from "../../../../components/report/AccordionList";
import ListGroupItem from "../../../../components/report/ListGroupItem";
import ModalFoto from "./ModalFoto";
import { AccessTime, ArrowBack, DateRange, Home, Timelapse } from "@mui/icons-material";
import { Button } from "@mui/material";
import { detailReport } from "../../../../store/actions/farm/report";
import { getDetailReportSuccess, getDetailReportError, getDetailReportPending } from "../../../../store/reducers/farm/report";
import { numberWithCommas } from "../../../../utils/constants";
import { toCapitalize, dateFormat } from "../../../../utils";
import AccordionListGroup from "../../../../components/report/AccordionListGroup";
import ListGroupPenjualan from "../../../../components/report/ListGroupPenjualan";
import { isEmpty, toInteger } from "lodash";
import moment from "moment/moment";
import 'moment/locale/id';

export const Index = (props) => {
  const id_report = props.match.params.id_report;
  const { detailReportSuccess, detailReportPending, detailReportError } = props;
  const history = useHistory();
  const [showModalFoto, setShowModalFoto] = useState(false);
  const [foto, setFoto] = useState("");
  const [id_penjualan, setIdPenjualan] = useState("");

  useEffect(() => {
    props.detailReport(id_report);
  }, []);

  const handleBack = () => {
    history.push(props.match.url.split("/").slice(0, 4).join("/"));
  };

  const handleEditClick = (id_farm, id_report) => {
    history.push(`/farmmanagement/${id_farm}/report/${id_report}/edit`);
  };

  const isCanEdit = (timestamp) => {
    let date = new Date(0);
    date.setUTCSeconds(timestamp);
    return date.toDateString() === new Date().toDateString();
  };

  const toFloat = (string = "") => {
    return parseFloat(string).toFixed(0);
  };

  if (detailReportPending)
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ minWidth: "100%", minHeight: "100vh" }}>
        Mengambil data report...
      </div>
    );
  else if (detailReportError)
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ minWidth: "100%", minHeight: "100vh" }}>
        Gagal mengambil data report...
      </div>
    );
  else if (isEmpty(detailReportSuccess)) return null;
  else {
    return (
      <Container fluid style={{ overflow: "auto" }}>
        <ModalFoto show={showModalFoto} setShow={setShowModalFoto} foto={foto} id_penjualan={id_penjualan} />
        <Row>
          <Col md={4} style={{ minHeight: "100vh", padding: "0px 50px 0px 40px", borderRight: "5px solid #F3F3F8" }}>
            <div className="d-flex align-items-center" style={{ margin: "50px 0px 50px 0px" }}>
              <ArrowBack sx={{ cursor: "pointer" }} onClick={handleBack} />
              <span
                className="text-extra-bold"
                style={{
                  display: "inline",
                  fontSize: "24px",
                  fontFamily: "PlusJakartaSans-Bold",
                  lineHeight: "36px",
                  marginLeft: "26px",
                }}
              >
                Report
              </span>
            </div>
            <ListGroup style={{ borderRadius: "12px" }}>
              <ListGroupItem icon={<Home style={{ fontSize: "32px" }} color="primary" />} label={"Nama Kandang"} value={detailReportSuccess.daily_report.nama_kandang} />
              <ListGroupItem icon={<DateRange style={{ fontSize: "32px" }} color="primary" />} label={"Tanggal"} value={dateFormat(detailReportSuccess.tanggal)} />
              <ListGroupItem icon={<AccessTime style={{ fontSize: "32px" }} color="primary" />} label={"Jam Pengisian"} value={detailReportSuccess.daily_report.jam_pengisian} />
              <ListGroupItem icon={<Timelapse style={{ fontSize: "32px" }} color="primary" />} label={"Umur Ayam"} value={detailReportSuccess.daily_report.umur_ayam + " hari"} />
            </ListGroup>
          </Col>
          <Col md={8} style={{ padding: "0px 57px 0px 50px" }}>
            <h1 className="judul-header">Rincian</h1>
            <AccordionBase title={"Daily Report"}>
              <AccordionList label={"Populasi Saat Chick In"} value={numberWithCommas(detailReportSuccess.daily_report.populasi_chick_in) + " ekor"} />
              <AccordionList label={"Kematian"} value={numberWithCommas(detailReportSuccess.daily_report.kematian)} />
              <AccordionList label={"Pakan"} value={detailReportSuccess.daily_report.kode_pakan + " / " + detailReportSuccess.daily_report.pakan} />
              <AccordionList label={"Jumlah Pakan Masuk"} value={numberWithCommas(detailReportSuccess.daily_report.jml_pakan_masuk + " sak")} />
              <AccordionList label={"Jumlah Habis Pakan"} value={numberWithCommas(detailReportSuccess.daily_report.jml_habis_pakan + " sak")} />
              <AccordionList label={"Berat Seluruh Ayam"} value={numberWithCommas(detailReportSuccess.daily_report.berat_seluruh_ayam + " kg")} />
              <AccordionList label={"Populasi Saat Ini"} value={numberWithCommas(detailReportSuccess.daily_report.populasi_saat_ini + " ekor")} />
              <AccordionList label={"FCR"} value={numberWithCommas(detailReportSuccess.daily_report.fcr)} />
              <AccordionList label={"Deplesi"} value={numberWithCommas(detailReportSuccess.daily_report.deplesi) + " %"} color="#FF4263" />
            </AccordionBase>
            <AccordionBase title={"Penjualan Hasil Panen"}>
              {detailReportSuccess.panen.penjualan.length !== 0 ? (
                <>
                  <AccordionListGroup>
                    {detailReportSuccess.panen.penjualan.map((penjualan, i) => (
                      <ListGroupPenjualan key={i} penjualan={penjualan} setShowModalFoto={setShowModalFoto} setFoto={setFoto} setIdPenjualan={setIdPenjualan} />
                    ))}
                  </AccordionListGroup>
                  <AccordionList label={"Berat Rata-Rata"} value={detailReportSuccess.panen?.berat + " kg"} />
                  <AccordionList label={"Sisa Stok Ayam"} value={numberWithCommas(detailReportSuccess.panen.stok_ayam)} />
                  <AccordionList label={"Kumulatif Umur"} value={numberWithCommas(detailReportSuccess.panen.cum_umur)} />
                  <AccordionList label={"RUP"} value={numberWithCommas(detailReportSuccess.panen.rup)} />
                  <AccordionList
                    label={"Indeks Performance (IP)"}
                    value={
                      <span
                        style={{
                          background:
                            toFloat(detailReportSuccess.panen?.ip) < 300 ? "#FF4263" : toFloat(detailReportSuccess.panen?.ip) >= 300 && toFloat(detailReportSuccess.panen?.ip) <= 350 ? "#E8A720" : "#1CB289",
                          color: "#FFF",
                          borderRadius: "4px",
                          padding: "6px 12px",
                        }}
                      >
                        {toFloat(detailReportSuccess.panen?.ip)}
                      </span>
                    }
                  />
                </>
              ) : (
                <AccordionListGroup>Tidak ada data panen</AccordionListGroup>
              )}
            </AccordionBase>
            <AccordionBase title={"DOC"}>
              {detailReportSuccess?.doc_report?.length !== 0 ? (
                <>
                  <AccordionListGroup>
                    <Row>
                      <Col className="text-bold">Tanggal Masuk</Col>
                      <Col className="text-bold">Merk</Col>
                      <Col className="text-bold">Jumlah</Col>
                      <Col className="text-bold">Bobot (gr)</Col>
                      <Col className="text-bold">Kondisi</Col>
                    </Row>
                  </AccordionListGroup>
                  {detailReportSuccess?.doc_report?.map((item) => (
                    <AccordionListGroup key={item.id}>
                      <Row>
                        <Col>{moment(item.tanggal_masuk, "DD/MM/YYYY")?.locale('id')?.format("DD MMMM YYYY")}</Col>
                        <Col>{item.doc?.name_doc}</Col>
                        <Col>{numberWithCommas(item.jumlah)}</Col>
                        <Col>{item.bobot}</Col>
                        <Col>{item.kondisi}</Col>
                      </Row>
                    </AccordionListGroup>
                  ))}
                </>
              ) : (
                <AccordionListGroup>Tidak ada data DOC</AccordionListGroup>
              )}
            </AccordionBase>
            <AccordionBase title={"Pemakaian Obat & Vitamin"}>
              {detailReportSuccess.obat.length !== 0 ? (
                detailReportSuccess.obat.map((obat) => (
                  <AccordionListGroup key={obat.id}>
                    <Row>
                      <Col className="text-bold">Merk</Col>
                      <Col className="text-center">{obat.merk}</Col>
                      <Col className="text-end">{numberWithCommas(obat.jumlah)} buah</Col>
                    </Row>
                  </AccordionListGroup>
                ))
              ) : (
                <AccordionListGroup>Tidak ada data obat</AccordionListGroup>
              )}
            </AccordionBase>
            <AccordionBase title={"Pemanas"}>
              {detailReportSuccess.pemanas ? (
                <>
                  <AccordionList label={"Jenis Pemanas"} value={detailReportSuccess.pemanas.nama} />
                  <AccordionList label={"Jumlah"} value={numberWithCommas(detailReportSuccess.pemanas.jumlah)} />
                </>
              ) : (
                <AccordionListGroup>Tidak ada data pemanas</AccordionListGroup>
              )}
            </AccordionBase>
            <AccordionBase title={"Sekam"}>
              {detailReportSuccess.kuantitas_sekam ? (
                <AccordionList label={"Kuantitas (sak)"} value={numberWithCommas(detailReportSuccess.kuantitas_sekam)} />
              ) : (
                <AccordionListGroup>Tidak ada data sekam</AccordionListGroup>
              )}
            </AccordionBase>
            <AccordionBase title={"Status Kandang"}>
              {detailReportSuccess.status_kandang ? (
                <AccordionList label={"Status Kandang"} value={toCapitalize(detailReportSuccess.status_kandang)} />
              ) : (
                <AccordionListGroup>Tidak ada data status kandang</AccordionListGroup>
              )}
            </AccordionBase>
            <div className="d-flex justify-content-end" style={{ marginBottom: "55px" }}>
              <Button variant="outline" className="btn-back-report" onClick={handleBack} disableElevation>
                Kembali
              </Button>
              <Button
                variant="contained"
                className="btn-edit-report"
                onClick={() => {
                  handleEditClick(detailReportSuccess.id_farm, detailReportSuccess.id);
                }}
                disableElevation
                disabled={isCanEdit(detailReportSuccess.created_at) ? false : true}
              >
                Edit
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

const mapStateToProps = ({ detailReport }) => ({
  detailReportSuccess: getDetailReportSuccess(detailReport),
  detailReportPending: getDetailReportPending(detailReport),
  detailReportError: getDetailReportError(detailReport),
});

const mapDispatchToProps = { detailReport };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
