import React from "react";
import { ListGroup, Row, Col } from "react-bootstrap";

const ListGroupItem = ({ icon, label, value }) => {
  return (
    <ListGroup.Item style={{ padding: "1.25rem 1.5rem 1.25rem 1.5rem" }}>
      <Row>
        <Col md={3} className={"d-flex align-items-center justify-content-center"}>
          {icon}
        </Col>
        <Col md={9}>
          <div style={{ fontSize: "14px", marginBottom: "8px" }}>{label}</div>
          <div style={{ fontSize: "18px" }} className="text-bold">
            {value}
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ListGroupItem;
