import React from "react";
import imagePlaceholderRounded from "../../../../assets/img/imagePlaceholderRounded.png";

const InputFoto = ({ value, onChange, style, className, label, prevImage, error, rule }) => {
  return (
    <div className={`input-wrapper ${error ? "error" : ""}`}>
      <span style={{ fontWeight: "700", fontSize: "14px" }}>{label}</span>
      <div className="input-box-wrapper" style={{ background: "#E5E5F0" }}>
        <img src={prevImage ? prevImage : imagePlaceholderRounded} alt="Preview Image" height={50} style={{ margin: "15px" }} />
        <input type="file" rule={rule} onChange={onChange} className={"form-control-file" + className} value={value} style={{ ...style, background: "none" }} accept="image/png, image/jpg, image/jpeg" />
      </div>
      <span className="error-msg">{error}</span>
    </div>
  );
};

export default InputFoto;
