import React from "react";
import { ListGroup, Col, Row } from "react-bootstrap";
import { numberWithCommas } from "../../utils/constants";

const ListGroupPenjualan = ({ penjualan, setShowModalFoto, setFoto, setIdPenjualan }) => {
  const handleFotoClick = () => {
    setFoto(penjualan.foto);
    setIdPenjualan(penjualan.id);
    setShowModalFoto(true);
  };
  return (
    <ListGroup style={{ borderRadius: "12px", marginBottom: "30px" }} key={penjualan.id}>
      <ListGroup.Item style={{ padding: "10px 26px" }}>
        <Row>
          <Col md={2} style={{ color: "#A5A5C0" }} className="d-flex align-items-center">
            Pembeli
          </Col>
          <Col md={10}>
            <Row>
              <Col md={9} style={{ borderRight: "1px solid #E5E5F0", textAlign: "end", padding: "10px 20px" }} className="text-bold">
                {penjualan.nama}
              </Col>
              <Col md={3} className="text-bold text-center" style={{ padding: "10px 0px" }}>
                <span
                  style={{ cursor: "pointer", color: "#1C37BE" }}
                  onClick={() => {
                    handleFotoClick();
                  }}
                >
                  Lihat Foto
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item style={{ padding: "20px 26px" }}>
        <Row>
          <Col md={3} className="text-bold">
            Jumlah
          </Col>
          <Col md={9}>{numberWithCommas(penjualan.jumlah)} ekor</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item style={{ padding: "20px 26px" }}>
        <Row>
          <Col md={3} className="text-bold">
            Berat Rata-Rata
          </Col>
          <Col md={9}>{penjualan.berat} kg</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item style={{ padding: "20px 26px" }}>
        <Row>
          <Col md={3} className="text-bold">
            Tonase
          </Col>
          <Col md={9}>{numberWithCommas(penjualan.tonase)} kg</Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default ListGroupPenjualan;
