import {
  LIST_REPORT_PENDING,
  LIST_REPORT_SUCCESS,
  LIST_REPORT_ERROR,
  UPDATE_REPORT_PAGE,
  UPDATE_FILTER_REPORT,
  DETAIL_REPORT_PENDING,
  DETAIL_REPORT_SUCCESS,
  DETAIL_REPORT_ERROR,
  LIST_FEED_PENDING,
  LIST_FEED_SUCCESS,
  LIST_FEED_ERROR,
  LIST_DOC_PENDING,
  LIST_DOC_SUCCESS,
  LIST_DOC_ERROR,
  CHECK_UMUR_PENDING,
  CHECK_UMUR_SUCCESS,
  CHECK_UMUR_ERROR,
  KUMULATIF_PANEN_PENDING,
  KUMULATIF_PANEN_SUCCESS,
  KUMULATIF_PANEN_ERROR,
  DELETE_OBAT_PENDING,
  DELETE_OBAT_SUCCESS,
  DELETE_OBAT_ERROR,
  CHECK_LAST_REPORT_PENDING,
  CHECK_LAST_REPORT_SUCCES,
  CHECK_LAST_REPORT_ERROR,
  KUMULATIF_CHICK_IN_PENDING,
  KUMULATIF_CHICK_IN_SUCCES,
  KUMULATIF_CHICK_IN_ERROR,
  POPULASI_CHICK_IN_PENDING,
  POPULASI_CHICK_IN_SUCCESS,
  POPULASI_CHICK_IN_ERROR,
  DOWNLOAD_REPORT_PENDING,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
  VIEW_FOTO_PENDING,
  VIEW_FOTO_SUCCESS,
  VIEW_FOTO_ERROR,
  VIEW_FOTOS_PENDING,
  VIEW_FOTOS_SUCCESS,
  VIEW_FOTOS_ERROR,
} from "../../../actions/farm/report";

const initialState = {
  pending: false,
  error: null,
};

const initialStateListReport = {
  ...initialState,
  listReports: [],
  pageData: {
    total: null,
    lengthItem: 10,
    page: 1,
  },
  filters: {
    tanggal: "",
    status: {},
  },
};

export const listReport = (state = initialStateListReport, action) => {
  switch (action.type) {
    case LIST_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        listReports: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
      };
    case LIST_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_REPORT_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_REPORT:
      return {
        ...state,
        filters: {
          tanggal: action.data.tanggal,
          status: action.data.status,
        },
      };
    default:
      return state;
  }
};

export const detailReport = (state = { ...initialState, detailReport: {} }, action) => {
  switch (action.type) {
    case DETAIL_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        detailReport: action.data,
      };
    case DETAIL_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const FeedReport = (state = { ...initialState, FeedReport: null }, action) => {
  switch (action.type) {
    case LIST_FEED_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_FEED_SUCCESS:
      return {
        ...state,
        pending: false,
        FeedReport: action.data,
      };
    case LIST_FEED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const DocReport = (state = { ...initialState, DocReport: null }, action) => {
  switch (action.type) {
    case LIST_DOC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        DocReport: action.data,
      };
    case LIST_DOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const CheckUmur = (state = { ...initialState, CheckUmur: 0 }, action) => {
  switch (action.type) {
    case CHECK_UMUR_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHECK_UMUR_SUCCESS:
      return {
        ...state,
        pending: false,
        CheckUmur: action.data,
      };
    case CHECK_UMUR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const CheckKumulatif = (state = { ...initialState, CheckKumulatif: {} }, action) => {
  switch (action.type) {
    case KUMULATIF_PANEN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case KUMULATIF_PANEN_SUCCESS:
      return {
        ...state,
        pending: false,
        CheckKumulatif: action.data,
      };
    case KUMULATIF_PANEN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const deleteObat = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_OBAT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_OBAT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DELETE_OBAT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const CheckLastReport = (state = { ...initialState, CheckLastReport: null }, action) => {
  switch (action.type) {
    case CHECK_LAST_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CHECK_LAST_REPORT_SUCCES:
      return {
        ...state,
        pending: false,
        CheckLastReport: action.data,
      };
    case CHECK_LAST_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const KumulatifChickIn = (state = { ...initialState, KumulatifChickIn: {} }, action) => {
  switch (action.type) {
    case KUMULATIF_CHICK_IN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case KUMULATIF_CHICK_IN_SUCCES:
      return {
        ...state,
        pending: false,
        KumulatifChickIn: action.data,
      };
    case KUMULATIF_CHICK_IN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const populasiChickIn = (state = { ...initialState, populasiChickIn: 0 }, action) => {
  switch (action.type) {
    case POPULASI_CHICK_IN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POPULASI_CHICK_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        populasiChickIn: action.data,
      };
    case POPULASI_CHICK_IN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const downloadReport = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const viewFoto = (state = { ...initialState, foto: "" }, action) => {
  switch (action.type) {
    case VIEW_FOTO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case VIEW_FOTO_SUCCESS:
      return {
        ...state,
        pending: false,
        foto: action.data,
      };
    case VIEW_FOTO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const previewPhotos = (state = { ...initialState, previewPhotos: [] }, action) => {
  switch (action.type) {
    case VIEW_FOTOS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case VIEW_FOTOS_SUCCESS:
      return {
        ...state,
        pending: false,
        previewPhotos: action.data,
      };
    case VIEW_FOTOS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getListReport = (state) => state.listReports;
export const getPageData = (state) => state.pageData;
export const getFilters = (state) => state.filters;
export const getListReportPending = (state) => state.pending;
export const getListReportError = (state) => state.error;
export const getDetailReportSuccess = (state) => state.detailReport;
export const getDetailReportPending = (state) => state.pending;
export const getDetailReportError = (state) => state.error;
export const getFeed = (state) => state.FeedReport;
export const getFeedError = (state) => state.error;
export const getFeedPending = (state) => state.pending;
export const getDoc = (state) => state.DocReport;
export const getDocError = (state) => state.error;
export const getDocPending = (state) => state.pending;
export const getUmur = (state) => state.CheckUmur;
export const getUmurError = (state) => state.error;
export const getUmurPending = (state) => state.pending;
export const getAddReportError = (state) => state.error;
export const getAddReportPending = (state) => state.pending;
export const getKumulatifPanen = (state) => state.CheckKumulatif;
export const getKumulatifPanenError = (state) => state.error;
export const getKumulatifPanenPending = (state) => state.pending;
export const getLastReport = (state) => state.CheckLastReport;
export const getLastReportError = (state) => state.error;
export const getLastReportPending = (state) => state.pending;
export const getKumulatifChickIn = (state) => state.KumulatifChickIn;
export const getKumulatifChickInError = (state) => state.error;
export const getKumulatifChickInPending = (state) => state.pending;
export const getPopulasiChickIn = (state) => state.populasiChickIn;
export const getPopulasiChickInError = (state) => state.error;
export const getPopulasiChickInPending = (state) => state.pending;
export const getDownloadReportPending = (state) => state.pending;
export const getDownloadReportError = (state) => state.error;
export const getFotoUrl = (state) => state.foto;
export const getFotoUrlPending = (state) => state.pending;
export const getPreviewPhotos = (state) => state.previewPhotos;
export const getPreviewPhotosPending = (state) => state.pending;
