import React from "react";

const InputTree = ({ type, placeholder, disabled, value, onChange, style, className, label, leftIcon, rightIcon, error, defaultValue, rule, onKeyPress, maxLength, readOnly, accept }) => {
  return (
    <div className={`input-wrapper ${error ? "error" : ""}`}>
      <span style={{ fontWeight: "700", fontSize: "14px" }}>{label}</span>
      <div className="input-box-wrapper">
        {leftIcon ? leftIcon : ``}
        <input
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          rule={rule}
          onChange={onChange}
          className={className}
          value={value}
          disabled={disabled}
          style={style}
          onKeyPress={onKeyPress}
          maxLength={maxLength}
          readOnly={readOnly}
          accept={accept}
        />
        {rightIcon ? rightIcon : ``}
      </div>
      <span className="error-msg">{error}</span>
    </div>
  );
};

export default InputTree;
