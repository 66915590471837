import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../components/commons/forms/select";
import { connect } from "react-redux";
import { listDoc, filterDoc } from "../../store/actions/doc";

import { InfoRounded } from "@mui/icons-material";
import { getFilters, getPageData, getSearchText } from "../../store/reducers/doc";
import { useState } from "react";
import { useEffect } from "react";

const ModalFilterDoc = (props) => {
  const { filters, pageData, searchText } = props;
  const options = [
    { value: "Grade A", label: "Grade A" },
    { value: "Grade B", label: "Grade B" },
    { value: "Grade C", label: "Grade C" },
  ];
  const [filter, setFilter] = useState({});

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterDoc({ grade_doc: filter });
    }, 200);
  };

  const handleResetButton = () => {
    props.setShow(false);
    props.filterDoc({ grade_doc: null });
    setFilter("");
  };

  const handleClose = () => {
    setTimeout(() => {
      props.setShow(false);
      setFilter("");
    }, 100);
  };

  return (
    <div>
      <Modal size={"md"} onHide={handleClose} show={props.show} centered>
        <ModalHeader closeButton>Filter</ModalHeader>
        <ModalBody>
          <div className="info">
            <InfoRounded color="inherit" fontSize="medium" />
            <div className="info-text">Pilih data yang ingin difilter</div>
          </div>
          <div className="select-wrapper">
            <SelectComponent
              options={options}
              label={"Grade"}
              onChange={(e) => {
                setFilter(e);
              }}
              value={
                filter === null
                  ? { value: "", label: "Semua Grade" }
                  : filters.grade_doc.value && !filter.value
                  ? {
                      value: filters.grade_doc.value,
                      label: filters.grade_doc.label,
                    }
                  : filter.value
                  ? { value: filter.value, label: filter.label }
                  : { value: "", label: "Semua Grade" }
              }
            />
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: `space-between` }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 ps-2">
                <Button
                  variant="outlined"
                  className="btn-btl-ubah-pass"
                  style={{ right: "2px" }}
                  onClick={handleResetButton}
                >
                  Reset
                </Button>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-end">
                  <Button
                    variant="outlined"
                    className="btn-btl-ubah-pass"
                    style={{ right: "2px" }}
                    onClick={handleClose}
                  >
                    Batal
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-simpan-ubah-pass"
                    style={{ right: "2px" }}
                    onClick={() => submitForm()}
                  >
                    Terapkan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listDoc }) {
  return {
    filters: getFilters(listDoc),
    pageData: getPageData(listDoc),
    searchText: getSearchText(listDoc),
  };
}

export default connect(mapStateToProps, { listDoc, filterDoc })(ModalFilterDoc);
