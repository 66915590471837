import { 
    UPDATE_FEED_PAGE,
    LIST_FEED_SUCCESS,
    LIST_FEED_PENDING,
    LIST_FEED_ERROR,
    UPDATE_FILTER_FEED,
    UPDATE_SEARCH_FEED,
    TAMBAH_FEED_PENDING,
    TAMBAH_FEED_SUCCESS,
    TAMBAH_FEED_ERROR,
    DELETE_FEED_SUCCESS,
    DELETE_FEED_PENDING,
    DELETE_FEED_ERROR,
    EDIT_FEED_SUCCESS,
    EDIT_FEED_PENDING,
    EDIT_FEED_ERROR
 } from "../../actions/feed";

const initialState = {
  listFeeds:[],
  pending:false,
  error:null,
  pageData:{
    total: null,
    lengthItem:10,
    page:1,
  },
  filters:{
    grade_feed:{}
  },
  search_text:''
};

export const listFeed = (state = initialState, action) => {
    switch (action.type) {
      case LIST_FEED_PENDING:
        return {
          ...state,
          pending: true,
        };
      case LIST_FEED_SUCCESS:
        return {
          ...state,
          pending: false,
          listFeeds: action.data.data,
          pageData: {
            ...state.pageData,
            total: action.data.total
          }
        };
      case LIST_FEED_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error
        }
      case UPDATE_FEED_PAGE:
        return {
          ...state,
          pageData:{
            ...state.pageData,
            page: action.data
          }
        }
      case UPDATE_FILTER_FEED:
        return {
          ...state,
          filters: {
            grade_feed: action.data.grade_feed
          }
        }
      case UPDATE_SEARCH_FEED:
        return {
          ...state,
          search_text: action.data
        }
      default:
        return state;
    }
};

export const tambahFeed = (state = initialState, action) => {
  switch (action.type) {
    case TAMBAH_FEED_PENDING:
      return {
        ...state,
        pending: true,
      };
    case TAMBAH_FEED_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case TAMBAH_FEED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const editFeed = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_FEED_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_FEED_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_FEED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export function deleteFeed(state = initialState ,action) {
  switch (action.type) {
    case DELETE_FEED_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_FEED_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteFeed: action.data,
      };
    case DELETE_FEED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListFeed        = state => state.listFeeds
export const getPageData        = state => state.pageData
export const getFilters         = state => state.filters
export const getSearchText      = state => state.search_text
export const getListFeedPending = state => state.pending
export const getListFeedError   = state => state.error
export const getAddFeedPending  = state => state.pending
export const getAddFeedError    = state => state.error
export const getDeleteFeedPending = (state) => state.pending;
export const getDeleteFeedError = (state) => state.error;