import { SET_EXAMPLE_MODAL } from "../../actions/example";

const initialState = {
  isOpenModal: true,
  modalType: "add",
};

const example = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXAMPLE_MODAL:
      return {
        ...state,
        isOpenModal: action.data.isOpen,
        modalType: action.data.modalType,
      };
    default:
      return state;
  }
};

export default example;
