import React from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from '@mui/material';
import { connect } from "react-redux";
import Warning1 from "../../assets/img/Warning.png";
import { deleteUser, listUser } from "../../store/actions/user";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/user";

const ModalDeleteUser = (props) => {
  const { pageData, filters, searchText } = props;

  const handleDelete = () => {
    const data = props.data.id;
    props.deleteUser(data);
    setTimeout(() => {
      props.setShow(false);
      props.listUser({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        role_name: filters.role.value,
        status: filters.status.value,
      });
    }, 1000);
  };

  return (
    <div>
      <Modal
        size={"md"}
        onHide={(e) => props.setShow(false)}
        show={props.show}
        centered>
        <ModalHeader closeButton>Konfirmasi</ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row justify-content-around">
              <div className="col-md-1 icon-warning">
                <img src={Warning1} alt="icon warning" />
              </div>
              <div className="col-md-11 text-icon-warning">
                <span className="text-bold">
                  Apakah Anda yakin ingin menghapus data ini?
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>

            <Button variant="outlined" disableElevation className="btn-btl-delete"
                  sx={{
                  paddingY: "10px",
                  }}
                  onClick={e => props.setShow(false)}
                  >
                  Batal
              </Button>
              
              <Button variant="contained" disableElevation className="btn-delete"
                  sx={{
                  paddingY: "10px",
                  }}
                  onClick={e => handleDelete()}
                  >
                  Ya,Hapus
              </Button>
              
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listUser }) {
  return {
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
}

export default connect(mapStateToProps, { listUser, deleteUser })(
  ModalDeleteUser
);
