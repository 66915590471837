// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#1C37BE",
	"secondary": "#A5A5C0",
	"success": "#1CB289",
	"info": "#04aa594d",
	"warning": "#E8A720",
	"danger": "#D10D0D",
	"light": "#e3eaef",
	"dark": "#242726",
	"inactive": "#ededed"
};
export default ___CSS_LOADER_EXPORT___;
