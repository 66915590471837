import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import example from "./example";
import profile from "./profile";
import { listUser } from "./user";
import { listFeed } from "./feed";
import { listFarm, listKepKan, detailFarm } from "./farm";
import { listRole } from "./role";
import { loginUser, logoutUser } from "./auth";
import { listDoc } from "./doc";
import { listReport, detailReport, FeedReport, DocReport, CheckUmur, CheckKumulatif, CheckLastReport, KumulatifChickIn, populasiChickIn, downloadReport, viewFoto, previewPhotos } from "./farm/report/index";
import { listFarmDB, dashboardData, tingkatKematian, beratAyam, statusKandang, topFiveData, syncDashboard, sisaPakan } from "./dashboard";

const rootReducer = combineReducers({
  form: formReducer,
  example,
  profile,
  listUser,
  listFeed,
  listFarm,
  detailFarm,
  listKepKan,
  listRole,
  loginUser,
  logoutUser,
  listDoc,
  listReport,
  detailReport,
  FeedReport,
  DocReport,
  CheckUmur,
  CheckLastReport,
  CheckKumulatif,
  KumulatifChickIn,
  populasiChickIn,
  listFarmDB,
  dashboardData,
  tingkatKematian,
  beratAyam,
  statusKandang,
  topFiveData,
  downloadReport,
  syncDashboard,
  viewFoto,
  previewPhotos,
  sisaPakan
});

export default rootReducer;
