import { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";

const Index = (props) => {
  const { show, setShow, data } = props;

  const handleClose = () => {
    setShow(false);
  };

  // custom function
  const toCurrency = (num) => {
    if (isNaN(parseFloat(num))) return 0;
    return Intl.NumberFormat("en-US").format(num);
  };
  const toPersen = (num) => {
    if (isNaN(parseFloat(num))) return 0;
    return Intl.NumberFormat("en-US").format(parseFloat(num).toFixed(2)).toString().replace(",", " ").replace(".", ",").replace(" ", ".");
  };

  return (
    <div>
      <Modal size={"md"} onHide={handleClose} show={props.show} centered>
        <ModalHeader closeButton>Stok Ayam</ModalHeader>
        <ModalBody>
          <Table size="small" className="table-user">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    background: "#F4F5F7",
                    borderBottom: "none",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  {"Berat Rata-Rata (kg)"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    background: "#F4F5F7",
                    borderBottom: "none",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  {"Jumlah (ekor)"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0 ? (
                data.map((d, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="center">{toPersen(d.berat)}</TableCell>
                      <TableCell align="center">{toCurrency(d.jumlah)}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    Tidak ada data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter style={{ display: "flex", alignItems: "flex-end" }}>
          <div>
            <Button variant="contained" className="btn-simpan-ubah-pass" onClick={handleClose}>
              Tutup
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Index;
