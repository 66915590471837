import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from '@mui/material';
import SelectComponent from "../../components/commons/forms/select";
import Input from "../../components/commons/forms/authentication/input";
import TextArea from "../../components/commons/forms/textarea";
import Radio from "../../components/commons/forms/radio";
import { connect } from "react-redux";
import {
  getListRole,
  getListRoleError,
  getListRolePending,
} from "../../store/reducers/role";
import { listRole } from "../../store/actions/role";
import { tambahUser, listUser } from "../../store/actions/user";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/user";
import inputRules from "../../utils/inputRules";

function ModalTambahUser(props) {
  const [email, setEmail] = useState("");
  const [role_code, setRole_code] = useState("kepalakandang");
  const [roleName, setRoleName] = useState("");
  const [name, setName] = useState("");
  const [alamat, setAlamat] = useState("");
  const [handphone, setHandphone] = useState("");
  const [status, setStatus] = useState("Aktif");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorRole, setErrorRole] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorHandphone, setErrorHandphone] = useState("");
  const [errorAlamat, setErrorAlamat] = useState("");
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const { listRoleSuccess, pageData, filters, searchText } = props;

  useEffect(() => {
    props.listRole();
  }, []);

  const handleInput = (e, set, error, customRule = false) => {
    let value;
    let label = true;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
      label = false;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (label) setRoleName(e.label ? e.label : roleName);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      if (inputRules[rule]) {
        const match = new RegExp(inputRules[rule]).test(value);
        if (!match) {
          error("Format" + rule.toLowerCase() + " tidak sesuai");
        } else {
          error(``);
        }
      }
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    if (role_code !== "kepalakandang") {
      if (data.email) {
        const match = new RegExp(inputRules["Email"]).test(data.email);
        match ? setErrorEmail("") : setErrorEmail("Format email tidak sesuai");
      } else {
        setErrorEmail("Email wajib diisi");
      }
    }
    data.name ? setErrorName("") : setErrorName("Nama wajib diisi");
    if (data.handphone) {
      data.handphone.length < 10
        ? setErrorHandphone("Nomor yang dimasukkan minimal 10 digit")
        : setErrorHandphone("");
    } else {
      setErrorHandphone("Nomor telepon wajib diisi");
    }
    data.alamat ? setErrorAlamat("") : setErrorAlamat("Alamat wajib diisi");
    data.role_code ? setErrorRole("") : setErrorRole("Role wajib diisi");
  };

  const handleClose = () => {
    setDefaultState();
    props.setShow(false);
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    let data = {};
    if (role_code === "kepalakandang") {
      data = {
        name,
        handphone,
        alamat,
        status,
        role_code,
      };
    } else {
      data = {
        email: email.toLowerCase(),
        name,
        handphone,
        alamat,
        status,
        role_code,
      };
    }

    handleError(data);
    for (var key in data) {
      if (data[key] === "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (data.handphone.length < 10) {
      setDisableBtnSubmit(false);
      return;
    }

    if (role_code !== "kepalakandang") {
      const match = new RegExp(inputRules["Email"]).test(data.email);
      if (!match) {
        setDisableBtnSubmit(false);
        return;
      }
    }
    props.tambahUser(data, successTambah, failedTambah);
  };

  const failedTambah = () => {
    setDisableBtnSubmit(false);
  };

  const successTambah = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
      props.listUser({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        role_code: filters.role.value,
        status: filters.status.value,
      });
      setDisableBtnSubmit(false);
    }, 1000);
  };

  const setDefaultState = () => {
    setEmail("");
    setName("");
    setRole_code("kepalakandang");
    setRoleName("");
    setHandphone("");
    setAlamat("");
    setStatus("Aktif");
    setErrorEmail("");
    setErrorAlamat("");
    setErrorHandphone("");
    setErrorRole("");
    setErrorName("");
  };

  return (
    <Modal size={"md"} onHide={handleClose} show={props.show} centered>
      <ModalHeader closeButton>Tambah User</ModalHeader>
      <ModalBody>
        <SelectComponent
          options={listRoleSuccess ? listRoleSuccess : {}}
          label={"Role"}
          isAsync={true}
          asyncUrl={"/v1/master_data/role"}
          onChange={(e) => handleInput(e, setRole_code, setErrorRole, "Role")}
          defaultValue={{ value: "kepalakandang", label: "Kepala Kandang" }}
          isError={errorRole !== ``}
          error={errorRole}
          isClearable={false}
        />
        <Input
          label={"Nama"}
          placeholder={"Masukkan nama"}
          onChange={(e) => handleInput(e, setName, setErrorName)}
          value={name || ""}
          rule={"Nama"}
          error={errorName}
        />
        <Input
          label={"Nomor Telepon"}
          placeholder={"Masukkan nomor telepon"}
          onChange={(e) => handleInput(e, setHandphone, setErrorHandphone)}
          value={handphone || ""}
          rule={`Nomor telepon`}
          error={errorHandphone}
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          maxLength={13}
        />
        {role_code === "management" ? (
          <Input
            rule="Email"
            label={"Email"}
            placeholder={"Masukkan email"}
            onChange={(e) => handleInput(e, setEmail, setErrorEmail)}
            defaultValue={email || ""}
            error={errorEmail}
            isError={errorEmail !== ``}
          />
        ) : null}
        <TextArea
          label={"Alamat"}
          placeholder={"Masukkan alamat"}
          onChange={(e) => handleInput(e, setAlamat, setErrorAlamat, "Alamat")}
          defaultValue={alamat || ""}
          error={errorAlamat}
          isError={errorAlamat !== ``}
        />
        <hr style={{ margin: "50px 0" }} />
        <Radio status={status} setStatus={setStatus} />
      </ModalBody>
      <ModalFooter>
      <Button
          variant="outline"
          className="btn-btl-ubah-pass"
          sx={{ paddingY: "10px" }}
          onClick={handleClose}>
            Batal
        </Button>
        <Button
          variant="contained"
          className="btn-simpan-ubah-pass"
          sx={{ paddingY: "10px" }}
          onClick={submitForm}
          disabled={disableBtnSubmit}>
            Simpan
        </Button>
      </ModalFooter>
    </Modal>
  );
}
function mapStateToProps({ listRole, listUser }) {
  return {
    listRolePending: getListRolePending(listRole),
    listRoleSuccess: getListRole(listRole),
    listRoleError: getListRoleError(listRole),
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
}

export default connect(mapStateToProps, { listRole, tambahUser, listUser })(
  ModalTambahUser
);
