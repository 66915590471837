export const LOCAL_STORAGE_KEY = "data";
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const MonthArr = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const numberWithCommas = (x) => {
  return x
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const convertDate = (date) => {
  const exploded = date.toLocaleString("fr-FR", {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  });
  return exploded;
};

export const convertTime = (date) => {
  return `${date.getHours().toString().padStart(1)}:${date.getMinutes().toString().padStart(1)}`;
};

export const toPersen = (num) => {
  if (isNaN(parseFloat(num))) return 0;
  return Intl.NumberFormat("en-US").format(parseFloat(num).toFixed(2)).toString().replace(",", " ").replace(".", ",").replace(" ", ".");
};

export const statusOptions = [
  { value: "Kosong", label: "Kosong" },
  { value: "Persiapan Kandang", label: "Persiapan Kandang" },
  { value: "Cuci Kandang", label: "Cuci Kandang" },
  { value: "Chick In", label: "Chick In" },
  { value: "Panen", label: "Panen" },
];

export const pemanasOptions = [
  { value: "Batu Bara", label: "Batu Bara" },
  { value: "Elpiji", label: "Elpiji" },
];
