import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import SelectComponent from "../../components/commons/forms/select";
import { useEffect, useState } from "react";
import Input from "../../components/commons/forms/authentication/input";
import TextArea from "../../components/commons/forms/textarea";
import Radio from "../../components/commons/forms/radio";
import { connect, useDispatch } from "react-redux";
import {
  getListRole,
  getListRoleError,
  getListRolePending,
} from "../../store/reducers/role";
import { listRole } from "../../store/actions/role";
import { editUser, listUser } from "../../store/actions/user";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/user";
import inputRules from "../../utils/inputRules";
// import createBrowserHistory from "history/createBrowserHistory";
import { createBrowserHistory } from 'history'

const ModalEditUser = (props) => {
  // membuat variabel untuk request
  const history = createBrowserHistory({forceRefresh:true});

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [roleName, setRoleName] = useState("");
  const [name, setName] = useState("");
  const [alamat, setAlamat] = useState("");
  const [handphone, setHandphone] = useState(props.data.handphone_show);
  const [status, setStatus] = useState("");
  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  // variable handle error input
  const [errorEmail, setErrorEmail] = useState("");
  const [errorRole, setErrorRole] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorHandphone, setErrorHandphone] = useState("");
  const [errorAlamat, setErrorAlamat] = useState("");

  // variabel untuk opsi select
  const { listRoleSuccess, pageData, filters, searchText } = props;

  useEffect(() => {
    props.listRole();
    if (props.data) {
      setId(props.data.id);
      setEmail(props.data.email);
      setRole(props.data.role);
      setRoleName(props.data.role_name);
      setName(props.data.name);
      setAlamat(props.data.alamat);
      setHandphone(props.data.handphone_show);
      setStatus(props.data.status);
      // set the error nulls
      setErrorName(``)
      setErrorRole(``)
      setErrorHandphone(``)
      setErrorAlamat(``)
      setErrorEmail(``)
    }
  }, [props.data]);

  const handleInput = (e, set, error, customRule = false) => {
    let value;
    let label = true;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
      label = false;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (label) setRoleName(e.label ? e.label : roleName);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      if (inputRules[rule]) {
        const match = new RegExp(inputRules[rule]).test(value);
        if (!match) {
          error(`Format ${rule} tidak sesuai`);
        } else error(``);
      }
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    if (role !== "kepalakandang") {
      if (data.email) {
        const match = new RegExp(inputRules["Email"]).test(data.email);
        match ? setErrorEmail("") : setErrorEmail("Format email tidak sesuai");
      } else {
        setErrorEmail("Email wajib diisi");
      }
    }
    data.name ? setErrorName("") : setErrorName("Nama wajib diisi");
    if (data.handphone) {
      data.handphone.length < 10
        ? setErrorHandphone("Nomor yang dimasukkan minimal 10 digit")
        : setErrorHandphone("");
    } else {
      setErrorHandphone("Nomor telepon wajib diisi");
    }
    data.alamat ? setErrorAlamat("") : setErrorAlamat("Alamat wajib diisi");
    data.role ? setErrorRole("") : setErrorRole("Role wajib diisi");
  };

  const setDefaultState = () => {
    setId(props.data.id);
    setEmail(props.data.email);
    setRole(props.data.role);
    setRoleName(props.data.role_name);
    setName(props.data.name);
    setAlamat(props.data.alamat);
    setHandphone(props.data.handphone_show);
    setStatus(props.data.status);
    //set the error nulls
    setErrorName(``)
    setErrorRole(``)
    setErrorHandphone(``)
    setErrorAlamat(``)
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    let data = {}
    if (role == "kepalakandang") {
      data = {
        id,
        name,
        handphone,
        alamat,
        status,
        role,
      };
    } else {
      data = {
        id,
        email: email.toLowerCase(),
        name,
        handphone,
        alamat,
        status,
        role,
      };
    }

    handleError(data);
    for (var key in data) {
      if (data[key] === "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (data.handphone.length < 10) {
      setDisableBtnSubmit(false);
      return;
    }

    if (role !== "kepalakandang") {
      const match = new RegExp(inputRules["Email"]).test(data.email);
      if (!match) {
        setDisableBtnSubmit(false);
        return;
      }
    }
    props.editUser(data, successEdit, failedEdit);
  };

  const successEdit = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
      props.listUser({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        role_code: filters.role.value,
        status: filters.status.value,
      });
      setDisableBtnSubmit(false);
    }, 1000,)
    //history.push("/usermanagement")
  };

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };

  const handleButtonBatal = () => {
    setDefaultState();
    props.setShow(false)
  }

  return (
    <div>
      <Modal
        size={"md"}
        onHide={(e) => props.setShow(false)}
        show={props.show}
        centered
      >
        <ModalHeader closeButton>Edit User</ModalHeader>
        <ModalBody>
          <SelectComponent
            options={listRoleSuccess ? listRoleSuccess : {}}
            label={"Role"}
            isAsync={true}
            asyncUrl={"/v1/master_data/role"}
            onChange={(e) => handleInput(e, setRole, setErrorRole, `Role`)}
            value={{ value: role, label: roleName }}
            isError={errorRole !== ``}
            error={errorRole}
            rule={'role'}
          />
          <Input
            label={"Nama"}
            placeholder={"Masukkan nama"}
            onChange={(e) => handleInput(e, setName, setErrorName)}
            value={name || ""}
            rule={"Nama"}
            error={errorName}
          />
          <Input
            label={"Nomor Telepon"}
            placeholder={"Masukkan nomor telepon"}
            onChange={(e) => handleInput(e, setHandphone, setErrorHandphone)}
            defaultValue={props.data.handphone_show || ""}
            rule={`Nomor telepon`}
            error={errorHandphone}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            maxLength={13}
          />
          {
            role == "management" ? (
              <Input
                label={"Email"}
                placeholder={"Masukkan email"}
                rule={'Email'}
                onChange={(e) => handleInput(e, setEmail, setErrorEmail)}
                value={email || ""}
                error={errorEmail}
                isError={errorEmail !== ``}
              />
            ) : null
          }
          <TextArea
            label={"Alamat"}
            placeholder={"Masukkan alamat"}
            onChange={(e) =>
              handleInput(e, setAlamat, setErrorAlamat, `Alamat`)
            }
            defaultValue={alamat || ""}
            error={errorAlamat}
            isError={errorAlamat !== ``}
          />
          <hr style={{ margin: "50px 0" }} />
          <Radio status={status} setStatus={setStatus} />
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" disableElevation className="btn-btl-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleButtonBatal}
          >
            Batal
          </Button>

          <Button variant="contained" disableElevation className="btn-simpan-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}
          >
            Simpan
          </Button>

        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listRole, listUser }) {
  return {
    listRolePending: getListRolePending(listRole),
    listRoleSuccess: getListRole(listRole),
    listRoleError: getListRoleError(listRole),
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
}

export default connect(mapStateToProps, { listRole, editUser, listUser })(
  ModalEditUser
);
