import React from 'react'
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from 'react';
import { connect } from 'react-redux';

import { listFarm, searchFarm } from '../../store/actions/farm';

import {
  getFilters,
  getPageData,
  getSearchText
} from '../../store/reducers/farm';

const SearchBoxFarm = (props) => {

  const { pageData, filters, searchText } = props;

  const [searchTextShow, setSearchTextShow] = useState(false);

  const handleSearchChange = (e) => {
    props.searchFarm(e.target.value);
    props.listFarm({
      length: pageData.lengthItem,
      page: 1,
      search_text: e.target.value,
      kepala_kandang: filters.kepala_kandang.value,
      status_farm: filters.status_farm.value,
    });
  };
  return (
    <>
      <div className={`searchbox-wrapper ${searchTextShow ? "searchbox-show" : ""}`}>
        <input
          type="text"
          name="search-text"
          placeholder="Masukkan kata kunci"
          onChange={handleSearchChange}
          defaultValue={searchText}
        />
        <IconButton onClick={(e) => setSearchTextShow(!searchTextShow)}>
          <Search />
        </IconButton>
      </div>
    </>
  )
}

const mapStateToProps = ({ listFarm }) => {
  return {
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, { listFarm, searchFarm })(SearchBoxFarm);