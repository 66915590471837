import React from "react";
import { Accordion } from "react-bootstrap";

const AccordionBase = ({ title, children, accordionRef }) => {
  return (
    <Accordion style={{ marginBottom: "30px" }}>
      <Accordion.Item eventKey="0">
        <Accordion.Button as="div" className="accordion-header-custom" ref={accordionRef}>
          <span className="text-bold" style={{ fontSize: "18px" }}>
            {title}
          </span>
        </Accordion.Button>
        <Accordion.Body bsPrefix="accordion-body-custom">{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionBase;
