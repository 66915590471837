import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PrivateRoute from "./components/commons/privateRoute";

import Dashboard from "./views/dashboard";
import UserManagement from "./views/usermanagement";
import FarmManagement from "./views/farmmanagement";
import FeedManagement from "./views/feedmanagement";
import DocManagement from "./views/docmanagement";
import Login from "./views/authentication";
import SidebarDetailFarm from "./views/farmmanagement/SidebarDetailFarm";
import DetailReport from "./views/farmmanagement/report/detail";
import TambahReport from "./views/farmmanagement/report/tambah";
import EditReport from "./views/farmmanagement/report/edit";
import { role } from "./utils";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#1C37BE",
    },
    secondary: {
      main: "#E5E5F0",
    },
    danger: {
      main: "#D10D0D",
    },
  },
  typography: {
    fontFamily: ["PlusJakartaSans-Regular", '"Open Sans"', "sans-serif"].join(","),
  },
});

// simple forbidden page
const Forbidden = () => {
  return (
    <div>
      <h1>403 Error</h1>
      <h1>Forbidden</h1>
      <h4>Your role have not access for this feature</h4>
      <h4>Role kamu tidak punya akses untuk fitur ini</h4>
    </div>
  );
};

function App() {
  return (
    <>
      <ThemeProvider theme={materialTheme}>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path={"/access-denied"} component={Forbidden} />
          <PrivateRoute exact path="/dashboard" roles={[role.management]} component={Dashboard} />
          <PrivateRoute exact path="/usermanagement" roles={[role.management]} component={UserManagement} />
          <PrivateRoute exact path="/farmmanagement" roles={[role.management]} component={FarmManagement} />
          <PrivateRoute exact path="/feedmanagement" roles={[role.management]} component={FeedManagement} />
          <PrivateRoute exact path="/docmanagement" roles={[role.management]} component={DocManagement} />
          <PrivateRoute path="/farmmanagement/:id" roles={[role.management]} exact component={SidebarDetailFarm} />
          <PrivateRoute
            path="/farmmanagement/:id/report"
            roles={[role.management]}
            exact
            component={SidebarDetailFarm}
          />
          <PrivateRoute
            path="/farmmanagement/:id/report/tambah"
            roles={[role.management]}
            exact
            component={TambahReport}
          />
          <PrivateRoute
            path="/farmmanagement/:id/report/:id_report"
            roles={[role.management]}
            exact
            component={DetailReport}
          />
          <PrivateRoute
            path="/farmmanagement/:id/report/:id_report/edit"
            roles={[role.management]}
            exact
            component={EditReport}
          />
        </Switch>
      </ThemeProvider>
    </>
  );
}

export default App;
