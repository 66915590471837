import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  MenuItem,
  Menu,
  Fade,
  Divider,
  Button,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import { Container, Navbar } from "react-bootstrap";
import PageFarm from "./PageFarm";
import SearchBoxFarm from "./SearchBoxFarm";
import ModalFilterFarm from "./ModalFilterFarm";
import ModalTambahFarm from "./ModalTambahFarm";
import ModalEditFarm from "./ModalEditFarm";
import ModalDeleteFarm from "./ModalDeleteFarm";
import PageDetailFarm from "./PageDetailFarm";

import { listFarm } from "../../store/actions/farm";
import {
  getListFarm,
  getListFarmPending,
  getListFarmError,
  getPageData,
  getFilters,
  getSearchText,
} from "../../store/reducers/farm";

const Index = (props) => {
  const {
    pageData,
    listFarmSuccess,
    listFarmPending,
    listFarmError,
    filters,
    searchText,
  } = props;

  const history = useHistory();

  const [showModalEditFarm, setShowModalEditFarm] = useState(false);
  const [showTambahFarmModal, setShowTambahFarmModal] = useState(false);
  //const [showPageDetailFarm, setShowPageDetailFarm] = useState(false);
  const [showModalDeleteFarm, setShowModalDeleteFarm] = useState(false);
  const [showModalFilterFarm, setShowModalFilterFarm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [anchorEl, setAnchorEl] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [popOpen, setPopOpen] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [activePopMenu, setActivePopMenu] = useState(null);

  const handleEditButtonClick = () => {
    handleMoreButtonClose();
    setShowModalEditFarm(true);
  };
  const handleDetailButtonClick = (id) => {
    handleMoreButtonClose();
    //setShowPageDetailFarm(true);
    history.push(`/farmmanagement/${editData.id}`);
  };
  const handleDeleteButtonClick = () => {
    handleMoreButtonClose();
    setShowModalDeleteFarm(true);
  };
  const handleMoreButtonClick = (data, event, index) => {
    setEditData(data);
    let anchor = anchorEl;
    anchor[index] = event.currentTarget;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[index] = true;
    setPopOpen(temp);
    setActivePopMenu(index);
  };
  const handleMoreButtonClose = () => {
    let anchor = anchorEl;
    anchor[activePopMenu] = null;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[activePopMenu] = false;
    setPopOpen(temp);
    setActivePopMenu(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //Handling Tambah Button
  const handleTambahFarmButtonClick = () => {
    setShowTambahFarmModal(true);
  };

  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    props.listFarm({
      length: pageData.lengthItem,
      page: pageData.page,
      search_text: searchText,
      kepala_kandang: filters.kepala_kandang.value,
      status_farm: filters.status_farm.value,
    });
  }, [popOpen, anchorEl, filters]);

  const farmLists = (data, error) => {
    if (!data || error) {
      return;
      <TableRow>
        <TableCell colSpan={5} align="center">
          Gagal Mengambil Data Kandang
        </TableCell>
      </TableRow>;
    }
    return data.length < 1 ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Data Kandang tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>

          {row.name.length > 20 ? (
            <TableCell>{row.name.slice(0, 20) + "..."}</TableCell>
          ) : (
            <TableCell>{row.name}</TableCell>
          )}
          {row.kepala_kandang.name.length > 20 ? (
            <TableCell>
              {row.kepala_kandang.name.slice(0, 20) + "..."}
            </TableCell>
          ) : (
            <TableCell>{row.kepala_kandang.name}</TableCell>
          )}

          <TableCell>
            <div
              style={{ width: "80%" }}
              className={
                row.status_farm == "Persiapan Kandang"
                  ? "btn-status bg-secondary btn-sm rounded"
                  : row.status_farm == "Chick In"
                  ? "btn-status btn-sm bg-warning rounded"
                  : row.status_farm == "Panen"
                  ? "btn-status btn-sm bg-success rounded"
                  : row.status_farm == "Cuci Kandang"
                  ? "btn-status btn-sm bg-primary rounded"
                  : "btn-status btn-sm bg-danger rounded"
              }
            >
              {row.status_farm[0].toUpperCase() + row.status_farm.substring(1)}
            </div>
          </TableCell>
          <TableCell align="center">
            <IconButton
              onClick={(e) => handleMoreButtonClick(row, e, i)}
              variant="contained"
              aria-controls={popOpen[i] ? id : undefined}
              aria-haspopup="true"
              aria-expanded={popOpen[i] ? "true" : undefined}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              elevation={1}
              id={popOpen[i] ? "simple-popover" : undefined}
              open={popOpen[i]}
              anchorEl={anchorEl[i]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleMoreButtonClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                test={row.id}
                onClick={(e) => handleDetailButtonClick(row.id)}
                sx={styleMenuItem}
              >
                Detail
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                test={row.id}
                onClick={(e) => handleEditButtonClick(row.id)}
                sx={styleMenuItem}
              >
                Edit
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                test={row.id}
                onClick={(e) => handleDeleteButtonClick()}
                sx={styleMenuItem}
              >
                Hapus
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      {editData ? (
        <>
          <ModalEditFarm
            show={showModalEditFarm}
            setShow={setShowModalEditFarm}
            data={editData}
          />
          <ModalDeleteFarm
            show={showModalDeleteFarm}
            setShow={setShowModalDeleteFarm}
            data={editData}
          />
          {/* {showPageDetailFarm ?               
              <PageDetailFarm
                data={editData }
              /> : null} */}
        </>
      ) : (
        ""
      )}

      <ModalFilterFarm
        show={showModalFilterFarm}
        setShow={setShowModalFilterFarm}
      />
      <ModalTambahFarm
        show={showTambahFarmModal}
        setShow={setShowTambahFarmModal}
      />

      <Navbar>
        <>
          <Navbar.Brand>
            <h1 className="judul-header">Farm Management</h1>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <SearchBoxFarm />
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilterFarm(!showModalFilterFarm)}
            >
              {filters.kepala_kandang.value || filters.status_farm.value ? (
                <span className="dot"></span>
              ) : (
                ``
              )}
              Filter
            </Button>
            <div className="custom-devider" />
            <Button
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{
                background: "#1C37BE",
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "800",
                borderRadius: "24px",
                width: "200px",
                height: "48px",
                "&:hover": {
                  background: "#5269D9",
                  boxShadow: "0px 4px 14px rgba(28, 55, 190, 0.75)",
                  borderRadius: "24px",
                },
              }}
              onClick={handleTambahFarmButtonClick}
            >
              Tambah Kandang
            </Button>
          </Navbar.Collapse>
        </>
      </Navbar>
      <Divider />
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "800", paddingY: "16px" }}
            >
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA KANDANG</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>KEPALA KANDANG</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              STATUS KANDANG
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listFarmPending ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Mengambil data kandang ...
              </TableCell>
            </TableRow>
          ) : (
            farmLists(listFarmSuccess, listFarmError)
          )}
        </TableBody>
      </Table>
      <PageFarm pageData={pageData} />
      <div style={{ marginBottom: "210px" }}></div>
    </Container>
  );
};

const mapStateToProps = ({ listFarm }) => {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listFarmPending: getListFarmPending(listFarm),
    listFarmError: getListFarmError(listFarm),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, { listFarm })(Index);
