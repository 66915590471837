import { useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap";
import inputRules from '../../utils/inputRules'
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, Button } from "@mui/material";
import { connect } from "react-redux";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

import Input from "../../components/commons/forms/authentication/input"
import { getLoginUserError, getLoginUserPending } from "../../store/reducers/auth";
import { loginUser } from "../../store/actions/auth";

function Login(props) {

  const [visiblePwd, setVisiblePwd] = useState(false);
  const [email, setEmail] = useState("")
  const [pwd, setPwd] = useState("")
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPwd, setErrorPwd] = useState("");
  const history = useHistory();

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    const nowTokenExpired = new Date().getTime();
    if (dataUser?.token && nowTokenExpired < dataUser?.tokenExpired) history.push("/dashboard");
    else localStorage.removeItem(LOCAL_STORAGE_KEY);
  }, [])

  const handleInput = (e, set, error) => {
    const value = e.target.value;
    const rule = e.target.getAttribute(`rule`);
    if (!value) {
      error(`${rule} wajib diisi`);
    }
    else {
      const match = new RegExp(inputRules[rule]).test(value)
      if (!match) {
        if (rule === 'Email') {
          error(`Format email tidak sesuai`)
        }
        else {
          error(`Password tidak sesuai`)
        }
      }
      else {
        error(``)
      }
    }
    set(value)
  }


  const handleSubmit = () => {
    setDisableBtnSubmit(true);
    const data = { email: email.toLowerCase(), pwd }

    handleError(data);

    for (var key in data) {
      if (data[key] === "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    if (data.email) {
      const match = new RegExp(inputRules["Email"]).test(data.email);
      if (!match) {
        setDisableBtnSubmit(false);
        return;
      }
    }
    if (data.pwd) {
      const match = new RegExp(inputRules["Password"]).test(data.pwd);
      if (!match) {
        setDisableBtnSubmit(false);
        return;
      }
    }

    props.loginUser(data,successLogin, failedLogin)
  }

  const failedLogin = () => {
    setDisableBtnSubmit(false);
  };
  const successLogin = () => {
    setDisableBtnSubmit(false);
  };

  const handleError = (data) => {
    if (data.email) {
      const match = new RegExp(inputRules["Email"]).test(data.email);
      match ? setErrorEmail("") : setErrorEmail("Format email tidak sesuai");
    } else {
      setErrorEmail("Email wajib diisi");
    }
    if (data.pwd) {
      const match = new RegExp(inputRules["Password"]).test(data.pwd);
      match ? setErrorPwd("") : setErrorPwd("Password tidak sesuai");
    } else {
      setErrorPwd("Password wajib diisi");
    }
  };


  return (
    <div className="login-wrapper" style={{overflowX:"hidden", overflowY:"auto"}}>
      <Row>
        <Col lg={6} md={6} sm={12} style={{ position: 'relative' }}>
          <div className="login-form-wrapper">
            <div className="title-wrapper">
              <span>Masuk</span>
              <span>Masukkan email dan password Anda</span>
            </div>
            <div className="form-wrapper">
              <Input
                label={`Email`}
                type={`text`}
                placeholder={`Masukkan Email`}
                defultValue={email || null || ""}
                rule={`Email`}
                onChange={e => handleInput(e, setEmail, setErrorEmail)}
                error={errorEmail}
              />

              <Input
                label={`Password`}
                type={visiblePwd ? 'text' : 'password'}
                placeholder={`Masukkan Password`}
                defaultValue={pwd || null || ""}
                rule={`Password`}
                onChange={e => handleInput(e, setPwd, setErrorPwd)}
                error={errorPwd}
                rightIcon={
                  <IconButton onClick={e => setVisiblePwd((visiblePwd ? false : true))}>
                    {visiblePwd ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
            </div>

            <Button variant="contained" className="submit-btn-wrapper login-btn"
              onClick={e => handleSubmit()}
              disabled={disableBtnSubmit}
            >
              Masuk
            </Button>


            <div className="footer-wrapper">
              <span>Copyright &copy; 2022 PT Cipta Sama Abadi</span>
            </div>

          </div>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}
          style={{ backgroundImage: `url("${process.env.REACT_APP_HOST}/img/bg.jpg")` }}
          className="bg desktop"

        >
          <div className="blue-bg" />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ loginUser }) => {
  return {
    loginUserPending: getLoginUserPending(loginUser),
    loginUserError: getLoginUserError(loginUser)
  };
};

export default connect(mapStateToProps, { loginUser })(Login);
