import axios from "axios";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";
import { history } from "../../utils";

let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

export const download = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "blob",
  headers: { "x-auth-token": user?.token || "" },
});

export const backend = process.env.REACT_APP_API_URL;

export const cancelToken = axios.CancelToken;

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { "x-auth-token": user?.token || "" },
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      // Handle response error unauthorized globally
      if (
        error?.response?.status == 401
      ) {
        localStorage.removeItem(LOCAL_STORAGE_KEY)
        history.push("/")
      }

      return Promise.reject(error)
    }
  )

  return instance
}

const API = createAxiosInstance()

export default API
