export const SidebarItem = [
  {
    title: "Dashboard",
    slug: "dashboard",
    url: "/dashboard",
    icon: "grid_view",
  },
  {
    title: "User Management",
    slug: "usermanagement",
    url: "/usermanagement",
    icon: "manage_accounts",
  },
  {
    title: "Farm Management",
    slug: "farmmanagement",
    url: "/farmmanagement",
    icon: "agriculture",
  },
  {
    title: "Feed Management",
    slug: "feedmanagement",
    url: "/feedmanagement",
    icon: "grass",
  },
  {
    title: "Doc Management",
    slug: "docmanagement",
    url: "/docmanagement",
    icon: "content_paste",
  },
];
