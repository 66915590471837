import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { connect } from "react-redux";
import Warning1 from "../../assets/img/Warning.png";
import { resetPassword, listUser } from "../../store/actions/user";
import { getFilters, getPageData, getSearchText } from "../../store/reducers/user";

const ModalResetPassword = (props) => {
  const { pageData, filters, searchText } = props;

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const handleResetPassword = () => {
    setDisableBtnSubmit(true);
    const data = props.data.id;
    props.resetPassword(data, successReset, failedReset);
  };

  const failedReset = () => {
    setDisableBtnSubmit(false);
  };

  const successReset = () => {
    setTimeout(() => {
      props.setShow(false);
      props.listUser({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        role_name: filters.role.value,
        status: filters.status.value,
      });
      setDisableBtnSubmit(false);
    }, 1000);
  };

  return (
    <Modal size={"md"} onHide={() => props.setShow(false)} show={props.show} centered>
      <ModalHeader closeButton>Konfirmasi</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row justify-content-around">
            <div className="col-md-2 align-self-center">
              <img src={Warning1} alt="icon warning" />
            </div>
            <div className="col-md-10">
              <span className="text-bold">Apakah Anda yakin ingin melakukan reset password?</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outline"
          className="btn-btl-delete"
          sx={{ paddingY: "10px" }}
          onClick={() => props.setShow(false)}>
          Batal
        </Button>
        <Button
          variant="contained"
          className="btn-delete"
          sx={{ paddingY: "10px" }}
          onClick={handleResetPassword}
          disabled={disableBtnSubmit}>
          Ya,Reset
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps({ listUser }) {
  return {
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
}

export default connect(mapStateToProps, { listUser, resetPassword })(ModalResetPassword);
