import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Bar from './statusKandangStat';

// load actions
import { statusKandangData } from "../../store/actions/dashboard"

// load reducer
import { getData , getPending , getError } from "../../store/reducers/dashboard"

const Index = (props) => {
    // load props data
    const {
        statusKandangSuccess
    } = props;
    // local state variable
    const [total , setTotal] = useState(0);

    useEffect(() => {
        if(statusKandangSuccess != null || statusKandangSuccess){
            let total = 0;
            statusKandangSuccess.map(v => {
                total += v.total;
            })
            setTotal(total);
        }else{
            props.statusKandangData();
        }
    } , [statusKandangSuccess])

    // custom function
    const toPersen = (num) => {
        if(isNaN(parseFloat(num))) return 0;
        return Intl.NumberFormat('en-US')
        .format(parseFloat(num).toFixed(2))
        .toString()
        .replace("," , " ")
        .replace("." , ",")
        .replace(" " , ".")
    }

    const getStat = (arr , name) => {
        let res = 0;
        arr.map(v => {
            if(v.status.toLowerCase() === name.toLowerCase()){
                res += v.total;
            }
        });
        return res;
    }

    return  <div className="c-card">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div className="c-card-title">Status Kandang</div>
                </div>
                <div className="status-kandang-stat-wrapper">
                    <Bar
                        color={"#576EE1"}
                        num={statusKandangSuccess != null ? getStat(statusKandangSuccess , "kosong") : 0}
                        total={total}
                        name={"Kosong"}
                    />
                    <Bar
                        color={"#1CB289"}
                        num={statusKandangSuccess != null ? getStat(statusKandangSuccess , "cuci kandang") : 0}
                        total={total}
                        name={"Cuci Kandang"}
                    />
                    <Bar
                        color={"#E8A720"}
                        num={statusKandangSuccess != null ? getStat(statusKandangSuccess , "persiapan kandang") : 0}
                        total={total}
                        name={"Persiapan Kandang"}
                    />
                    <Bar
                        color={"#F97C25"}
                        num={statusKandangSuccess != null ? getStat(statusKandangSuccess , "chick in") : 0}
                        total={total}
                        name={"Chick In"}
                    />
                    <Bar
                        color={"#FF4263"}
                        num={statusKandangSuccess != null ? getStat(statusKandangSuccess , "panen") : 0}
                        total={total}
                        name={"Panen"}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center chart-stat-wrapper">
                    <div className="chart-stat-title">Total Kandang</div>
                    <div className="chart-stat-value">{toPersen(total)}</div>
                </div>
            </div>;
}

const mapStateToProps = ({ statusKandang }) => {
    return {
        statusKandangSuccess    : getData(statusKandang),
        statusKandangPending    : getPending(statusKandang),
        statusKandangError      : getError(statusKandang)
    };
};
  
export default connect(mapStateToProps , { statusKandangData })(Index);