import {
    LOGIN_USER_PENDING,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER_PENDING,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_ERROR
} from "../../actions/auth";

const initialState = {
    pending: false,
    error: null
};

export const loginUser = (state = initialState , action) => {
    switch (action.type) {
        case LOGIN_USER_PENDING:
            return {
                ...state,
                pending: true,
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state ,
                pending: false,
            }
        case LOGIN_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}

export const logoutUser = (state = initialState , action) => {
    switch(action.type) {
        case LOGOUT_USER_PENDING:
            return {
                ...state,
                pending: true
            }
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                pending: false
            }
        case LOGOUT_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}

// exporting get function
export const getLoginUserPending    = state => state.pending
export const getLoginUserError      = state => state.error
export const getLogoutUserPending   = state => state.pending
export const getLogoutUserError     = state => state.error