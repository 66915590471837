import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Input from "../../components/commons/forms/authentication/input";
import Form from 'react-bootstrap/Form';
import SelectComponent from "../../components/commons/forms/select";
import { connect} from "react-redux";

import { editFeed, listFeed } from "../../store/actions/feed";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/feed";

const ModalEditFeed = (props) => {
  // membuat variabel untuk request
  const [id, setId] = useState("");
  const [grade_feed, setGradeFeed] = useState("");
  const [kode_feed, setKodeFeed] = useState("");
  const [name_feed, setNameFeed] = useState("");
  const [defaultData, setDefaultData] = useState("");
  // variable handle error input
  const [errorGradeFeed, setErrorGradeFeed] = useState("");
  const [errorKodeFeed, setErrorKodeFeed] = useState("");
  const [errorNameFeed, setErrorNameFeed] = useState("");

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const gradeOptions = [
      { value: `Pre starter`, label: "Pre Starter" },
      { value: `Starter`, label: "Starter" },
      { value: `Grower`, label: "Grower" },
      { value: `Finisher`, label: "Finisher" },
  ];

  const { pageData, filters, searchText } = props;

  useEffect(() => {
    if (props.data) {
        setId(props.data.id);
        setGradeFeed(props.data.grade_feed);
        setKodeFeed(props.data.kode_feed);
        setNameFeed(props.data.name_feed);
        
        setErrorGradeFeed(``);
        setErrorKodeFeed(``);
        setErrorNameFeed(``);
    }
  }, [props.data]);

  const handleInput = (e, set, error, customRule = false) => {
    let value;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    data.kode_feed ? setErrorKodeFeed("") : setErrorKodeFeed("Kode Feed wajib diisi");
    data.name_feed ? setErrorNameFeed("") : setErrorNameFeed("Nama Feed wajib diisi");
    data.grade_feed ? setErrorGradeFeed("") : setErrorGradeFeed("Grade Feed wajib diisi");
  };

  const submitForm = (e) => {

      setDisableBtnSubmit(true);
      const data = {
      id,
      grade_feed,
      name_feed,
      kode_feed
      };

      handleError(data);
      
      for (var key in data) {
        if (data[key] === "") {
          setDisableBtnSubmit(false);
          return;
        }
      }

      props.editFeed(data, successEdit, failedEdit);

  };

  const successEdit = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
      props.listFeed({
          length: pageData.lengthItem,
          page: 1,
          search_text: searchText,
          grade_feed: filters.grade_feed.value,
      });
      setDisableBtnSubmit(false);
      }, 1000);
  }

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };

  const handleButtonBatal = () => {
    setDefaultState();
    props.setShow(false)
  }

  const setDefaultState = () => {
      setGradeFeed(props.data.grade_feed);
      setNameFeed(props.data.name_feed);
      setKodeFeed(props.data.kode_feed);

      setErrorGradeFeed(``);
      setErrorKodeFeed(``);
      setErrorNameFeed(``);
  };

  return (
    <div>
      <Modal
        size={"md"}
        onHide={(e) => props.setShow(false)}
        show={props.show}
        centered
      >
        <ModalHeader closeButton onClick={handleButtonBatal}>Edit Feed</ModalHeader>
        <ModalBody>
            <Input
                label={"Kode Feed"}
                placeholder={"Masukkan kode feed"}
                onChange={(e) => handleInput(e, setKodeFeed, setErrorKodeFeed)}
                value={kode_feed || ""}
                rule={"Kode Feed"}
                error={errorKodeFeed}
            />
            <Input
                label={"Name Feed"}
                placeholder={"Masukkan nama Feed"}
                onChange={(e) => handleInput(e, setNameFeed, setErrorNameFeed)}
                value={name_feed || ""}
                rule={"Name Feed"}
                error={errorNameFeed}
            />

            <SelectComponent
                label={"Grade Feed"}
                placeholder={"Masukkan grade feed"}
                error={errorGradeFeed}
                isError={errorGradeFeed !== ``}
                options={gradeOptions}
                value={{ value: grade_feed, label: grade_feed }}
                onChange={(e) => handleInput(e, setGradeFeed, setErrorGradeFeed, "Grade Feed")}
            />

        </ModalBody>
        <ModalFooter>

          <Button variant="outlined" disableElevation className="btn-btl-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleButtonBatal}
            >
            Batal
          </Button>
            
          <Button variant="contained" disableElevation className="btn-simpan-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}
            >
            Simpan
          </Button>

        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listFeed }) {
  return {
    pageData: getPageData(listFeed),
    filters: getFilters(listFeed),
    searchText: getSearchText(listFeed),
  };
}

export default connect(mapStateToProps, { editFeed, listFeed })(
  ModalEditFeed
);
