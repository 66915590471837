import React from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { listReport } from "../../../store/actions/farm/report";
import { getFilters, getPageData } from "../../../store/reducers/farm/report";

const PageReport = (props) => {
  const { pageData, filters } = props;

  const change = (page) => {
    if (page) {
      props.listReport({
        id_farm: props.match.params.id,
        length: pageData.lengthItem,
        page: page,
        tanggal: filters.tanggal.value,
        status: filters.status.value,
      });
    }
  };

  const getPage = (length, total, activePage) => {
    const totalPage = Math.ceil(total / length);
    const activePageLessthanThree = activePage < 3 ? 1 : 2;
    const minusOfStartListPage = activePage === totalPage ? activePageLessthanThree : 1;
    const startListPage = activePage > 1 ? activePage - minusOfStartListPage : 1;
    const plusActivePage = activePage === 1 ? 2 : 1;
    const plusOfEndListPage = activePage + 1 > totalPage ? activePage : activePage + plusActivePage;
    const endListPage = totalPage <= 3 ? totalPage : plusOfEndListPage;
    const listPageNum = [];
    for (let i = startListPage; i <= endListPage; i++) {
      listPageNum.push(i);
    }
    return listPageNum.map((v, k) => {
      return (
        <div key={k} className={`page-item page-num ${activePage === v ? "active" : ""}`} onClick={(e) => change(v)}>
          {v}
        </div>
      );
    });
  };

  return (
    <>
      <div className="pagination-wrapper">
        <div className="data-count-info">
          {pageData.total < pageData.lengthItem ? pageData.total : pageData.lengthItem} dari {pageData.total ? pageData.total : 0} data
        </div>
        <div className="page-wrapper">
          <div className={`page-item page-btn ${pageData.page === 1 ? "disabled" : ""}`} onClick={(e) => change(pageData.page === 1 ? undefined : pageData.page - 1)}>
            <ArrowBack />
          </div>

          {getPage(pageData.lengthItem, pageData.total, pageData.page)}

          <div
            className={`page-item page-btn ${pageData.page === Math.ceil(pageData.total / pageData.lengthItem) ? "disabled" : ""}`}
            onClick={(e) => change(pageData.page === Math.ceil(pageData.total / pageData.lengthItem) ? undefined : pageData.page + 1)}
          >
            <ArrowForward />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ listReport }) => {
  return {
    pageData: getPageData(listReport),
    filters: getFilters(listReport),
  };
};

export default connect(mapStateToProps, { listReport })(withRouter(PageReport));
