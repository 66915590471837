import { connect } from "react-redux";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import ModalBeratAyam from "./ModalTableBeratAyam";
import { numberWithCommas } from "../../utils/constants";
import { customDecimal } from "../../utils/report/reportCalculation";
import { Col, Row } from "react-bootstrap";

// load reducers
import { getDashboard, getDashboardPending, getDashboardError } from "../../store/reducers/dashboard";
import { useState } from "react";
import { Card } from "react-bootstrap";

const Index = (props) => {
  // load props data
  const { dashboardDataPending, dashboardDataSuccess, dashboardDataError } = props;

  // create state variabel
  const [modalShow, setModalShow] = useState(false);

  const getSymbol = (num) => {
    return num >= 0 ? "+" : "";
  };

  const numFormat = (num) => {
    return numberWithCommas(customDecimal(num));
  };

  const showTopFive = (data) => {
    let i = 1;
    return data.map((v, i) => {
      if (i <= 5) {
        i++;
        return (
          <TableRow key={i}>
            <TableCell align="center">{numFormat(v.berat)}</TableCell>
            <TableCell align="center">{numFormat(v.jumlah)}</TableCell>
          </TableRow>
        );
      }
    });
  };

  return (
    <div className="stok-ayam-wrapper">
      <div className="stok-ayam-title">Stok Ayam</div>
      <div style={{ padding: "25px" }}>
        <Card style={{ marginBottom: "8px", borderRadius: "8px" }}>
          <Row className="stok-ayam-total-panen-card">
            <Col sm={4}>Total Panen</Col>
            <Col
              sm={4}
              className={dashboardDataSuccess ? (dashboardDataSuccess.sisa_panen < 0 ? "bad" : "good") : "good"}
            >
              {`(${getSymbol(dashboardDataSuccess?.total_panen_persen || 0)}${
                dashboardDataSuccess ? numFormat(dashboardDataSuccess?.total_panen_persen) : 0
              }%)`}
            </Col>
            <Col sm={4}>{dashboardDataSuccess ? numFormat(dashboardDataSuccess.total_panen) : 0}</Col>
          </Row>
        </Card>
        <Card style={{ borderRadius: "8px" }}>
          <Row className="stok-ayam-sisa-panen-card">
            <Col sm={6}>Sisa Panen</Col>
            <Col sm={6}>{dashboardDataSuccess ? numFormat(dashboardDataSuccess.sisa_panen) : 0}</Col>
          </Row>
        </Card>
      </div>
      <div className="top-five-ayam">
        <Table size="small" className="table-user">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  background: "#F4F5F7",
                  borderBottom: "none",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
              >
                {"Berat (kg)"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#F4F5F7",
                  borderBottom: "none",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
              >
                {"Jumlah (ekor)"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardDataSuccess?.beratAyamList?.length > 0 ? (
              showTopFive(dashboardDataSuccess.beratAyamList)
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Tidak ada data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <span className="top-five-expand-btn" onClick={(e) => setModalShow(!modalShow)}>
          {"Lihat Selengkapnya >"}
        </span>
        <ModalBeratAyam show={modalShow} setShow={setModalShow} data={dashboardDataSuccess?.beratAyamList} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ dashboardData }) => {
  return {
    dashboardDataSuccess: getDashboard(dashboardData),
    dashboardDataPending: getDashboardPending(dashboardData),
    dashboardDataError: getDashboardError(dashboardData),
  };
};

export default connect(mapStateToProps, {})(Index);
