import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from '@mui/material';
import { connect } from "react-redux";
import Warning1 from "../../assets/img/Warning.png";
import { deleteDoc, listDoc } from "../../store/actions/doc";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/doc";

const ModalDeleteDoc = (props) => {
  const { pageData, filters, searchText } = props;

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const handleDelete = () => {
    setDisableBtnSubmit(true);
    const data = props.data.id;
    props.deleteDoc(data, successDelete, failedDelete);
  };

  const failedDelete = () => {
    setDisableBtnSubmit(false);
  };

  const successDelete = () => {
    setTimeout(() => {
      props.setShow(false);
      props.listDoc({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        grade_doc: filters.grade_doc.value,
      });
      setDisableBtnSubmit(false);
    }, 1000);
  }

  return (
    <div>
      <Modal
        size={"md"}
        onHide={() => props.setShow(false)}
        show={props.show}
        centered>
        <ModalHeader closeButton>Konfirmasi</ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row justify-content-around">
              <div className="col-md-1 icon-warning">
                <img src={Warning1} alt="icon warning" />
              </div>
              <div className="col-md-11 text-icon-warning">
                <span className="text-bold">
                  Apakah anda yakin ingin menghapus data ini?
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
        <Button
          variant="outline"
          className="btn-btl-delete"
          sx={{ paddingY: "10px" }}
          onClick={()=> props.setShow(false)}>
            Batal
        </Button>
        <Button
          variant="contained"
          className="btn-delete"
          sx={{ paddingY: "10px" }}
          onClick={handleDelete}
          disabled={disableBtnSubmit}>
            Ya,Hapus
        </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps({ listDoc }) {
  return {
    pageData: getPageData(listDoc),
    filters: getFilters(listDoc),
    searchText: getSearchText(listDoc),
  };
}

export default connect(mapStateToProps, { listDoc, deleteDoc })(ModalDeleteDoc);
