import { connect } from "react-redux";
import { listUser, searchUser } from "../../store/actions/user";
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/user";

const SearchBox = (props) => {
  const { pageData, filters, searchText } = props;

  const [searchTextShow, setSearchTextShow] = useState(false);

  const handleSearchChange = (e) => {
    props.searchUser(e.target.value);
    props.listUser({
      length: pageData.lengthItem,
      page: 1,
      search_text: e.target.value,
      role_code: filters.role.value,
      status: filters.status.value,
    });
  };

  return (
    <div
      className={`searchbox-wrapper ${searchTextShow ? "searchbox-show" : ""}`}
    >
      <input
        type="text"
        name="search-text"
        placeholder="Masukkan kata kunci"
        onChange={handleSearchChange}
        defaultValue={searchText}
      />
      <IconButton onClick={(e) => setSearchTextShow(!searchTextShow)}>
        <Search />
      </IconButton>
    </div>
  );
};

const mapStateToProps = ({ listUser }) => {
  return {
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
};

export default connect(mapStateToProps, { listUser, searchUser })(SearchBox);
