import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import SelectComponent from "../../components/commons/forms/select";
import { Divider } from "@mui/material";
import ChartBA from "./chartBeratAyam";
import ChartTK from "./chartTingkatKematian";
import StatusKandang from "./statusKandang";
import SisaPakan from "./sisaPakan";
import StokAyam from "./stokAyam";
import TopFiveData from "./TopFiveData";
import { numberWithCommas } from "../../utils/constants";
import { customDecimal } from "../../utils/report/reportCalculation";

// load icons
import { Restaurant, WarningRounded, HealthAndSafety } from "@mui/icons-material";

// load actions
import {
  listFarm,
  updateFarm,
  dashboardData,
  chartTingkatKematian,
  chartBeratAyam,
  topFiveData,
  sisaPakanData
} from "../../store/actions/dashboard";

// load reducers
import {
  getListFarm,
  getListFarmError,
  getListFarmPending,
  getFilter,
  getDashboard,
  getDashboardPending,
  getDashboardError,
  getChartFilter,
  getData,
  getPending,
  getError,
} from "../../store/reducers/dashboard";
import { isEmpty } from "lodash";

const Index = (props) => {
  // load props data
  const {
    listFarmSuccess,
    listFarmPending,
    listFarmError,
    filterKandang,
    dashboardDataPending,
    dashboardDataSuccess,
    dashboardDataError,
    TKChartFilter,
    BAChartFilter,
    TFDataSuccess,
    sisaPakanSuccess
  } = props;

  // create local state variabel
  const [topFivePopulasi, setTopFivePopulasi] = useState([]);
  const [topFivePanen, setTopFivePanen] = useState([]);
  const [topFiveDeplesi, setTopFiveDeplesi] = useState([]);
  //create local state for pakan information
  const [pakanMasuk, setPakanMasuk] = useState(0);
  const [pakanTerpakai, setPakanTerpakai] = useState(0);
  const [sisaPakan, setSisaPakan] = useState(0);

  // custom function
  const numFormat = (num) => {
    return numberWithCommas(customDecimal(num));
  };

  const getSymbol = (num) => {
    return num >= 0 ? "+" : "";
  };

  useEffect(() => {
    setTopFivePopulasi(TFDataSuccess?.topPopulasi);
    setTopFivePanen(TFDataSuccess?.topPanen);
    setTopFiveDeplesi(TFDataSuccess?.topDeplesi);
  }, [TFDataSuccess]);

  useEffect(() => {
    props.listFarm();
    props.topFiveData();
  }, []);

  useEffect(() => {
    if (!isEmpty(listFarmSuccess)) {
      props.updateFarm(listFarmSuccess[0]);
    }
  }, [listFarmSuccess]);

  // use effect hook for taking dashboard data
  useEffect(() => {
    if (!isEmpty(filterKandang)) {
      props.dashboardData(filterKandang.value);
      props.chartTingkatKematian({ farm: filterKandang.value, year: TKChartFilter });
      props.chartBeratAyam({ farm: filterKandang.value, bulan: BAChartFilter?.bulan, tahun: BAChartFilter?.tahun });
      props.sisaPakanData({id: filterKandang.value});
    }
  }, [filterKandang]);

  useEffect(() => {
    if(sisaPakanSuccess){
      if(sisaPakanSuccess === NaN){
        setPakanMasuk(0);
        setPakanTerpakai(0);
        setSisaPakan(0);
      }else{
        setPakanMasuk(sisaPakanSuccess?.pakan_masuk);
        setPakanTerpakai(sisaPakanSuccess?.pakan_pakai);
        setSisaPakan(sisaPakanSuccess?.pakan_sisa);
      }
    }
  }, [sisaPakanSuccess]);

  if (dashboardDataPending || listFarmPending) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minWidth: "100%", minHeight: "100vh" }}
      >
        Mengambil data dashboard...
      </div>
    );
  } else if (dashboardDataError || listFarmError) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minWidth: "100%", minHeight: "100vh" }}
      >
        Gagal mengambil data dashboard...
      </div>
    );
  } else {
    return (
      <Container>
        <Row>
          <Col xl={8} className="px-5 py-4" style={{ borderRight: "5px solid #F3F3F8", minHeight: "100vh" }}>
            <div className="d-flex justify-content-between align-items-start">
              <div className="tx-bold mt-2" style={{ fontSize: "27px" }}>
                Dashboard
              </div>
              <SelectComponent
                options={listFarmSuccess}
                isAsync={true}
                asyncUrl={"/v1/master_data/farm"}
                onChange={(e) => props.updateFarm(e)}
                isClearable={false}
                value={filterKandang}
                customstyle={{
                  width: "200px !important",
                }}
              />
            </div>
            <Divider className="my-4" />
            <Row>
              <Col xl={4} className="px-3" style={{ position: "relative" }}>
                <div className="tracker-wrapper p-4">
                  <div className="icon-wrapper">
                    <div
                      className="icon-bg"
                      style={{ backgroundColor: "rgba(232, 167, 32,.1)", color: "rgb(232, 167, 32)" }}
                    >
                      <Restaurant />
                    </div>
                  </div>
                  <span className="mt-3">Konversi Pakan</span>
                  <div className="mt-2 tracker-value-wrapper">
                    <span className="tracker-value">
                      {dashboardDataSuccess ? numFormat(dashboardDataSuccess.koversi_pakan) : 0}
                    </span>
                    <span
                      className={`tracker-status ${
                        !dashboardDataSuccess
                          ? "bad"
                          : dashboardDataSuccess.konversi_pakan_status != "Naik"
                          ? "good"
                          : "bad"
                      }`}
                    >{`(${
                      dashboardDataSuccess?.konversi_pakan_status ? dashboardDataSuccess.konversi_pakan_status : "-"
                    })`}</span>
                  </div>
                </div>
              </Col>
              <Col xl={4} className="px-3">
                <div className="tracker-wrapper p-4">
                  <div className="icon-wrapper">
                    <div
                      className="icon-bg"
                      style={{ backgroundColor: "rgba(255, 66, 99,.1)", color: "rgb(255, 66, 99)" }}
                    >
                      <WarningRounded />
                    </div>
                  </div>
                  <span className="mt-3">Tingkat Kematian</span>
                  <div className="mt-2 tracker-value-wrapper">
                    <span className="tracker-value">
                      {dashboardDataSuccess ? numFormat(dashboardDataSuccess?.tingkat_kematian) : 0}
                    </span>
                    <span
                      className={`tracker-status ${
                        !dashboardDataSuccess
                          ? "bad"
                          : dashboardDataSuccess?.tingkat_kematian_persen < 0
                          ? "good"
                          : "bad"
                      }`}
                    >{`(${getSymbol(dashboardDataSuccess?.tingkat_kematian_persen || 0)}${
                      dashboardDataSuccess ? numFormat(dashboardDataSuccess?.tingkat_kematian_persen) : 0
                    }%)`}</span>
                  </div>
                </div>
              </Col>
              <Col xl={4} className="px-3">
                <div className="tracker-wrapper p-4">
                  <div className="icon-wrapper">
                    <div
                      className="icon-bg"
                      style={{ backgroundColor: "rgba(28, 55, 190 , .1)", color: "rgb(28, 55, 190)" }}
                    >
                      <HealthAndSafety />
                    </div>
                  </div>
                  <span className="mt-3">Berat Ayam</span>
                  <div className="mt-2 tracker-value-wrapper">
                    <span className="tracker-value">
                      {dashboardDataSuccess ? numFormat(dashboardDataSuccess.berat_ayam) : 0}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <ChartTK />
            <ChartBA />
            <StatusKandang />

            {/* Card Pakan */}
            <div className="c-card-pakan">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div className="c-card-title ps-5 pt-5 pe-5">Pakan</div>
                </div>
                <div className="status-kandang-stat-wrapper pb-4">
                  <SisaPakan
                    name={'Pakan Masuk'}
                    color={"#F97C25"}
                    num = {numberWithCommas(Number(pakanMasuk))}
                    numBar = {pakanMasuk}
                    total = {pakanMasuk + pakanTerpakai}
                  />
                  <SisaPakan
                    name={'Pakan Terpakai'}
                    color={"#FF4263"}
                    num = {numberWithCommas(Number(pakanTerpakai))}
                    numBar = {pakanTerpakai}
                    total = {pakanMasuk + pakanTerpakai}
                  />
                </div>
                <div className="bg-pakan-dash ps-3">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="cart-title-pakan ps-5">Sisa Pakan</div>
                    </div>
                    <div className="col-md-3">
                      <div className="chart-stat-value ps-5">{numberWithCommas(Number(sisaPakan))} sak</div>
                    </div>
                  </div>
                </div>
            </div>

          </Col>

          <Col xl={4}>
            <div className="side-dashboard-wrapper">
              <StokAyam />
              <TopFiveData
                name={"Tingkat Populasi"}
                data={topFivePopulasi}
                icon={<WarningRounded style={{ color: "#1CB289", fontSize: "35px" }} />}
                ticker={"ekor"}
              />
              <TopFiveData
                name={"Tingkat Panen"}
                data={topFivePanen}
                icon={<WarningRounded style={{ color: "#FF7A00", fontSize: "35px" }} />}
                ticker={"ekor"}
              />
              <TopFiveData
                name={"Tingkat Deplesi"}
                data={topFiveDeplesi}
                icon={<WarningRounded style={{ color: "#FF4263", fontSize: "35px" }} />}
                ticker={"%"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

const mapStateToProps = ({ listFarmDB, dashboardData, tingkatKematian, beratAyam, topFiveData, sisaPakan}) => {
  return {
    listFarmSuccess: getListFarm(listFarmDB),
    listFarmPending: getListFarmPending(listFarmDB),
    listFarmError: getListFarmError(listFarmDB),
    filterKandang: getFilter(listFarmDB),
    dashboardDataSuccess: getDashboard(dashboardData),
    dashboardDataPending: getDashboardPending(dashboardData),
    dashboardDataError: getDashboardError(dashboardData),
    TKChartFilter: getChartFilter(tingkatKematian),
    BAChartFilter: getChartFilter(beratAyam),
    TFDataSuccess: getData(topFiveData),
    TFDataPending: getPending(topFiveData),
    TFDataError: getError(topFiveData),
    sisaPakanSuccess: getData(sisaPakan),
    sisaPakanPending: getPending(sisaPakan),
    sisaPakanError: getError(sisaPakan)
  };
};

export default connect(mapStateToProps, {
  listFarm,
  updateFarm,
  chartTingkatKematian,
  chartBeratAyam,
  dashboardData,
  topFiveData,
  sisaPakanData
})(Index);
