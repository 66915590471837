import { toInteger, toNumber } from "lodash";

export const customDecimal = (num) => {
  return Math.round(num * 1000) / 1000;
};

export const toNum = (string) => {
  if (typeof string === "number") return string;
  if (string.includes(",")) return toNumber(string.replace(/\./g, "").replace(",", "."));
  return toInteger(string.replace(/\./g, ""));
};

export const calculatePopulasiSaatIni = (chickIn, kumKematian, kematian) => {
  if (typeof chickIn == "string") return toNum(chickIn) - (kumKematian + toNum(kematian));
  return chickIn - (kumKematian + toNum(kematian));
};

export const calculateFCR = (kumJumlahHabisPakan, jumlahHabisPakan, kumTonase, tonase) => {
  const sumKumTonase = kumTonase + tonase;
  if (sumKumTonase === 0) return 0;
  return customDecimal(((kumJumlahHabisPakan + toNum(jumlahHabisPakan)) * 50) / sumKumTonase);
};

export const calculateDeplesi = (chickIn, kumAyamTerjual, ayamTerjual) => {
  if (chickIn === 0) return 0;
  if (typeof chickIn == "string") return customDecimal(((toNum(chickIn) - (kumAyamTerjual + ayamTerjual)) * 100) / toNum(chickIn));
  return customDecimal(((chickIn - (kumAyamTerjual + ayamTerjual)) * 100) / chickIn);
};

export const calculateBeratRataRata = (kumTonase, tonase, kumAyamTerjual, ayamTerjual) => {
  const sumKumAyamTerjual = kumAyamTerjual + ayamTerjual;
  if (sumKumAyamTerjual === 0) return 0;
  return customDecimal((kumTonase + tonase) / sumKumAyamTerjual);
};

export const calculateSisaStokAyam = (populasiSaatIni, kumAyamTerjual, ayamTerjual) => {
  return populasiSaatIni - (kumAyamTerjual + ayamTerjual);
};

export const calculateCumUmur = (umurAyam, ayamTerjual) => {
  return umurAyam * ayamTerjual;
};

export const calculateRUP = (kumCumUmur, cumUmur, kumAyamTerjual, ayamTerjual) => {
  const sumKumAyamTerjual = kumAyamTerjual + ayamTerjual;
  const sumKumCumUmur = kumCumUmur + cumUmur;
  if (sumKumCumUmur === 0 || sumKumAyamTerjual === 0) return 0;
  return customDecimal(sumKumCumUmur / sumKumAyamTerjual);
};

export const calculateIP = (deplesi, beratRataRata, fcr, rup) => {
  if (fcr === 0 || rup === 0) return 0;
  return Math.floor(customDecimal((((100 - deplesi) * beratRataRata) / fcr / rup) * 100));
};
