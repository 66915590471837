import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowBack } from "@mui/icons-material";

const BaseFormReport = ({ title, children, handleBack }) => {
  return (
    <div style={{ margin: "0px 57px 0px 10px" }}>
      <Row style={{ padding: "46px 0px 59px 0px" }}>
        <Col sm={1} className="d-flex justify-content-center align-items-center">
          <ArrowBack sx={{ cursor: "pointer" }} onClick={handleBack} />
        </Col>
        <Col sm={11}>
          <span
            className="text-extra-bold"
            style={{
              display: "inline",
              fontSize: "24px",
              fontFamily: "PlusJakartaSans-Bold",
              lineHeight: "36px",
            }}
          >
            {title}
          </span>
        </Col>
      </Row>
      <Row>
        <Col sm={1} />
        <Col sm={11}>{children}</Col>
      </Row>
    </div>
  );
};

export default BaseFormReport;
