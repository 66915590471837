import API from '../API'
import {actionSuccess , actionError , actionPending} from '../action_types'

export const LIST_ROLE_PENDING = `LIST_ROLE_PENDING`
export const LIST_ROLE_SUCCESS = `LIST_ROLE_SUCCESS`
export const LIST_ROLE_ERROR   = `LIST_ROLE_ERROR`

const LIST_ROLE_URL = `/v1/master_data/role`

export const listRole = (param = {}) => dispatch => {
    dispatch(actionPending(LIST_ROLE_PENDING))
    API.get(LIST_ROLE_URL , param)
        .then(res => {
            const listRole = [];
            res.data.data.map(v => {
                listRole.push({
                    value: v.code,
                    label: v.name
                })
            })
            dispatch(actionSuccess(LIST_ROLE_SUCCESS , listRole))
        })
        .catch(error => {
            dispatch(actionError(LIST_ROLE_ERROR , error))
        })
}