import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Warning1 from '../../assets/img/Warning.png';
import { Button } from '@mui/material';
import { connect } from "react-redux";
import { listFarm, deleteFarm } from "../../store/actions/farm";
import {
    getDeleteFarmPending,
    getDeleteFarmError,
    getPageData,
    getFilters,
    getSearchText,
} from "../../store/reducers/farm";

const ModalDeleteFarm = (props) => {
    const { pageData, filters, searchText } = props;

    //req id
    const handleDelete = () => {
        const data = props.data.id
        props.deleteFarm(data)
        setTimeout(() => {
            props.setShow(false)
            props.listFarm({
                length: pageData.lengthItem,
                page: pageData.page,
                search_text: searchText,
                kepala_kandang: filters.kepala_kandang.value,
                status_farm: filters.status_farm.value,
            });
        }, 1000)
    }
    return (
        <>
            <Modal size={"md"} onHide={e => props.setShow(false)} show={props.show} centered>
                <ModalHeader closeButton>Konfirmasi</ModalHeader>
                <ModalBody>
                    <div className='container-fluid'>
                        <div className='row justify-content-around'>
                            <div className='col-md-1 icon-warning'>
                                <img src={Warning1} alt="icon warning" />
                            </div>
                            <div className='col-md-11 text-icon-warning'><span className='text-bold'>Apakah Anda yakin ingin menghapus data ini?</span></div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outlined" disableElevation className="btn-btl-delete"
                        sx={{
                            paddingY: "10px",
                        }}
                        onClick={e => props.setShow(false)}
                    >
                        Batal
                    </Button>

                    <Button variant="contained" disableElevation className="btn-delete"
                        sx={{
                            paddingY: "10px",
                        }}
                        onClick={e => handleDelete()}
                    >
                        Ya,Hapus
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

function mapStateToProps({ listFarm }) {
    return {
        pageData: getPageData(listFarm),
        filters: getFilters(listFarm),
        searchText: getSearchText(listFarm),
        deleteFarmPending: getDeleteFarmPending(listFarm),
        deleteFarmError: getDeleteFarmError(listFarm),
    };
}

export default connect(mapStateToProps, { listFarm, deleteFarm })(ModalDeleteFarm);