import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Warning1 from '../../assets/img/Warning.png';
import { Button } from '@mui/material';
import {connect} from "react-redux"
import { deleteFeed, listFeed } from '../../store/actions/feed';
import { 
    getPageData,
    getSearchText,
    getFilters,
    getDeleteFeedError, 
    getDeleteFeedPending 
} from '../../store/reducers/feed';

const ModalDeleteFeed = (props) => {

    const { pageData, filters, searchText } = props;

    //req id
    const handleDelete = () => {
        const data = props.data.id
        props.deleteFeed(data)
        setTimeout(() => {
            props.setShow(false)
            props.listFeed({
                length: pageData.lengthItem,
                page: 1,
                search_text: searchText,
                grade_feed: filters.grade_feed.value,
            });
        } , 1000)
    }
    
    return (
    <>
        <Modal size={"md"} onHide={e => props.setShow(false)} show={props.show} centered>
            <ModalHeader closeButton>Konfirmasi</ModalHeader>
            <ModalBody>
                <div className='container-fluid'>
                <div className='row justify-content-around'>
                    <div className='col-md-1 icon-warning'>
                        <img src={Warning1} alt="icon warning"/>
                    </div>
                    <div className='col-md-11 text-icon-warning'><span className='text-bold'>Apakah Anda yakin ingin menghapus data ini?</span></div>
                </div>
                </div>
            </ModalBody>
            <ModalFooter>

                <Button variant="outlined" disableElevation className="btn-btl-delete"
                    sx={{
                    paddingY: "10px",
                    }}
                    onClick={e => props.setShow(false)}
                    >
                    Batal
                </Button>
                
                <Button variant="contained" disableElevation className="btn-delete"
                    sx={{
                    paddingY: "10px",
                    }}
                    onClick={e => handleDelete()}
                    >
                    Ya,Hapus
                </Button>
                
            </ModalFooter>
        </Modal>
    </>
    )
}

function mapStateToProps({ listFeed }) {
    return {
        pageData: getPageData(listFeed),
        filters: getFilters(listFeed),
        searchText: getSearchText(listFeed),
        deleteFeedPending: getDeleteFeedPending(listFeed),
        deleteFeedError: getDeleteFeedError(listFeed),
    };
}
  
  
export default connect(mapStateToProps , {listFeed, deleteFeed})(ModalDeleteFeed); 
