import { actionSuccess, actionError, actionPending } from "../action_types";
import API from "../API";
import { toastSuccess, toastError } from "../../../components/toast";

export const UPDATE_DOC_PAGE = `UPDATE_DOC_PAGE`;
export const UPDATE_FILTER_DOC = `UPDATE_FILTER_DOC`;
export const UPDATE_SEARCH_DOC = `UPDATE_SEARCH_DOC`;
export const LIST_DOC_PENDING = `LIST_DOC_PENDING`;
export const LIST_DOC_SUCCESS = `LIST_DOC_SUCCESS`;
export const LIST_DOC_ERROR = `LIST_DOC_ERROR`;
export const TAMBAH_DOC_PENDING = `TAMBAH_DOC_PENDING`;
export const TAMBAH_DOC_SUCCESS = `TAMBAH_DOC_SUCCESS`;
export const TAMBAH_DOC_ERROR = `TAMBAH_DOC_ERROR`;
export const EDIT_DOC_PENDING = `EDIT_DOC_PENDING`;
export const EDIT_DOC_SUCCESS = `EDIT_DOC_SUCCESS`;
export const EDIT_DOC_ERROR = `EDIT_DOC_ERROR`;
export const DELETE_DOC_PENDING = `DELETE_DOC_PENDING`;
export const DELETE_DOC_SUCCESS = `DELETE_DOC_SUCCESS`;
export const DELETE_DOC_ERROR = `DELETE_DOC_ERROR`;

const DOC_URL = `/v1/master_data/doc`;

export const listDoc =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_DOC_PENDING));
    API.get(DOC_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_DOC_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_DOC_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_DOC_ERROR, error));
        toastError(error);
      });
  };

export const tambahDoc =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(TAMBAH_DOC_PENDING));
    API.post(DOC_URL, param)
      .then((res) => {
        dispatch(actionSuccess(TAMBAH_DOC_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TAMBAH_DOC_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const editDoc =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(EDIT_DOC_PENDING));
    API.put(DOC_URL, param)
      .then((res) => {
        dispatch(actionSuccess(EDIT_DOC_SUCCESS));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_DOC_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const deleteDoc =
  (id = null, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    const param = { id: id };
    dispatch(actionPending(DELETE_DOC_PENDING));
    API.delete(DOC_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DELETE_DOC_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(DELETE_DOC_ERROR, error));
        toastError(error);
        callbackFailed();
      });
  };

export const filterDoc =
  (param = {}) =>
  (dispatch) => {
    const grade_doc = param.grade_doc ? param.grade_doc : {};
    dispatch(actionSuccess(UPDATE_FILTER_DOC, { grade_doc }));
  };

export const searchDoc =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_DOC, param));
  };
