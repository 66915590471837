import { numberWithCommas } from "../../utils/constants";
import { customDecimal } from "../../utils/report/reportCalculation";

const Index = (props) => {
  const { name, data, icon, ticker } = props;

  return (
    <div className="top-five-wrapper">
      <div className="top-five-title-wrapper">
        <div className="top-five-title-icon">{icon}</div>
        <div className="top-five-title-text">
          <span>Top 5</span>
          <span>{name}</span>
        </div>
      </div>
      <div className="top-five-data-wrapper">
        {data && data.length > 0 ? (
          data.map((d, i) => {
            return (
              <div key={i} className="row" style={{ marginBottom: "30px" }}>
                <div className="col-2">
                  <div className="d-flex justify-content-center h-100" style={{ fontSize: "18px", fontWeight: "800" }}>
                    {i + 1}
                  </div>
                </div>
                <div className="col-10">
                  <div className="text-bold" style={{ fontSize: "14px" }}>
                    {d.farm}
                  </div>
                  <div style={{ color: "#919392", fontSize: "13px", fontWeight: "500" }}>
                    {numberWithCommas(customDecimal(d.value))} {ticker}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="tf-empty">Belum ada data</div>
        )}
      </div>
    </div>
  );
};

export default Index;
