// LOAD ACTION NAME FROM ACTION FILE
import {
  LIST_FARMDB_PENDING,
  LIST_FARMDB_SUCCESS,
  LIST_FARMDB_ERROR,
  UPDATE_FARM_FILTER,
  DASHBOARD_DATA_PENDING,
  DASHBOARD_DATA_SUCCESS,
  DASHBOARD_DATA_ERROR,
  TK_CHART_DATA_PENDING,
  TK_CHART_DATA_SUCCESS,
  TK_CHART_DATA_ERROR,
  TK_CHART_FILTER_UPDATE,
  BA_CHART_DATA_PENDING,
  BA_CHART_DATA_SUCCESS,
  BA_CHART_DATA_ERROR,
  BA_CHART_FILTER_UPDATE,
  SK_DATA_PENDING,
  SK_DATA_SUCCESS,
  SK_DATA_ERROR,
  TF_DATA_PENDING,
  TF_DATA_SUCCESS,
  TF_DATA_ERROR,
  DASHBOARD_SYNC_PENDING,
  DASHBOARD_SYNC_SUCCESS,
  DASHBOARD_SYNC_ERROR,
  SP_DATA_PENDING,
  SP_DATA_SUCCESS,
  SP_DATA_ERROR
} from "../../actions/dashboard";

const initialState = {
  pending: false,
  error: null,
  listFarmDB: [],
  filterKandang: {},
  dashboardData: null,
};
const chartInitialState = {
  pending: false,
  error: null,
  data: null,
  filter: new Date().getFullYear().toString(),
};

const baseState = {
  pending: false,
  error: null,
  data: null,
};

const convertFilterKandang = (param) => {
  return {
    value: param.value,
    label: param.label.length > 20 ? param.label.substring(0, 20) + "..." : param.label,
  };
};

export const topFiveData = (state = baseState, action) => {
  switch (action.type) {
    case TF_DATA_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case TF_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case TF_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const statusKandang = (state = baseState, action) => {
  switch (action.type) {
    case SK_DATA_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case SK_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case SK_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const beratAyam = (
  state = {
    ...chartInitialState,
    filter: {
      bulan: new Date().getMonth() + 1,
      tahun: new Date().getFullYear(),
    },
  },
  action
) => {
  switch (action.type) {
    case BA_CHART_DATA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case BA_CHART_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case BA_CHART_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case BA_CHART_FILTER_UPDATE:
      return {
        ...state,
        filter: action.data,
      };
    default:
      return state;
  }
};

export const tingkatKematian = (state = chartInitialState, action) => {
  switch (action.type) {
    case TK_CHART_DATA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case TK_CHART_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case TK_CHART_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case TK_CHART_FILTER_UPDATE:
      return {
        ...state,
        filter: action.data,
      };
    default:
      return state;
  }
};

export const dashboardData = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_DATA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        dashboardData: action.data,
      };
    case DASHBOARD_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const listFarmDB = (state = initialState, action) => {
  switch (action.type) {
    case LIST_FARMDB_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_FARMDB_SUCCESS:
      return {
        ...state,
        pending: false,
        listFarmDB: action.data,
      };
    case LIST_FARMDB_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_FARM_FILTER:
      return {
        ...state,
        filterKandang: convertFilterKandang(action.data),
      };
    default:
      return state;
  }
};

export const syncDashboard = (state = baseState, action) => {
  switch (action.type) {
    case DASHBOARD_SYNC_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DASHBOARD_SYNC_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DASHBOARD_SYNC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
export const sisaPakan = (state = baseState, action) => {
  switch (action.type) {
    case SP_DATA_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case SP_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case SP_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getListFarm = (state) => state.listFarmDB;
export const getListFarmError = (state) => state.error;
export const getListFarmPending = (state) => state.pending;
export const getDashboard = (state) => state.dashboardData;
export const getDashboardPending = (state) => state.pending;
export const getDashboardError = (state) => state.error;
export const getChart = (state) => state.data;
export const getChartPending = (state) => state.pending;
export const getChartError = (state) => state.error;
export const getFilter = (state) => state.filterKandang;
export const getChartFilter = (state) => state.filter;
export const getData = (state) => state.data;
export const getPending = (state) => state.pending;
export const getError = (state) => state.error;
