import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../../components/commons/forms/select";
import DateRangePicker from "../../../components/commons/forms/datepicker/DateRangePicker";

import { listReport, filterReport } from "../../../store/actions/farm/report";
import { getFilters, getPageData } from "../../../store/reducers/farm/report";
import { defaultDateFormat, formatDate } from "../../../utils";

export const ModalFilterReport = (props) => {
  const { filters, pageData } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterTanggal, setFilterTanggal] = useState("");
  const [filterStatus, setFilterStatus] = useState({});
  const [errorTanggal, setErrorTanggal] = useState("");

  const statusOptions = [
    { label: "Ontime", value: "Ontime" },
    { label: "Late", value: "Late" },
  ];

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setFilterTanggal(formatDate(start).concat(" - ", formatDate(end)));
      setErrorTanggal("");
    } else if (start) {
      setFilterTanggal(formatDate(start).concat(" - "));
    } else {
      setFilterTanggal("");
      setErrorTanggal("");
    }
  };

  useEffect(() => {
    if (filters.tanggal != "") {
      setStartDate(filters.tanggal ? new Date(defaultDateFormat(filters.tanggal.split(" - ")[0])) : null);
      setEndDate(filters.tanggal ? new Date(defaultDateFormat(filters.tanggal.split(" - ")[1])) : null);
      setFilterTanggal(filters.tanggal);
    }
    if (filters.status.value != undefined) {
      setFilterStatus(filters.status);
    }
  }, [props.show]);

  const submitForm = () => {
    if (startDate && !endDate) {
      setErrorTanggal("Tanggal akhir harus diisi");
      return;
    }
    setTimeout(() => {
      props.filterReport({ tanggal: filterTanggal, status: filterStatus });
      props.setShow(false);
      setErrorTanggal("");
    }, 100);
  };

  const handleClose = () => {
    props.setShow(false);
    setFilterTanggal("");
    setFilterStatus({});
    setStartDate(null);
    setEndDate(null);
    setErrorTanggal("");
  };

  const handleReset = () => {
    props.filterReport({ tanggal: "", status: {} });
    handleClose();
  };

  return (
    <Modal size={"md"} onHide={handleClose} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>
        <div className="select-wrapper">
          <DateRangePicker
            label={"Tanggal"}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            placeholder={"Masukkan Tanggal"}
            error={errorTanggal}
            value={filterTanggal}
          />
        </div>
        <div className="select-wrapper">
          <SelectComponent
            options={statusOptions}
            label={"Status"}
            onChange={(e) => {
              setFilterStatus(e);
            }}
            value={
              filterStatus === null
                ? { value: "", label: "Semua Status" }
                : filters.status.value && !filterStatus.value
                ? { value: filters.status, label: filters.status.label }
                : filterStatus.value
                ? { value: filterStatus.value, label: filterStatus.label }
                : { value: "", label: "Semua Status" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleClose}
                >
                  Batal
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={() => submitForm()}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ listReport }) => {
  return {
    filters: getFilters(listReport),
    pageData: getPageData(listReport),
  };
};

const mapDispatchToProps = { listReport, filterReport };

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterReport);
