import { width } from "@mui/system";
import { useEffect, useState } from "react";

const Index = (props) => {
  // load props data
  const { color, num, numBar, total, name } = props;

  const [width, setWidth] = useState("0%");

  useEffect(() => {
    if (numBar != 0 && total != 0) {
      setWidth(`${((numBar / total) * 100).toFixed(2)}%`);
    }else{
      setWidth("0%");
    }
  }, [color, num, total, numBar, width]);

  return (
    <div className="stat-item ps-5 pe-5 pb-3">
      <div className="d-flex justify-content-between align-items-center">
        <span className="status-kandang-title">{name ? name : "?"}</span>
        <span className="status-kandang-value">{num ? num : 0}</span>
      </div>
      <div className="progress-bar-wrapper">
        <div></div>
        <div style={{ width: width, backgroundColor: color }}></div>
      </div>
    </div>
  );
};

export default Index;
