import { connect } from "react-redux";
import { Event } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import DateRangePicker from "../../components/commons/forms/datepicker/DateRangePicker";
import { Chart } from "react-chartjs-2";
import { numberWithCommas } from "../../utils/constants";
import { customDecimal } from "../../utils/report/reportCalculation";

// load actions
import { chartTingkatKematian, updateTKFilter } from "../../store/actions/dashboard";

// load reducers
import { getFilter, getChart, getChartPending, getChartError, getChartFilter } from "../../store/reducers/dashboard";
import { isEmpty, range } from "lodash";

const Index = (props) => {
  // setup useRef
  const TKChartRef = useRef();
  // setup chart color
  let TKChartColor;

  // load props data
  const { filterKandang, TKChartSuccess, TKChartPending, TKChartError, TKChartFilter } = props;

  const [TKLabel, setTKLabel] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ]);
  const baseChartData = {
    labels: TKLabel,
    datasets: [
      {
        data: range(0, 30, 0),
        fill: true,
        tension: 0.1,
        borderJoinStyle: "bevel",
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(0,0,0,0)",
        borderColor: "#ed3636",
        backgroundColor: TKChartColor,
        lineTension: 0.3,
      },
    ],
  };
  const [TKData, setTKData] = useState(baseChartData);
  // setup chart options
  const tingkatKemationOpt = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 15,
        },
      },
      y: {
        title: {
          display: true,
          text: "Jumlah (ekor)",
          color: "#919392",
          font: {
            family: "PlusJakartaSans-Regular",
            size: 14,
            weight: "500",
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        suggestedMin: 0,
        ticks: {
          padding: 15,
          callback: (val, i, ticks) => {
            return numFormat(val);
          },
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
    layout: {
      padding: 15,
    },
  };
  // use effect hook for rendering tingkat kematian chart data
  useEffect(() => {
    const TKChart = TKChartRef.current;
    if (TKChart) {
      TKChartColor = TKChart.ctx.createLinearGradient(0, TKChart.chartArea.bottom, 0, TKChart.chartArea.top);
      TKChartColor.addColorStop(1, "#ff4263");
      TKChartColor.addColorStop(0, "#ffffff");
      setTKData({
        ...TKData,
        datasets: [
          {
            ...TKData.datasets[0],
            backgroundColor: TKChartColor,
            data: TKChartSuccess?.values ? TKChartSuccess.values : TKData.datasets[0].data,
          },
        ],
      });
    }
  }, [TKChartRef.current, TKChartSuccess]);

  // use effect hook for update chart tingkat kematian
  useEffect(() => {
    if (!isEmpty(filterKandang)) props.chartTingkatKematian({ farm: filterKandang.value, year: TKChartFilter });
  }, [TKChartFilter]);

  // custom function
  const numFormat = (num) => {
    return numberWithCommas(customDecimal(num));
  };

  const calculatePercent = (num1, num2) => {
    // check if the param receive string and not numeric
    if (isNaN(parseFloat(num1)) || isNaN(parseFloat(num2))) return 0;
    // check if one of param receive 0 , cuz cant be divided.
    if (parseFloat(num1) === 0 || parseFloat(num2) === 0) return 0;
    // return by formula to get percent
    return ((num1 - num2) * 100) / num2;
  };
  const cvDateToString = (d) => new Date(d).toLocaleDateString("fr-FR").toString();

  const handleChangeTK = (e) => {
    const year = cvDateToString(e).split("/")[2];
    props.updateTKFilter(year);
  };

  return (
    <div className="c-card">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div className="c-card-title">Tingkat Kematian</div>
        <DateRangePicker
          onChange={(e) => handleChangeTK(e)}
          selectsRange={false}
          placeholder={""}
          value={TKChartFilter}
          leftIcon={<Event style={{ fontSize: "25px", color: "#A5A5C0", marginTop: "5px" }} />}
          format={"yyyy"}
          wrapperClass="date-picker-wrapper"
          groupClass="date-picker-group"
          showYearPicker={true}
        />
      </div>
      <Chart ref={TKChartRef} type="line" labels={TKLabel} data={TKData} options={tingkatKemationOpt} id="canvas" />
      <div className="d-flex justify-content-between align-items-center chart-stat-wrapper">
        <div className="chart-stat-title">Tingkat Kematian</div>
        <div className="chart-stat-value">{`${
          TKChartSuccess ? numFormat(TKChartSuccess.values.reduce((t, c) => t + c)) : 0
        } ekor`}</div>
        <div className="chart-stat-persen">{`${
          TKChartSuccess
            ? numFormat(
                calculatePercent(
                  TKChartSuccess.values.reduce((t, c) => t + c),
                  TKChartSuccess.lastYearValues.reduce((t, c) => t + c)
                )
              )
            : 0
        }%`}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ listFarmDB, tingkatKematian }) => {
  return {
    filterKandang: getFilter(listFarmDB),
    TKChartSuccess: getChart(tingkatKematian),
    TKChartPending: getChartPending(tingkatKematian),
    TKChartError: getChartError(tingkatKematian),
    TKChartFilter: getChartFilter(tingkatKematian),
  };
};

export default connect(mapStateToProps, {
  chartTingkatKematian,
  updateTKFilter,
})(Index);
