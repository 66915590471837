import React from "react";
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { listDoc, searchDoc } from "../../store/actions/doc";

import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/doc";

const SearchBoxDoc = (props) => {
  const { pageData, filters, searchText } = props;

  const [searchTextShow, setSearchTextShow] = useState(false);

  const handleSearchChange = (e) => {
    props.searchDoc(e.target.value);
    props.listDoc({
      length: pageData.lengthItem,
      page: 1,
      search_text: e.target.value,
      grade_doc: filters.grade_doc.value,
    });
  };

  return (
    <>
      <div
        className={`searchbox-wrapper ${
          searchTextShow ? "searchbox-show" : ""
        }`}>
        <input
          type="text"
          name="search-text"
          placeholder="Masukkan kata kunci"
          onChange={handleSearchChange}
          defaultValue={searchText}
        />
        <IconButton onClick={(e) => setSearchTextShow(!searchTextShow)}>
          <Search />
        </IconButton>
      </div>
    </>
  );
};

const mapStateToProps = ({ listDoc }) => {
  return {
    pageData: getPageData(listDoc),
    filters: getFilters(listDoc),
    searchText: getSearchText(listDoc),
  };
};

export default connect(mapStateToProps, { listDoc, searchDoc })(SearchBoxDoc);
