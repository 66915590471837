import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from '@mui/material';
import SelectComponent from "../../components/commons/forms/select";
import Input from "../../components/commons/forms/authentication/input";
import { connect } from "react-redux";
import { editDoc, listDoc } from "../../store/actions/doc";
import {
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/doc";

function ModalEditDoc(props) {
  const [id, setId] = useState("");
  const [kode_doc, setKode] = useState("");
  const [name_doc, setName] = useState("");
  const [grade_doc, setGrade] = useState("");

  const [errorKode, setErrorKode] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorGrade, setErrorGrade] = useState("");

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const { pageData, filters, searchText } = props;

  const options = [
    { value: "Grade A", label: "Grade A" },
    { value: "Grade B", label: "Grade B" },
    { value: "Grade C", label: "Grade C" },
  ];

  useEffect(() => {
    if (props.data) {
      setId(props.data.id);
      setKode(props.data.kode_doc);
      setName(props.data.name_doc);
      setGrade(props.data.grade_doc);
    }
  }, [props.data]);

  const setDefaultState = () => {
    if (props.data) {
      setId(props.data.id);
      setKode(props.data.kode_doc);
      setName(props.data.name_doc);
      setGrade(props.data.grade_doc);
    }
    setErrorKode("");
    setErrorName("");
    setErrorGrade("");
  };

  const handleClose = () => {
    setDefaultState();
    props.setShow(false);
  };

  const handleInput = (e, set, error, msg) => {
    let value;
    let label = true;
    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
      label = false;
    }
    if (!value) {
      error(`${msg} wajib diisi`);
    } else {
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    data.kode_doc ? setErrorKode("") : setErrorKode("Kode DOC wajib diisi");
    data.name_doc ? setErrorName("") : setErrorName("Nama DOC wajib diisi");
    data.grade_doc ? setErrorGrade("") : setErrorGrade("Grade DOC wajib diisi");
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    const data = { id, kode_doc, name_doc, grade_doc };
    handleError(data);
    for (var key in data) {
      if (data[key] === "") {
        return;
      }
    }
    props.editDoc(data, successEdit, failedEdit);
  };

  const failedEdit = () => {
    setDisableBtnSubmit(false);
  };

  const successEdit = () => {
    setTimeout(() => {
      props.setShow(false);
      props.listDoc({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        grade_doc: filters.grade_doc.value,
      });
      setDisableBtnSubmit(false);
    }, 1000);
  };

  return (
    <Modal size={"md"} onHide={handleClose} show={props.show} centered>
      <ModalHeader closeButton>Edit DOC</ModalHeader>
      <ModalBody>
        <Input
          label={"Kode DOC"}
          placeholder={"Masukkan Kode DOC"}
          value={kode_doc || ""}
          error={errorKode}
          isError={errorKode !== ``}
          onChange={(e) => handleInput(e, setKode, setErrorKode, "Kode DOC")}
        />
        <Input
          label={"Nama DOC"}
          placeholder={"Masukkan Nama DOC"}
          value={name_doc || ""}
          error={errorName}
          isError={errorName !== ``}
          onChange={(e) => handleInput(e, setName, setErrorName, "Nama DOC")}
        />
        <SelectComponent
          label={"Grade DOC"}
          error={errorGrade}
          isError={errorGrade !== ``}
          options={options}
          onChange={(e) => handleInput(e, setGrade, setErrorGrade, "Grade DOC")}
          value={{ value: grade_doc, label: grade_doc }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outline"
          className="btn-btl-ubah-pass"
          sx={{ paddingY: "10px" }}
          onClick={handleClose}>
            Batal
        </Button>
        <Button
          variant="contained"
          className="btn-simpan-ubah-pass"
          sx={{ paddingY: "10px" }}
          onClick={submitForm}
          disabled={disableBtnSubmit}>
            Simpan
        </Button>
      </ModalFooter>
    </Modal>
  );
}
function mapStateToProps({ listDoc }) {
  return {
    pageData: getPageData(listDoc),
    filters: getFilters(listDoc),
    searchText: getSearchText(listDoc),
  };
}

export default connect(mapStateToProps, { editDoc, listDoc })(ModalEditDoc);
