import React from "react";
import { Modal, ModalBody, ModalFooter} from "react-bootstrap";
import { Button } from '@mui/material';
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Warning from "../../assets/img/Warning.png";

const ModalDelete = (props) => {
  return (
    <Modal size={"md"} onHide={() => props.setShow(false)} show={props.show} centered>
      <ModalHeader closeButton>Konfirmasi</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row justify-content-around">
            <div className="col-md-1 icon-warning">
              <img src={Warning} alt="icon warning" />
            </div>
            <div className="col-md-11 text-icon-warning">
              <span className="text-bold">Apakah anda yakin ingin menghapus data ini?</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outlined"
          className="btn-btl-delete"
          sx={{ paddingY: "10px" }}
          onClick={() => props.setShow(false)}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          className="btn-delete"
          sx={{ paddingY: "10px" }}
          onClick={() => props.handleDelete()}
          disabled={props.disableSubmit}
        >
          Ya,Hapus
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
