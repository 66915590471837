import { actionSuccess, actionError, actionPending } from "../action_types";
import API from "../API";
import { toastSuccess, toastError } from "../../../components/toast";
import { history } from "../../../utils"

export const UPDATE_FEED_PAGE     = `UPDATE_FEED_PAGE`;
export const LIST_FEED_PENDING    = `LIST_FEED_PENDING`;
export const LIST_FEED_SUCCESS    = `LIST_FEED_SUCCESS`;
export const LIST_FEED_ERROR      = `LIST_FEED_ERROR`;
export const UPDATE_FILTER_FEED   = `UPDATE_FILTER_FEED`;
export const UPDATE_SEARCH_FEED   = `UPDATE_SEARCH_FEED`;
export const TAMBAH_FEED_PENDING  = `TAMBAH_FEED_PENDING`;
export const TAMBAH_FEED_SUCCESS  = `TAMBAH_FEED_SUCCESS`;
export const TAMBAH_FEED_ERROR    = `TAMBAH_FEED_ERROR`;
export const DELETE_FEED_PENDING  = `DELETE_FEED_PENDING`;
export const DELETE_FEED_SUCCESS  = `DELETE_FEED_SUCCESS`;
export const DELETE_FEED_ERROR    = `DELETE_FEED_ERROR`;
export const EDIT_FEED_PENDING    = `EDIT_FEED_PENDING`;
export const EDIT_FEED_SUCCESS    = `EDIT_FEED_SUCCESS`;
export const EDIT_FEED_ERROR      = `EDIT_FEED_ERROR`;

const LIST_FEED_URL = `/v1/master_data/feed`;
const TAMBAH_FEED_URL = `/v1/master_data/feed`;
const DELETE_FEED_URL = `/v1/master_data/feed`;
const EDIT_FEED_URL = `/v1/master_data/feed`;


export const listFeed =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_FEED_PENDING));
    API.get(LIST_FEED_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_FEED_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_FEED_PAGE, param.page));
        }
        return res.data;
      })
      .catch((error) => {
        dispatch(actionError(LIST_FEED_ERROR, error));
        toastError(error, "");
    });
};

export const filterFeed =
  (param = {}) =>
  (dispatch) => {
    const grade_feed = param.grade_feed ? param.grade_feed : {};
    dispatch(actionSuccess(UPDATE_FILTER_FEED, { grade_feed }));
};

export const searchFeed =
  (param = "") =>
  (dispatch) => {
    dispatch(actionSuccess(UPDATE_SEARCH_FEED, param));
};

export const tambahFeed =
(param = {}, callbackSuccess, callbackFailed) =>
(dispatch) => {
  dispatch(actionPending(TAMBAH_FEED_PENDING));
  API.post(TAMBAH_FEED_URL, param)
    .then((res) => {
      dispatch(actionSuccess(TAMBAH_FEED_SUCCESS, res.data.data));
      toastSuccess(res.data.message);
      callbackSuccess();
    })
    .catch((error) => {
      dispatch(actionError(TAMBAH_FEED_ERROR, error));
      toastError(error, "");
      callbackFailed();
    });
};

export const editFeed =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(EDIT_FEED_PENDING));
    API.put(EDIT_FEED_URL, param)
      .then((res) => {
        dispatch(actionSuccess(EDIT_FEED_SUCCESS));
        toastSuccess(res.data.message);
        callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_FEED_ERROR, error));
        toastError(error, "");
        callbackFailed();
      });
};

export function deleteFeed(id = null) {
  const param = {
    id: id,
  };
  return (dispatch) => {
    dispatch(actionPending(DELETE_FEED_PENDING));
    API.delete(DELETE_FEED_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(DELETE_FEED_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DELETE_FEED_ERROR, error));
        toastError(error, "");
      });
  };
}
