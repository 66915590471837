import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ModalDelete from "../../../../components/report/ModalDelete";

export const ModalDeleteDoc = (props) => {
  const handleDelete = (index) => {
    props.setDoc(props.doc.filter((doc, _index) => props.data.index !== _index));
    props.setError(props.error.filter((error, _index) => props.data.index !== _index));
    setTimeout(() => {
      props.setShow(false);
    }, 50)
  };
  return (
    <ModalDelete show={props.show} setShow={props.setShow} handleDelete={handleDelete} disableSubmit={props?.doc ? false : true} />
  );
};

const mapStateToProps = (state) => ({});


export default connect(mapStateToProps)(ModalDeleteDoc);
