import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import imagePlaceholder from "../../../../assets/img/imagePlaceholder.png";

export const ModalFoto = (props) => {
  const handleDownloadButton = () => {
    const a = document.createElement("a");
    a.href = props.foto;
    a.download = props.id_penjualan + "." + props.foto.substring("data:image/".length, props.foto.indexOf(";base64"));
    a.click();
    a.remove();
  };

  return (
    <Modal
      size={"md"}
      onHide={() => {
        props.setShow(false);
      }}
      show={props.show}
      centered
      style={{ overflowX: "hidden" }}
    >
      <ModalHeader closeButton>Foto</ModalHeader>
      <ModalBody>
        <img src={props.foto ? props.foto : imagePlaceholder} style={{ width: "119%", marginLeft: "-40px" }} />
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <Button variant="outline" className="btn-back-report" onClick={() => props.setShow(false)} disableElevation>
            Tutup
          </Button>
          <Button variant="contained" className="btn-edit-report" disableElevation onClick={handleDownloadButton} disabled={props.foto ? false : true}>
            Unduh
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFoto);
