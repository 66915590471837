import React from "react";
import { Col, Row } from "react-bootstrap";

const AccordionList = ({ label, value, color = "rgb(33, 37, 41)", style }) => {
  return (
    <div className="accordion-list-custom" style={style}>
      <Row>
        <Col md={4}>
          <span className="text-bold" style={{ fontSize: "14px" }}>
            {label}
          </span>
        </Col>
        <Col md={8} style={{ color: color }}>
          {value}
        </Col>
      </Row>
    </div>
  );
};

export default AccordionList;
