import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import PageDetailFarm from "./PageDetailFarm";
import DaftarReport from "./report";
import HeaderDaftarReport from "./report/HeaderDaftarReport";

function SidebarDetailFarm(props) {
  const history = useHistory();
  const [showDetail, setShowDetail] = useState(history.location.pathname.includes("report") ? false : true);

  const showHeader = (show) => {
    if (!show) return <HeaderDaftarReport />;
  };

  const switchComponent = (show) => {
    switch (show) {
      case false:
        return <DaftarReport />;
      case true:
        return <PageDetailFarm />;
      default:
        return <div>Loading...</div>;
    }
  };

  const buttonSwitch = (title = "", className = "", onClick = () => {}) => {
    return (
      <Button
        variant="contained"
        disableElevation
        className={className}
        sx={{
          paddingY: "10px",
        }}
        onClick={onClick}
      >
        {title}
      </Button>
    );
  };

  const onReportClick = () => {
    history.replace({ pathname: "/farmmanagement/" + props.match.params.id + "/report" });
    setShowDetail(false);
  };

  const onKandangClick = () => {
    history.replace({ pathname: "/farmmanagement/" + props.match.params.id });
    setShowDetail(true);
  };

  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="d-flex align-items-center">
        <ArrowBack sx={{ cursor: "pointer" }} onClick={() => history.push({ pathname: "/farmmanagement" })} />
        <span
          className="text-extra-bold"
          style={{
            fontSize: "24px",
            fontFamily: "PlusJakartaSans-Bold",
            marginLeft: "26px",
          }}
        >
          Farm Management
        </span>
      </div>
      <Row style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Col>
          {buttonSwitch("Informasi Kandang", showDetail ? "detail-farm-new-btn-active" : "detail-farm-new-btn-non-active", onKandangClick)}
          {buttonSwitch("Report", !showDetail ? "btn-report-new-active" : "btn-report-new-non-active", onReportClick)}
        </Col>
        <Col>{showHeader(showDetail)}</Col>
      </Row>
      <div>{switchComponent(showDetail)}</div>
    </Container>
  );
}

export default withRouter(SidebarDetailFarm);
