import React from "react";
import { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../components/commons/forms/select";

import { connect } from "react-redux";

import { listFarm, listKepKan, filterFarm } from "../../store/actions/farm";
import { getListKepKan, getListFarm, getPageData, getFilters, getSearchText } from "../../store/reducers/farm";

const ModalFilterFarm = (props) => {
  const [filterKepKan, setFilterKepKan] = useState({});
  const [filterStatus, setFilterStatus] = useState({});

  const { listFarmSuccess, listKepKanSuccess, pageData, filters, searchText } = props;

  const statusOptions = [
    { value: "kosong", label: "Kosong" },
    { value: "cuci kandang", label: "Cuci Kandang" },
    { value: "persiapan kandang", label: "Persiapan Kandang" },
    { value: "chick in", label: "Chick In" },
    { value: "panen", label: "Panen" },
  ];

  useEffect(() => {
    props.listKepKan();
  }, []);

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterFarm({ kepala_kandang: filterKepKan, status_farm: filterStatus });
    }, 1000);
  };

  const resetButton = () => {
    props.setShow(false);
    props.filterFarm({ kepala_kandang: null, status_farm: null });
    setFilterKepKan("");
    setFilterStatus("");
  };

  const handleBatal = () => {
    setTimeout(() => {
      props.setShow(false);
      setFilterKepKan("");
      setFilterStatus("");
    }, 1000);
  };

  return (
    <Modal size={"md"} onHide={handleBatal} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={statusOptions}
            label={"Status Kandang"}
            isAsync={true}
            asyncUrl={`/v1/master_data/farm`}
            // onChange={(e) => props.filterFarm({ ...filters, status_farm: e })}
            onChange={(e) => {
              setFilterStatus(e);
            }}
            value={
              filterStatus === null
                ? { value: "", label: "Semua Status Kandang" }
                : filters.status_farm.value && !filterStatus.value
                ? { value: filters.status_farm.value, label: filters.status_farm.label }
                : filterStatus.value
                ? { value: filterStatus.value, label: filterStatus.label }
                : { value: "", label: "Semua Status Kandang" }
            }
          />
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={listKepKanSuccess ? listKepKanSuccess : {}}
            label={"Kepala Kandang"}
            isAsync={true}
            asyncUrl={`/v1/master_data/user?role_code=kepalakandang&status=Aktif`}
            // onChange={(e) => props.filterFarm({ ...filters, kepala_kandang: e })}
            onChange={(e) => {
              setFilterKepKan(e);
            }}
            value={
              filterKepKan === null
                ? { value: "", label: "Semua Kepala Kandang" }
                : filters.kepala_kandang.value && !filterKepKan.value
                ? { value: filters.kepala_kandang.value, label: filters.kepala_kandang.label }
                : filterKepKan.value
                ? { value: filterKepKan.value, label: filterKepKan.label }
                : { value: "", label: "Semua Kepala Kandang" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={resetButton}
              >
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleBatal}
                >
                  Batal
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={(e) => submitForm()}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ listFarm, listKepKan }) => {
  return {
    listFarmSuccess: getListFarm(listFarm),
    listKepKanSuccess: getListKepKan(listKepKan),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, { listKepKan, listFarm, filterFarm })(ModalFilterFarm);
