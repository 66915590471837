import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Modal } from "react-bootstrap";
import { InfoRounded } from "@mui/icons-material";
import DateRangePicker from "../../../components/commons/forms/datepicker/DateRangePicker";
import { formatDate } from "../../../utils";
import { downloadReport } from "../../../store/actions/farm/report";
import { getDownloadReportPending, getDownloadReportError } from "../../../store/reducers/farm/report";
import CircularProgress from "@mui/material/CircularProgress";

export const ModalUnduhReport = (props) => {
  const { downloadReportPending, downloadReportError } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();
  const [excludeDates, setExcludeDates] = useState();

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setErrorMessage("");
  };

  const handleUnduhButton = () => {
    if (startDate === null) {
      setErrorMessage("Tanggal harus diisi");
      return;
    }
    if (endDate === null) {
      setErrorMessage("Tanggal akhir harus diisi");
      return;
    }
    props.downloadReport(props.id_farm, formatDate(startDate), formatDate(endDate));
  };

  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    setErrorMessage("");
    setDisabled(false);
    props.setShow(false);
  };

  useEffect(() => {
    if (downloadReportPending) setDisabled(true);
    if (!downloadReportPending) {
      handleClose();
    }
  }, [downloadReportPending]);

  useEffect(() => {
    if (props.minDate) {
      setMinDate(new Date(props.minDate));
      setExcludeDates(undefined);
    } else {
      setMinDate(new Date());
      setExcludeDates([new Date()]);
    }
  }, [props.minDate]);

  useEffect(() => {
    if (props.maxDate) {
      let tempDate = new Date(props.maxDate);
      if (props.checkTodayReport) {
        tempDate = new Date(tempDate.setDate(tempDate.getDate() - 1));
        setMaxDate(tempDate);
      } else setMaxDate(tempDate);
    } else {
      setMaxDate(new Date());
    }
  }, [props.maxDate]);

  return (
    <Modal size={"md"} show={props.show} onHide={handleClose} centered>
      <Modal.Header closeButton>Unduh Report</Modal.Header>
      <Modal.Body>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin diunduh</div>
        </div>
        <div className="select-wrapper">
          <DateRangePicker
            label={"Tanggal"}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            placeholder={"Masukkan Tanggal"}
            error={errorMessage}
            isClearable={false}
            minDate={minDate}
            maxDate={maxDate}
            excludeDates={excludeDates}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          disableElevation
          className="btn-btl-ubah-pass"
          style={{
            right: "2px",
          }}
          onClick={handleClose}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          disableElevation
          className="btn-simpan-ubah-pass"
          style={{
            right: "2px",
          }}
          onClick={handleUnduhButton}
          disabled={disabled}
        >
          {disabled ? <CircularProgress size={18} color="inherit" disableShrink /> : "Unduh"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ downloadReport }) => {
  return {
    downloadReportPending: getDownloadReportPending(downloadReport),
    downloadReportError: getDownloadReportError(downloadReport),
  };
};

const mapDispatchToProps = { downloadReport };

export default connect(mapStateToProps, mapDispatchToProps)(ModalUnduhReport);
