import React from "react";
import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  MenuItem,
  Menu,
  Fade,
  Divider,
  Button,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";

import { Container, Navbar } from "react-bootstrap";
import { connect } from "react-redux";

import ModalEditUser from "./ModalEditUser";
import ModalTambahUser from "./ModalTambahUser";
import ModalDetailUser from "./ModalDetailUser";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalFilter from "./ModalFilters";
import ModalResetPassword from "./ModalResetPassword";
import SearchBox from "./SearchBox";
import Page from "./Page";

import { listUser } from "../../store/actions/user";
import {
  getListUser,
  getListUserPending,
  getListUserError,
  getPageData,
  getFilters,
  getSearchText,
} from "../../store/reducers/user";
import { getUser } from "../../utils";

const Index = (props) => {
  const profile = getUser();
  const {
    pageData,
    listUserSuccess,
    listUserPending,
    listUserError,
    filters,
    searchText,
  } = props;

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalTambah, setShowModalTambah] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [editData, setEditData] = useState(null);
  const [anchorEl, setAnchorEl] = useState([null,null,null,null,null,null,null,null,null,null]);
  const [popOpen , setPopOpen] = useState([false,false,false,false,false,false,false,false,false,false]);
  const [activePopMenu , setActivePopMenu] = useState(null);

  const handleEditButtonClick = () => {
    handleMoreButtonClose();
    setShowModalEdit(true);
  };
  const handleDetailButtonClick = () => {
    handleMoreButtonClose();
    setShowModalDetail(true);
  };
  const handleDeleteButtonClick = () => {
    handleMoreButtonClose();
    setShowModalDelete(true);
  };
  const handleResetPasswordButtonClick = () => {
    handleMoreButtonClose();
    setShowModalResetPassword(true);
  };
  const handleMoreButtonClick = (data, event , index) => {
    setEditData(data);
    let anchor = anchorEl;
    anchor[index] = event.currentTarget; 
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[index] = true;
    setPopOpen(temp);
    setActivePopMenu(index);
  };
  const handleMoreButtonClose = () => {
    let anchor = anchorEl;
    anchor[activePopMenu] = null;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[activePopMenu] = false;
    setPopOpen(temp);
    setActivePopMenu(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    props.listUser({
      length: pageData.lengthItem,
      page: pageData.page,
      search_text: searchText,
      role_name: filters.role.value,
      status: filters.status.value,
    });
  }, [popOpen, anchorEl, filters]);

  const userLists = (data, error) => {
    return error ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Gagal mengambil data user
        </TableCell>
      </TableRow>
    ) : data.length === 0 ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Data User tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>{row.role_name}</TableCell>
          {row.name.length > 20 ? (
            <TableCell>{row.name.slice(0, 20) + "..."}</TableCell>
          ) : (
            <TableCell>{row.name}</TableCell>
          )}
          <TableCell>
            <div
              className={
                row.status === "Aktif"
                  ? "btn btn-status btn-sm bg-success rounded"
                  : "btn btn-status btn-sm bg-warning rounded"
              }>
              {row.status}
            </div>
          </TableCell>
          <TableCell align="center">
            <IconButton
              onClick={(e) => handleMoreButtonClick(row, e , i)}
              variant="contained"
              aria-controls={popOpen[i] ? id : undefined}
              aria-haspopup="true"
              aria-expanded={popOpen[i] ? "true" : undefined}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              elevation={1}
              id={popOpen[i] ? "simple-popover" : undefined}
              open={popOpen[i]}
              anchorEl={anchorEl[i]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleMoreButtonClose}
              TransitionComponent={Fade}>
              <MenuItem onClick={handleDetailButtonClick} sx={styleMenuItem}>
                Detail
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem onClick={handleEditButtonClick} sx={styleMenuItem}>
                Edit
              </MenuItem>
              <Divider variant="middle" />
              {row.role === 'management' && row.id != profile.id ?
                <div>
                  <MenuItem
                    onClick={handleResetPasswordButtonClick}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}>
                    Reset Password
                  </MenuItem>
                  <Divider variant="middle" />
                </div>
                : ""
            }
              <MenuItem
                test={row.id}
                onClick={(e) => handleDeleteButtonClick()}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}>
                Hapus
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      {editData ? (
        <>
          <ModalEditUser
            id={`edit-user-modal`}
            show={showModalEdit}
            setShow={setShowModalEdit}
            data={editData}
          />
          <ModalDetailUser
            show={showModalDetail}
            setShow={setShowModalDetail}
            data={editData}
            edit={setShowModalEdit}
          />
          <ModalDeleteUser
            show={showModalDelete}
            setShow={setShowModalDelete}
            data={editData}
          />
          <ModalResetPassword
            show={showModalResetPassword}
            setShow={setShowModalResetPassword}
            data={editData}
          />
        </>
      ) : (
        ""
      )}
      <ModalTambahUser show={showModalTambah} setShow={setShowModalTambah} />
      <ModalFilter show={showModalFilter} setShow={setShowModalFilter} />
      <Navbar>
        <Navbar.Brand>
          <h1 className="judul-header">User Management</h1>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <SearchBox />
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            sx={{
              borderRadius: 28,
              paddingY: "10px",
              textTransform: "capitalize",
              color: "black",
              fontWeight: "800",
            }}
            size="large"
            className={`filters`}
            onClick={(e) => setShowModalFilter(!showModalFilter)}>
            {filters.role.value || filters.status.value ? (
              <span className="dot"></span>
            ) : (
              ``
            )}
            Filter
          </Button>
          <div className="custom-devider" />
          <Button
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "800",
                borderRadius: "24px",
                width: "163px",
                height: "48px",
                "&:hover": {
                  background: "#5269D9",
                  boxShadow: "0px 4px 14px rgba(28, 55, 190, 0.75)",
                  borderRadius: "24px",
                },
              }}
              onClick={() => setShowModalTambah(true)}>
              Tambah User
            </Button>
        </Navbar.Collapse>
      </Navbar>
      <Divider />
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>ROLE</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              STATUS
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listUserPending ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Mengambil data user ...
              </TableCell>
            </TableRow>
          ) : (
            userLists(listUserSuccess, listUserError)
          )}
        </TableBody>
      </Table>
      <Page pageData={pageData} />
      <div style={{marginBottom:"210px"}}></div>
    </Container>
  );
};

const mapStateToProps = ({ listUser }) => {
  return {
    listUserSuccess: getListUser(listUser),
    listUserPending: getListUserPending(listUser),
    listUserError: getListUserError(listUser),
    pageData: getPageData(listUser),
    filters: getFilters(listUser),
    searchText: getSearchText(listUser),
  };
};

export default connect(mapStateToProps, { listUser })(Index);
