export const handleInput = (e, set, error, customRule = false, callback, optional = false) => {
  let value;
  let label = true;

  if (e) {
    value = e.target ? e.target.value : e.label;
  } else {
    value = ``;
    label = false;
  }

  const rule = customRule ? customRule : e.target.getAttribute(`rule`);
  if (error) {
    if (!value && optional === false) {
      error(`${rule} wajib diisi`);
    } else {
      error(``);
    }
  }
  set(value);
  if (callback) callback(e);
};

export const handlePenjualanAdd = (penjualan, setPenjualan, errorPenjualan, setErrorPenjualan, foto, setFoto, fotoPreview, setFotoPreview) => {
  if (penjualan.length === 0) {
    setPenjualan([{ jumlah: "", berat: "", nama: "", tonase: "", foto: "" }]);
    setErrorPenjualan([{ jumlah: "", berat: "", nama: "", tonase: "", foto: "" }]);
  } else {
    setPenjualan(penjualan.concat([{ jumlah: "", berat: "", nama: "", tonase: "", foto: "" }]));
    setErrorPenjualan(errorPenjualan.concat([{ jumlah: "", berat: "", nama: "", tonase: "", foto: "" }]));
  }
};

function readFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
}
export const handlePenjualanChange = (event, index, attribute, penjualan, setPenjualan, errorPenjualan, setErrorPenjualan) => {
  let value = event.target.files ? event.target.files[0] : event.target.value;
  const setValue = () => {
    const newPenjualan = penjualan.map((penjualan, sindex) => {
      if (index !== sindex) return penjualan;
      return { ...penjualan, [attribute]: value };
    });
    setErrorPenjualan(
      errorPenjualan.map((jual, i) => {
        if (i !== index) return jual;
        return { ...jual, [attribute]: "" };
      })
    );
    setPenjualan(newPenjualan);
  };

  if (attribute === "foto") {
    readFile(value).then((data) => {
      value = data;
      setValue();
    });
  } else {
    setValue();
  }
};

export const handlePenjualanDelete = (index, id_penjualan, setDataDeletePenjualan, setShowModalDeletePenjualan) => {
  setDataDeletePenjualan({ id: id_penjualan || "", index });
  setShowModalDeletePenjualan(true);
};

export const handleObatAdd = (obat, setObat, errorObat, setErrorObat) => {
  if (obat.length === 0) {
    setObat([{ merk: "", jumlah: "" }]);
    setErrorObat([{ merk: "", jumlah: "" }]);
  } else {
    setObat(obat.concat([{ merk: "", jumlah: "" }]));
    setErrorObat(errorObat.concat([{ merk: "", jumlah: "" }]));
  }
};

export const handleObatChange = (event, index, attribute, obat, setObat, errorObat, setErrorObat) => {
  const newObat = obat.map((obt, sindex) => {
    if (index !== sindex) return obt;
    return { ...obt, [attribute]: event.target.value };
  });
  setErrorObat(
    errorObat.map((obt, i) => {
      if (i !== index) return obt;
      return { ...obt, [attribute]: "" };
    })
  );
  setObat(newObat);
};

export const handleObatDelete = (index, id_obat, setDataDeleteObat, setShowModalDeleteObat) => {
  setDataDeleteObat({ id: id_obat || "", index });
  setShowModalDeleteObat(true);
};

export const handleDOCAdd = (doc, setDoc, errorDoc, setErrorDoc) => {
  if (doc.length === 0) {
    setDoc([{ tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" }]);
    setErrorDoc([{ tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" }]);
  } else {
    setDoc(doc.concat([{ tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" }]));
    setErrorDoc(errorDoc.concat([{ tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" }]));
  }
};

export const handleDocChange = (value, index, attribute, doc, setDoc, errorDoc, setErrorDoc) => {
  const newDoc = doc.map((item, i) => {
    if (index !== i) return item;
    return { ...item, [attribute]: value };
  });
  setErrorDoc(
    errorDoc.map((item, i) => {
      if (i !== index) return item;
      return { ...item, [attribute]: "" };
    })
  );
  setDoc(newDoc);
};

export const handleDOCDelete = (index, id_doc, setDataDeleteDoc, setShowModalDeleteDoc) => {
  setDataDeleteDoc({ id: id_doc || "", index });
  setShowModalDeleteDoc(true);
};
