import React, { useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import SelectComponent from "../../components/commons/forms/select";
import { useState } from "react";
import { connect } from "react-redux";
import { listFeed, filterFeed } from "../../store/actions/feed";

import { getListFeed, getFilters, getPageData, getSearchText } from "../../store/reducers/feed";

const ModalFilterFeed = (props) => {
  const [filter, setFilter] = useState({});

  const { listFeedSuccess, filters, pageData, searchText } = props;

  const gradeOptions = [
    { value: "Pre starter", label: "Pre Starter" },
    { value: "Starter", label: "Starter" },
    { value: "Grower", label: "Grower" },
    { value: "Finisher", label: "Finisher" },
  ];

  const submitForm = () => {
    setTimeout(() => {
      props.setShow(false);
      props.filterFeed({ grade_feed: filter});
    }, 200)
  };

  const resetButton = () => {
    props.setShow(false);
    props.filterFeed({ grade_feed: null });
    setFilter("");
  };

  const handleClose = () => {
    setTimeout(() => {
      props.setShow(false);
      setFilter("");
    }, 200);
  };

  return (
    <Modal size={"md"} onHide={handleClose} show={props.show} centered>
      <ModalHeader closeButton>Filter</ModalHeader>
      <ModalBody>
        <div className="info">
          <InfoRounded color="inherit" fontSize="medium" />
          <div className="info-text">Pilih data yang ingin difilter</div>
        </div>

        <div className="select-wrapper">
          <SelectComponent
            options={gradeOptions}
            label={"Grade Feed"}
            isAsync={true}
            asyncUrl={`/v1/master_data/feed}`}
            onChange={(e) => {
              setFilter(e);
            }}
            value={
              filter === null
                ? { value: "", label: "Semua Grade" }
                : filters.grade_feed.value && !filter.value
                ? {
                    value: filters.grade_feed.value,
                    label: filters.grade_feed.label,
                  }
                : filter.value
                ? { value: filter.value, label: filter.label }
                : { value: "", label: "Semua Grade" }
            }
          />
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: `space-between` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-2">
              <Button
                variant="outlined"
                disableElevation
                className="btn-btl-ubah-pass"
                style={{
                  right: "2px",
                }}
                onClick={resetButton}
              >
                Reset
              </Button>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <Button
                  variant="outlined"
                  disableElevation
                  className="btn-btl-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={handleClose}
                >
                  Batal
                </Button>

                <Button
                  variant="contained"
                  disableElevation
                  className="btn-simpan-ubah-pass"
                  style={{
                    right: "2px",
                  }}
                  onClick={() => submitForm()}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps({ listFeed }) {
  return {
    listFeedSuccess: getListFeed(listFeed),
    filters: getFilters(listFeed),
    pageData: getPageData(listFeed),
    searchText: getSearchText(listFeed),
  };
}

export default connect(mapStateToProps, { listFeed, filterFeed })(ModalFilterFeed);
