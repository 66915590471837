import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, ListGroup, Row, Col } from "react-bootstrap";
import { Close } from "@mui/icons-material";
import { InsertInvitation } from "@mui/icons-material";
import NumericFormat from "react-number-format";
import InputSecond from "../../../../components/commons/forms/input";
import InputTree from "../../../../components/commons/forms/report/InputTree";
import { numberWithCommas, statusOptions, pemanasOptions, convertDate } from "../../../../utils/constants";
import SelectReport from "../../../../components/commons/forms/report/SelectReport";
import SelectReportSecond from "../../../../components/commons/forms/report/SelecReportSecond";
import CustomDatePicker from "../../../../components/commons/forms/datepicker/CustomDatePicker";
import CustomNumericFormat from "../../../../components/report/CustomNumericFormat";
import { FeedReport, DocReport, CheckKumulatif, detailReport, KumulatifChickIn, editReport, populasiChickIn } from "../../../../store/actions/farm/report";
import { detailFarm } from "../../../../store/actions/farm";
import { syncDashboard } from "../../../../store/actions/dashboard";
import {
  getFeed,
  getFeedPending,
  getFeedError,
  getDoc,
  getDocPending,
  getDocError,
  getDetailReportSuccess,
  getDetailReportPending,
  getDetailReportError,
  getKumulatifPanen,
  getKumulatifPanenError,
  getKumulatifPanenPending,
  getKumulatifChickIn,
  getKumulatifChickInPending,
  getKumulatifChickInError,
  getPopulasiChickIn,
  getPopulasiChickInPending,
  getPopulasiChickInError,
} from "../../../../store/reducers/farm/report";
import { getDetailFarmSuccess } from "../../../../store/reducers/farm";
import { useHistory } from "react-router-dom";
import AccordionBase from "../../../../components/report/AccordionBase";
import AccordionListGroup from "../../../../components/report/AccordionListGroup";
import BaseFormReport from "../../../../components/report/BaseFormReport";
import ModalDeleteObat from "./ModalDeleteObat";

import { defaultDateFormat, formatDate } from "../../../../utils";
import { handleInput, handlePenjualanAdd, handlePenjualanChange, handlePenjualanDelete, handleObatAdd, handleObatChange, handleObatDelete, handleDOCAdd, handleDocChange, handleDOCDelete } from "../../../../utils/report";
import ModalDeletePenjualan from "./ModalDeletePenjualan";
import ModalDeleteDoc from "./ModalDeleteDoc";
import { isEmpty } from "lodash";
import InputFoto from "../../../../components/commons/forms/report/InputFoto";
import * as calc from "../../../../utils/report/reportCalculation";
import { toastError } from "../../../../components/toast";

const Index = (props) => {
  const history = useHistory();
  const {
    feedSuccess,
    feedPending,
    feedError,
    docSuccess,
    docPending,
    docError,
    detailReportSuccess,
    detailReportPending,
    detailReportError,
    kumulatifPanen,
    kumulatifPanenPending,
    kumulatifPanenError,
    KumulatifChickInSuccess,
    KumulatifChickInPending,
    KumulatifChickInError,
    detailFarmSuccess,
    populasiChickInSuccess,
    populasiChickInPending,
    populasiChickInError,
  } = props;

  // define variable daily report
  const [nama_kandang, setNamaKandang] = useState("");
  const [tanggal, setTanggal] = useState(null);
  const [jam_pengisian, setJamPengisian] = useState("");
  const [umur_ayam, setUmurAyam] = useState("");
  const [populasi_chick_in, setPopulasiChickIn] = useState("");
  const [kematian, setKematian] = useState("");
  const [pakan, setPakan] = useState("");
  const [kode_pakan, setKodePakan] = useState("");
  const [jml_pakan_masuk, setJmlPakanMasuk] = useState("");
  const [jml_habis_pakan, setJmlHabisPakan] = useState("");
  const [berat_seluruh_ayam, setBeratSeluruhAyam] = useState("");
  const [populasi_saat_ini, setPopulasiSaatIni] = useState(0);
  const [fcr, setFcr] = useState(0);
  const [deplesi, setDeplesi] = useState(0);
  const [errorBeratSeluruhAyam, setErrorBeratSeluruhAyam] = useState("");
  const [errorJmlPakanMasuk, setErrorJmlPakanMasuk] = useState("");
  const [errorJmlHabisPakan, setErrorJmlHabisPakan] = useState("");
  const [errorKematian, setErrorKematian] = useState("");
  const [errorPakan, setErrorPakan] = useState("");
  const [errorPopulasiChickIn, setErrorPopulasiChickIn] = useState("");
  // define variable doc report
  const [doc, setDoc] = useState([]);
  const [errorDoc, setErrorDoc] = useState([]);
  // define variable obat
  const [obat, setObat] = useState([]);
  const [errorObat, setErrorObat] = useState([]);
  // define variable panen
  const [panen, setPanen] = useState(null);
  const [penjualan, setPenjualan] = useState([]);
  const [berat_panen, setBeratPanen] = useState(0);
  const [cum_umur, setCumUmur] = useState(0);
  const [ip, setIp] = useState(0);
  const [rup, setRup] = useState(0);
  const [stok_ayam, setStokAyam] = useState(0);
  const [errorPenjualan, setErrorPenjualan] = useState([]);
  // define variable pemanas
  const [nama_pemanas, setNamaPemanas] = useState("");
  const [jumlah_pemanas, setJumlahPemanas] = useState("");
  const [errorNamaPemanas, setErrorNamaPemanas] = useState("");
  const [errorJmlPemanas, setErrorJmlPemanas] = useState("");
  // define variable kuantitas sekam and status kandang
  const [kuantitas, setKuantitas] = useState("");
  const [status_kandang, setStatusKandang] = useState("");
  const [errorStatusKandang, setErrorStatusKandang] = useState("");

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);
  const [showModalDeleteObat, setShowModalDeleteObat] = useState(false);
  const [showModalDeletePenjualan, setShowModalDeletePenjualan] = useState(false);
  const [showModalDeleteDoc, setShowModalDeleteDoc] = useState(false);
  const [dataDeleteObat, setDataDeleteObat] = useState({ id: "", index: "" });
  const [dataDeletePenjualan, setDataDeletePenjualan] = useState({ id: "", index: "" });
  const [dataDeleteDoc, setDataDeleteDoc] = useState({ id: "", index: "" });
  const [disableTambahPenjualan, setDisableTambahPenjualan] = useState(false);

  const id_farm = props.match.params.id;
  const id_report = props.match.params.id_report;

  const dailyRef = useRef(null);
  const panenRef = useRef(null);
  const docRef = useRef(null);
  const obatRef = useRef(null);
  const pemanasRef = useRef(null);
  const sekamRef = useRef(null);
  const statusRef = useRef(null);

  const defaultDailyReport = () => {
    setNamaKandang(detailReportSuccess.daily_report.nama_kandang);
    setTanggal(new Date(defaultDateFormat(detailReportSuccess.daily_report.tanggal)));
    setJamPengisian(detailReportSuccess.daily_report.jam_pengisian);
    setUmurAyam(detailReportSuccess.daily_report.umur_ayam.toString());
    setPopulasiChickIn(detailReportSuccess.daily_report.populasi_chick_in.toString());
    setKematian(detailReportSuccess.daily_report.kematian.toString());
    setPakan(detailReportSuccess.daily_report.pakan);
    setKodePakan(detailReportSuccess.daily_report.kode_pakan);
    setJmlPakanMasuk(detailReportSuccess.daily_report.jml_pakan_masuk);
    setJmlHabisPakan(detailReportSuccess.daily_report.jml_habis_pakan.toString());
    setBeratSeluruhAyam(detailReportSuccess.daily_report.berat_seluruh_ayam.toString());
  };

  const defaultPanen = () => {
    if (detailReportSuccess.panen) {
      setPanen(detailReportSuccess.panen);
      setPenjualan(detailReportSuccess.panen.penjualan);
      setErrorPenjualan(
        detailReportSuccess.panen.penjualan.map(() => {
          return { jumlah: "", berat: "", nama: "", tonase: "" };
        })
      );
    } else {
      setPanen(null);
      setPenjualan([]);
      setErrorPenjualan([]);
    }
  };

  const defaultPemanas = () => {
    if (detailReportSuccess.pemanas) {
      setNamaPemanas(detailReportSuccess.pemanas.nama);
      setJumlahPemanas(detailReportSuccess.pemanas.jumlah);
    } else {
      setNamaPemanas("");
      setJumlahPemanas("");
    }
    setErrorNamaPemanas("");
    setErrorJmlPemanas("");
  };

  const ayamTerjualHariIni = () => {
    return penjualan.reduce((sum, item) => sum + (item.jumlah ? calc.toNum(item.jumlah) : 0), 0);
  };

  const tonaseHariIni = () => {
    return penjualan.reduce((sum, item) => sum + (item.tonase ? calc.toNum(item.tonase) : 0), 0);
  };

  useEffect(() => {
    if (isEmpty(detailReportSuccess) || detailReportSuccess.id !== id_report) props.detailReport(id_report);
    if (!isEmpty(detailReportSuccess)) {
      defaultDailyReport();
      defaultPanen();
      setDoc(
        detailReportSuccess.doc_report?.map((item) => ({
          ...item,
          merk: { label: item?.doc?.name_doc, value: item?.doc?.id },
          tanggal_masuk: new Date(defaultDateFormat(item?.tanggal_masuk))
        }))
      );
      setErrorDoc(
        detailReportSuccess.doc_report.map(() => {
          return { tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" };
        })
      );
      defaultPemanas();
      setObat(detailReportSuccess.obat);
      setErrorObat(
        detailReportSuccess.obat.map(() => {
          return { merk: "", jumlah: "" };
        })
      );
      setKuantitas(detailReportSuccess?.kuantitas_sekam);
      setStatusKandang(detailReportSuccess?.status_kandang);
    }
  }, [detailReportSuccess]);

  useEffect(() => {
    props.DocReport();
    props.FeedReport();
    props.CheckKumulatif(id_farm, id_report);
    props.KumulatifChickIn(id_farm, id_report);
    props.detailFarm(id_farm);
    props.detailReport(id_report);
    props.populasiChickIn(id_farm, id_report);
  }, []);

  useEffect(() => {
    if (status_kandang === "Panen") setDisableTambahPenjualan(false);
    else setDisableTambahPenjualan(true);
  }, [status_kandang]);

  // calculate populasi chick in
  useEffect(() => {
    let jumlahDoc = 0;
    if (doc?.length) {
      doc?.forEach((item) => {
        jumlahDoc += calc.toNum(item?.jumlah)
      })
    }
    setPopulasiChickIn(calc.toNum(jumlahDoc) + populasiChickInSuccess);
  }, [doc, populasiChickInSuccess]);

  //calculate populasi saat ini
  useEffect(() => {
    setPopulasiSaatIni(calc.calculatePopulasiSaatIni(populasi_chick_in, KumulatifChickInSuccess.kum_kematian, kematian));
  }, [populasi_chick_in, kematian, KumulatifChickInSuccess, detailReportSuccess]);

  //calculate FCR
  useEffect(() => {
    if (status_kandang === "Panen") setFcr(calc.calculateFCR(KumulatifChickInSuccess.komulatifHabisPakan, jml_habis_pakan, kumulatifPanen.kumTonase, tonaseHariIni()));
    else setFcr(0);
  }, [status_kandang, KumulatifChickInSuccess, jml_habis_pakan, penjualan]);

  //calculate deplesi
  useEffect(() => {
    if (status_kandang === "Panen") setDeplesi(calc.calculateDeplesi(populasi_chick_in, kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
    else setDeplesi(0);
  }, [status_kandang, populasi_chick_in, kumulatifPanen, penjualan]);

  //calculate berat rata-rata
  useEffect(() => {
    if (status_kandang === "Panen") setBeratPanen(calc.calculateBeratRataRata(kumulatifPanen.kumTonase, tonaseHariIni(), kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
    else setBeratPanen(0);
  }, [status_kandang, kumulatifPanen, penjualan]);

  //calculate sisa stok ayam
  useEffect(() => {
    setStokAyam(calc.calculateSisaStokAyam(populasi_saat_ini, kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
  }, [kumulatifPanen, penjualan, populasi_saat_ini]);

  //calculate cum umur
  useEffect(() => {
    if (status_kandang === "Panen") setCumUmur(calc.calculateCumUmur(umur_ayam, ayamTerjualHariIni()));
    else setCumUmur(0);
  }, [status_kandang, penjualan]);

  //calculate RUP
  useEffect(() => {
    if (status_kandang === "Panen") setRup(calc.calculateRUP(kumulatifPanen.kumCumUmur, cum_umur, kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
  }, [status_kandang, kumulatifPanen, cum_umur]);

  // calculate IP
  useEffect(() => {
    if (status_kandang === "Panen") setIp(calc.calculateIP(deplesi, berat_panen, fcr, rup));
    else setIp(0);
  }, [status_kandang, deplesi, penjualan, fcr, rup]);

  const setterError = (state, setError, fieldName) => {
    const errorMessage = state ? "" : `${fieldName} wajib diisi`;
    setError(errorMessage);
  };

  const validateDailyReport = (daily_report) => {
    for (const key in daily_report) {
      if (daily_report[key] === "" || daily_report[key] === null) {
        return false;
      }
    }
    return true;
  };

  const validatePenjualan = (penjualan) => {
    for (let i = 0; i < penjualan.length; i++) {
      if (penjualan[i].berat === "" || penjualan[i].jumlah === "" || penjualan[i].nama === "") {
        return false;
      }
    }
    return true;
  };

  const validateDocReport = (doc_report) => {
    if (doc_report === null) {
      if (populasiChickInSuccess === 0 && status_kandang === "Chick In") return false;
      return true;
    }

    for (let i = 0; i < doc_report.length; i++) {
      for (const key in doc_report[i]) {
        if (doc_report[i][key] === "" || doc_report[i][key] === null) {
          return false;
        }
      }
    }

    return true;
  };

  const validateObat = (obat) => {
    for (let i = 0; i < obat.length; i++) {
      for (const key in obat[i]) {
        if (obat[i][key] === "") {
          return false;
        }
      }
    }
    return true;
  };

  const validatePemanas = (jumlah_pemanas, nama_pemanas) => {
    return !(jumlah_pemanas === "" || nama_pemanas === "");
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    // Validate daily report fields
    const daily_report = {
      populasi_chick_in: calc.toNum(populasi_chick_in),
      kematian,
      pakan,
      jml_habis_pakan,
      jml_pakan_masuk,
      berat_seluruh_ayam,
    };
    setterError(kematian, setErrorKematian, "Kematian");
    setterError(pakan, setErrorPakan, "Pakan");
    setterError(jml_pakan_masuk, setErrorJmlPakanMasuk, "Jumlah pakan masuk");
    setterError(jml_habis_pakan, setErrorJmlHabisPakan, "Jumlah habis pakan");
    setterError(berat_seluruh_ayam, setErrorBeratSeluruhAyam, "Berat seluruh ayam");

    // Validate penjualan fields
    let panenObject = {
      ...panen,
      berat: numberWithCommas(berat_panen),
      stok_ayam,
      cum_umur,
      rup,
      ip,
      penjualan,
    };
    let tempErrorPenjualan = [...errorPenjualan];
    let newErrPenjualan = [];
    penjualan.map((jual, i) => {
      let newErr = {
        jumlah: "",
        berat: "",
        nama: "",
        tonase: "",
      };
      if (jual.jumlah === "") {
        newErr.jumlah = "Jumlah wajib diisi";
      }
      if (jual.berat === "") {
        newErr.berat = "Berat rata-rata wajib diisi";
      }
      if (jual.tonase === "") {
        newErr.tonase = "Tonase wajib diisi";
      }
      if (jual.nama === "") {
        newErr.nama = "Nama pembeli wajib diisi";
      }
      newErrPenjualan = [
        ...tempErrorPenjualan.map((v, j) => {
          return i === j ? newErr : v;
        }),
      ];
      tempErrorPenjualan = newErrPenjualan;
    });
    setErrorPenjualan(newErrPenjualan);

    // Validate doc report fields
    
    let doc_report = [];
    if (doc.length !== 0) {
      let tempErrorDoc = [...errorDoc];
      let newErrDoc = [];
      doc?.filter((item) => item?.action !== "DELETE")?.map((item, i) => {
        let newErr = { tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" };
        if (!item.tanggal_masuk) newErr.tanggal_masuk = "Tanggal Masuk wajib diisi";
        if (!item.merk) newErr.merk = "Merk wajib diisi";
        if (!item.jumlah) newErr.jumlah = "Jumlah wajib diisi";
        if (!item.bobot) newErr.bobot = "Bobot wajib diisi";
        if (!item.kondisi) newErr.kondisi = "Kondisi wajib diisi";
        newErrDoc = [
          ...tempErrorDoc.map((v, j) => {
            return i === j ? newErr : v;
          }),
        ];
        tempErrorDoc = newErrDoc;
      });
      setErrorDoc(newErrDoc);

      doc_report = doc?.map((item) => ({
        ...item,
        id_doc: item?.merk?.value,
        tanggal_masuk: convertDate(item?.tanggal_masuk),
        action: item?.action || (item?.id ? "UPDATE" : "INSERT")
      }));
    }

    // Validate obat fields
    if (obat.length !== 0) {
      let tempErrorObat = [...errorObat];
      let newErrObat = [];
      obat.map((obt, i) => {
        let newErr = { merk: "", jumlah: "" };
        if (obt.merk === "") newErr.merk = "Merk wajib diisi";
        if (obt.jumlah === "") newErr.jumlah = "Jumlah wajib diisi";
        newErrObat = [
          ...tempErrorObat.map((v, j) => {
            return i === j ? newErr : v;
          }),
        ];
        tempErrorObat = newErrObat;
      });
      setErrorObat(newErrObat);
    }

    // Validate pemanas fields
    let pemanasObject = null;
    if (validatePemanas(jumlah_pemanas, nama_pemanas)) {
      pemanasObject = {
        nama: nama_pemanas,
        jumlah: jumlah_pemanas,
      };

      setterError(nama_pemanas, setErrorNamaPemanas, "Nama pemanas");
      setterError(jumlah_pemanas, setErrorJmlPemanas, "Jumlah pemanas");
    } else {
      pemanasObject = null;
      setErrorNamaPemanas("");
      setErrorJmlPemanas("");
    }

    // Validate all sections
    const isDailyReportValid = validateDailyReport(daily_report);
    const isPenjualanValid = validatePenjualan(penjualan);
    const isDocReportValid = validateDocReport(doc_report?.filter((item) => item?.action !== "DELETE"));
    const isObatValid = validateObat(obat);

    if (!(isDailyReportValid && isPenjualanValid && isDocReportValid && isObatValid)) {
      setDisableBtnSubmit(false);
      toastError("Silakan periksa kembali isian form");
      return;
    }

    const payload = {
      daily_report: {
        ...daily_report,
        deplesi: numberWithCommas(deplesi),
        fcr: numberWithCommas(fcr),
        id: detailReportSuccess.daily_report.id,
        id_kandang: id_farm,
        jam_pengisian,
        kematian,
        kode_pakan,
        populasi_saat_ini,
        tanggal: formatDate(tanggal),
        umur_ayam,
      },
      doc_report: doc_report.map(({merk, ...item}) => {
        if (item?.action === "DELETE") {
          return {
            ...detailReportSuccess?.doc_report?.find((doc) => doc?.id === item?.id),
            action: "DELETE"
          }
        }

        return item;
      }),
      id: id_report,
      id_farm,
      kuantitas: calc.toNum(kuantitas),
      obat,
      panen: panenObject,
      pemanas: pemanasObject,
      status: status_kandang,
    };
    props.editReport(payload, callbackSuccess, () => setDisableBtnSubmit(false));
  };

  const callbackSuccess = () => {
    setTimeout(() => {
      const payload = { date: formatDate(tanggal).replace(/\//g, "-"), farm: id_farm };
      props.syncDashboard(payload);
      props.detailReport(id_report);
      setDisableBtnSubmit(false);
      history.push({ pathname: "/farmmanagement/" + id_farm + "/report" });
    }, 1000);
  };

  const handleBack = () => {
    history.push("/farmmanagement/" + id_farm + "/report");
  };

  const handleButtonBatalMerah = (type) => {
    switch (type) {
      case "daily":
        dailyRef.current.click();
        defaultDailyReport();
        break;
      case "panen":
        panenRef.current.click();
        defaultPanen();
        break;
      case "doc":
        docRef.current.click();
        if (detailReportSuccess.doc_report) {
          setDoc(
            detailReportSuccess.doc_report?.map((item) => ({
              ...item,
              merk: { label: item?.doc?.name_doc, value: item?.doc?.id },
              tanggal_masuk: new Date(defaultDateFormat(item?.tanggal_masuk))
            }))
          );
        } else {
          setDoc([]);
        }
        setErrorDoc([]);
        break;
      case "obat":
        obatRef.current.click();
        if (detailReportSuccess.obat) setObat(detailReportSuccess.obat);
        else setObat([]);
        setErrorObat([]);
        break;
      case "pemanas":
        pemanasRef.current.click();
        defaultPemanas();
        break;
      case "sekam":
        sekamRef.current.click();
        if (detailReportSuccess.kuantitas_sekam) setKuantitas(detailReportSuccess.kuantitas_sekam);
        else setKuantitas("");
        break;
      case "status_kandang":
        statusRef.current.click();
        if (detailReportSuccess.status_kandang) setStatusKandang(detailReportSuccess.status_kandang);
        else setStatusKandang("");
        break;
    }
  };

  if (kumulatifPanenPending || detailReportPending || docPending || feedPending || KumulatifChickInPending || populasiChickInPending) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ minWidth: "100%", minHeight: "100vh" }}>
        Mengambil data report...
      </div>
    );
  } else if (kumulatifPanenError || detailReportError || docError || feedError || KumulatifChickInError || populasiChickInError) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ minWidth: "100%", minHeight: "100vh" }}>
        Gagal mengambil data report...
      </div>
    );
  } else {
    const transformedDoc = doc?.map((item, index) => ({ ...item, originalIndex: index }));
    const filteredDoc = transformedDoc?.filter(item => item?.action !== "DELETE");

    return (
      <BaseFormReport title={"Edit"} handleBack={handleBack}>
        <ModalDeleteObat data={dataDeleteObat} error={errorObat} setError={setErrorObat} obat={obat} setObat={setObat} show={showModalDeleteObat} setShow={setShowModalDeleteObat} />
        <ModalDeletePenjualan
          data={dataDeletePenjualan}
          error={errorPenjualan}
          setError={setErrorPenjualan}
          penjualan={penjualan}
          setPenjualan={setPenjualan}
          show={showModalDeletePenjualan}
          setShow={setShowModalDeletePenjualan}
        />
        <ModalDeleteDoc
          data={dataDeleteDoc}
          error={errorDoc}
          setError={setErrorDoc}
          doc={doc}
          setDoc={setDoc}
          show={showModalDeleteDoc}
          setShow={setShowModalDeleteDoc} 
        />
        {/* untuk acordion Daily Report */}
        <AccordionBase title={"Daily Report"} accordionRef={dailyRef}>
          <AccordionListGroup>
            <InputSecond label={"Nama Kandang"} placeholder={"Masukkan nama kandang"} value={nama_kandang || ""} readOnly={true} />
            <CustomDatePicker
              label={"Tanggal"}
              type={"date"}
              leftIcon={<InsertInvitation style={{ color: "A5A5C0" }} />}
              placeholder={"Masukkan tanggal"}
              selected={tanggal}
              wrapperClassName="datepicker"
              className="form-control"
              dateFormat="dd MMMM yyyy"
              readOnly={true}
            />
            <InputSecond label={"Jam Pengisian"} leftIcon={<InsertInvitation style={{ color: "A5A5C0" }} />} value={jam_pengisian || ""} readOnly={true} />
            <InputSecond label={"Umur Ayam (hari)"} style={{ color: "black" }} placeholder={"Masukkan umur ayam"} value={umur_ayam} onChange={() => handleInput(setUmurAyam)} readOnly={true} />
          </AccordionListGroup>
          <AccordionListGroup>
            <div className="row">
              <div className="col-md-6">
                <CustomNumericFormat
                  label={"Populasi Saat Chick In (ekor)"}
                  placeholder={"Masukkan populasi saat chick in"}
                  value={populasi_chick_in}
                  onChange={(e) => handleInput(e, setPopulasiChickIn, setErrorPopulasiChickIn)}
                  rule={`Populasi saat chick in`}
                  error={errorPopulasiChickIn}
                  readOnly={status_kandang !== "Chick In" || new Date(new Date(tanggal).setHours(0, 0, 0, 0)).getTime() !== detailFarmSuccess.chick_in_date}
                />
              </div>
              <div className="col-md-6">
                <CustomNumericFormat
                  label={"Kematian (ekor)"}
                  placeholder={"Masukkan Kematian Hari Ini"}
                  value={kematian}
                  onChange={(e) => handleInput(e, setKematian, setErrorKematian)}
                  rule={`Kematian`}
                  error={errorKematian}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectReport
                  options={feedSuccess == null ? {} : feedSuccess}
                  placeholder={"Masukkan Pakan"}
                  label={"Pakan"}
                  isAsync={true}
                  asyncUrl={"/v1/master_data/feed"}
                  isPakan={true}
                  value={pakan ? { label: pakan, value: pakan } : ""}
                  onChange={(e) =>
                    handleInput(e, setPakan, setErrorPakan, "Pakan", (e) => {
                      setKodePakan(e ? e.kode : "");
                    })
                  }
                  error={errorPakan}
                  isError={errorPakan !== ``}
                />
              </div>
              <div className="col-md-6">
                <InputTree style={{ color: "black" }} label={"Kode Pakan"} placeholder={"Masukkan kode pakan"} readOnly={true} value={kode_pakan} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <CustomNumericFormat
                  label={"Jumlah Pakan Masuk (sak)"}
                  placeholder={"Masukkan jumlah pakan masuk"}
                  value={jml_pakan_masuk}
                  onChange={(e) => handleInput(e, setJmlPakanMasuk, setErrorJmlPakanMasuk)}
                  rule={`Jumlah pakan masuk`}
                  error={errorJmlPakanMasuk}
                />
              </div>
              <div className="col-md-6">
                <CustomNumericFormat
                  label={"Jumlah Habis Pakan (sak)"}
                  placeholder={"Masukkan jumlah habis pakan"}
                  value={jml_habis_pakan}
                  onChange={(e) => handleInput(e, setJmlHabisPakan, setErrorJmlHabisPakan)}
                  rule={`Jumlah habis pakan`}
                  error={errorJmlHabisPakan}
                />
              </div>
            </div>
            <div className="row">
              <CustomNumericFormat
                label={"Berat Seluruh Ayam (kg)"}
                placeholder={"Masukkan berat seluruh ayam"}
                value={berat_seluruh_ayam}
                onChange={(e) => handleInput(e, setBeratSeluruhAyam, setErrorBeratSeluruhAyam)}
                rule={`Berat Seluruh Ayam`}
                error={errorBeratSeluruhAyam}
                isComma={true}
              />
            </div>
          </AccordionListGroup>
          <AccordionListGroup backgroundColor={"#F4F5F7"}>
            <Row style={{ marginBottom: "43px" }}>
              <Col md={5}>
                <span className="text-bold">Populasi Saat Ini</span>
              </Col>
              <Col>{numberWithCommas(populasi_saat_ini) + " ekor" || 0}</Col>
            </Row>
            <Row style={{ marginBottom: "43px" }}>
              <Col md={5}>
                <span className="text-bold">Food Conversion Ratio (FCR)</span>
              </Col>
              <Col>{numberWithCommas(fcr)}</Col>
            </Row>
            <Row style={{ marginBottom: "43px" }}>
              <Col md={5}>
                <span className="text-bold">Deplesi</span>
              </Col>
              <Col md={5} style={{ color: parseFloat(deplesi) <= 0 ? "#1CB289" : "#FF4263" }}>
                {numberWithCommas(deplesi) + " %"}
              </Col>
            </Row>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("daily")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Penjualan hasil panen */}
        <AccordionBase title={"Penjualan Hasil Panen"} accordionRef={panenRef}>
          <AccordionListGroup>
            {penjualan.map((pjln, index) => (
              <ListGroup style={{ borderRadius: "12px", marginBottom: "30px" }} className="me-5 mt-5" key={index}>
                <ListGroup.Item className="pt-4 pb-4 ps-5 pe-5">
                  <span
                    className="position-absolute top-0 start-100 translate-middle p-2 bg-danger-2 border border-white shadow-sm rounded-circle"
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePenjualanDelete(index, pjln.id, setDataDeletePenjualan, setShowModalDeletePenjualan)}
                  >
                    <Close style={{ color: "white" }} />
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <CustomNumericFormat
                        label={"Jumlah (ekor)"}
                        placeholder={"Masukkan jumlah ayam terjual"}
                        onChange={(e) => handlePenjualanChange(e, index, "jumlah", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        value={pjln.jumlah || ""}
                        rule={`Jumlah ayam terjual`}
                        error={errorPenjualan[index]?.jumlah}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomNumericFormat
                        label={"Berat Rata-Rata (kg)"}
                        placeholder={"Masukkan berat rata-rata (kg)"}
                        onChange={(e) => handlePenjualanChange(e, index, "berat", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        value={pjln.berat || ""}
                        rule={`Berat rata-rata`}
                        error={errorPenjualan[index]?.berat}
                        isComma={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <CustomNumericFormat
                        label={"Tonase (kg)"}
                        placeholder={"Masukkan tonase"}
                        value={pjln.tonase}
                        onChange={(e) => handlePenjualanChange(e, index, "tonase", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        error={errorPenjualan[index]?.tonase}
                        rule={`Tonase`}
                        isComma={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputTree
                        label={"Nama Pembeli"}
                        placeholder={"Masukkan nama pembeli"}
                        value={pjln.nama}
                        onChange={(e) => handlePenjualanChange(e, index, "nama", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        error={errorPenjualan[index]?.nama}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <InputFoto
                        label={"Foto"}
                        onChange={(e) => handlePenjualanChange(e, index, "foto", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        prevImage={penjualan[index].foto}
                        error={errorPenjualan[index]?.foto}
                      />
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
            <div className="row">
              <div className="col-md-12 text-center">
                <Button className="mt-5 mb-5 hover-none btn-outline-primary" onClick={() => handlePenjualanAdd(penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)} disabled={disableTambahPenjualan}>
                  Tambah Data
                </Button>
              </div>
            </div>
          </AccordionListGroup>
          <AccordionListGroup backgroundColor={"#F4F5F7"}>
            <Row style={{ marginBottom: "38px" }}>
              <Col md={5}>
                <span className="text-bold">Berat Rata-Rata</span>
              </Col>
              <Col md={5}>{numberWithCommas(berat_panen) + " kg"}</Col>
            </Row>
            <Row style={{ marginBottom: "38px" }}>
              <Col md={5}>
                <span className="text-bold">Sisa Stok Ayam</span>
              </Col>
              <Col>{numberWithCommas(stok_ayam) + " ekor"}</Col>
            </Row>
            <Row style={{ marginBottom: "38px" }}>
              <Col md={5}>
                <span className="text-bold">Kumulatif Umur</span>
              </Col>
              <Col>{numberWithCommas(cum_umur)}</Col>
            </Row>
            <Row style={{ marginBottom: "38px" }}>
              <Col md={5}>
                <span className="text-bold">RUP</span>
              </Col>
              <Col>{numberWithCommas(rup)}</Col>
            </Row>
            <Row style={{ marginBottom: "38px" }}>
              <Col md={5}>
                <span className="text-bold">Indeks Performance (IP)</span>
              </Col>
              <Col md={5}>
                <span
                  style={{
                    background: ip < 300 ? "#FF4263" : ip >= 300 && ip <= 350 ? "#E8A720" : "#1CB289",
                    color: "#FFF",
                    borderRadius: "4px",
                    padding: "6px 12px",
                  }}
                >
                  {numberWithCommas(ip)}
                </span>
              </Col>
            </Row>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("panen")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion DOC */}
        <AccordionBase title={"DOC"} accordionRef={docRef}>
          <AccordionListGroup>
            {filteredDoc?.map((item, i) => (
              <ListGroup style={{ borderRadius: "12px" }} className="me-5 mt-5" key={i}>
                <ListGroup.Item className="pt-4 pb-4 ps-5 pe-5">
                  <span
                    className="position-absolute top-0 start-100 translate-middle p-2 bg-danger-2 border border-white shadow-sm rounded-circle"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDOCDelete(item?.originalIndex || i, item.id, setDataDeleteDoc, setShowModalDeleteDoc)}
                  >
                    <Close style={{ color: "white" }} />
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <CustomDatePicker
                        displayColumn={true}
                        label={"Tanggal Masuk"}
                        type={"date"}
                        leftIcon={<InsertInvitation style={{ color: "A5A5C0" }} />}
                        placeholder={"Masukkan tanggal masuk"}
                        wrapperClassName="datepicker"
                        className="form-control"
                        dateFormat="d MMMM yyyy"
                        onChange={(e) => handleDocChange(e, item?.originalIndex || i, "tanggal_masuk", doc, setDoc, errorDoc, setErrorDoc)}
                        selected={item?.tanggal_masuk}
                        defaultValue={item?.tanggal_masuk || ""}
                        error={errorDoc[i]?.tanggal_masuk}
                      />
                    </div>
                    <div className="col-md-6">
                      <SelectReport
                        options={docSuccess == null ? {} : docSuccess}
                        placeholder={"Masukkan merk"}
                        label={"Merk"}
                        isAsync={true}
                        asyncUrl={"/v1/master_data/doc"}
                        onChange={(e) => handleDocChange(e, item?.originalIndex || i, "merk", doc, setDoc, errorDoc, setErrorDoc)}
                        value={item?.merk || ""}
                        error={errorDoc[i]?.merk}
                        isError={errorDoc?.length && errorDoc[i]?.merk !== ''}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <NumericFormat
                        label={"Jumlah"}
                        placeholder={"Masukkan jumlah"}
                        customInput={InputTree}
                        thousandSeparator="."
                        decimalSeparator=","
                        rule={`Jumlah DOC`}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        onChange={(e) => handleDocChange(e.target.value, item?.originalIndex || i, "jumlah", doc, setDoc, errorDoc, setErrorDoc)}
                        value={item?.jumlah || ""}
                        error={errorDoc[i]?.jumlah}
                      />
                    </div>
                    <div className="col-md-6">
                      <NumericFormat
                        label={"Bobot (gr)"}
                        placeholder={"Masukkan bobot (gr)"}
                        customInput={InputTree}
                        thousandSeparator="."
                        decimalSeparator=","
                        rule={"Bobot"}
                        onKeyPress={(e) => !/[0-9,]/.test(e.key) && e.preventDefault()}
                        onChange={(e) => handleDocChange(e.target.value, item?.originalIndex || i, "bobot", doc, setDoc, errorDoc, setErrorDoc)}
                        value={item?.bobot || ""}
                        error={errorDoc[i]?.bobot}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <InputTree
                        style={{ color: "black" }}
                        label={"Kondisi"}
                        placeholder={"Masukkan kondisi"}
                        rule={"Kondisi"}
                        onChange={(e) => handleDocChange(e.target.value, item?.originalIndex || i, "kondisi", doc, setDoc, errorDoc, setErrorDoc)}
                        value={item?.kondisi || ""}
                        error={errorDoc[i]?.kondisi}
                      />
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
            <div className="row">
              <div className="col-md-12 text-center">
                <Button className="mt-5 mb-5 hover-none btn-outline-primary"  onClick={() => handleDOCAdd(doc, setDoc, errorDoc, setErrorDoc)}>
                  Tambah Data
                </Button>
              </div>
            </div>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("doc")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Pemakaian obat dan vitamin */}
        <AccordionBase title={"Pemakaian Obat & Vitamin"} accordionRef={obatRef}>
          <AccordionListGroup>
            {obat?.map((obt, index) => (
              <ListGroup style={{ borderRadius: "12px", marginBottom: "30px" }} className="me-5 mt-5" key={index}>
                <ListGroup.Item className="pt-4 pb-4 ps-5 pe-5">
                  <span
                    className="position-absolute top-0 start-100 translate-middle p-2 bg-danger-2 border border-white shadow-sm rounded-circle"
                    onClick={() => handleObatDelete(index, obt.id, setDataDeleteObat, setShowModalDeleteObat)}
                    style={{ cursor: "pointer" }}
                  >
                    <Close style={{ color: "white" }} />
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTree
                        label={"Merk Obat"}
                        placeholder={"Masukkan merk obat"}
                        onChange={(e) => handleObatChange(e, index, "merk", obat, setObat, errorObat, setErrorObat)}
                        value={obt.merk}
                        error={errorObat[index]?.merk}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomNumericFormat
                        label={"Jumlah"}
                        placeholder={"Masukkan jumlah"}
                        onChange={(e) => handleObatChange(e, index, "jumlah", obat, setObat, errorObat, setErrorObat)}
                        value={obt.jumlah}
                        error={errorObat[index]?.jumlah}
                      />
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
            <div className="row">
              <div className="col-md-12 text-center">
                <Button variant="" className="mt-5 mb-5 hover-none btn-outline-primary" onClick={() => handleObatAdd(obat, setObat, errorObat, setErrorObat)}>
                  Tambah Data
                </Button>
              </div>
            </div>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("obat")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Pemanas */}
        <AccordionBase title={"Pemanas"} accordionRef={pemanasRef}>
          <AccordionListGroup>
            <SelectReportSecond
              options={pemanasOptions}
              placeholder={"Masukkan jenis pemanas"}
              label={"Jenis Pemanas"}
              value={nama_pemanas ? { label: nama_pemanas, value: nama_pemanas } : ""}
              onChange={(e) => handleInput(e, setNamaPemanas, setErrorNamaPemanas, "Jenis pemanas", undefined, true)}
              error={errorNamaPemanas}
            />
            <CustomNumericFormat
              label={"Jumlah"}
              placeholder={"Masukkan jumlah pemanas"}
              onChange={(e) => handleInput(e, setJumlahPemanas, setErrorJmlPemanas, undefined, undefined, true)}
              value={jumlah_pemanas || ""}
              rule={"Jumlah Pemanas"}
              second={true}
              error={errorJmlPemanas}
            />
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("pemanas")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Sekam */}
        <AccordionBase title={"Sekam"} accordionRef={sekamRef}>
          <AccordionListGroup>
            <CustomNumericFormat label={"Kuantitas (sak)"} placeholder={"Masukkan kuantitas"} onChange={(e) => handleInput(e, setKuantitas)} value={kuantitas || ""} />
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("sekam")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Status Kandang */}
        <AccordionBase title={"Status Kandang"} accordionRef={statusRef}>
          <AccordionListGroup>
            <SelectReportSecond
              options={statusOptions}
              label={"Status Kandang"}
              placeholder={"Masukkan status kandang"}
              value={detailReportSuccess.status_kandang ? { value: status_kandang, label: status_kandang } : { value: "", label: "Masukkan status kandang" }}
              onChange={(e) => handleInput(e, setStatusKandang, setErrorStatusKandang, "Status Kandang")}
              isError={errorStatusKandang !== ``}
              error={errorStatusKandang}
            />
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("status_kandang")}>
            Batal
          </div>
        </AccordionBase>
        <div className="row mt-5 mb-5 pb-5 d-flex flex-row-reverse">
          <Button variant="contained" className="btn-simpan-report" onClick={submitForm} disabled={disableBtnSubmit}>
            Simpan
          </Button>
          <Button variant="outlined" className="btn-btl-report" onClick={handleBack}>
            Batal
          </Button>
        </div>
      </BaseFormReport>
    );
  }
};

const mapStateToProps = ({ FeedReport, DocReport, CheckKumulatif, detailReport, KumulatifChickIn, detailFarm, populasiChickIn }) => {
  return {
    feedSuccess: getFeed(FeedReport),
    feedPending: getFeedPending(FeedReport),
    feedError: getFeedError(FeedReport),
    docSuccess: getDoc(DocReport),
    docPending: getDocPending(DocReport),
    docError: getDocError(DocReport),
    kumulatifPanen: getKumulatifPanen(CheckKumulatif),
    kumulatifPanenPending: getKumulatifPanenPending(CheckKumulatif),
    kumulatifPanenError: getKumulatifPanenError(CheckKumulatif),
    detailReportSuccess: getDetailReportSuccess(detailReport),
    detailReportPending: getDetailReportPending(detailReport),
    detailReportError: getDetailReportError(detailReport),
    KumulatifChickInSuccess: getKumulatifChickIn(KumulatifChickIn),
    KumulatifChickInPending: getKumulatifChickInPending(KumulatifChickIn),
    KumulatifChickInError: getKumulatifChickInError(KumulatifChickIn),
    detailFarmSuccess: getDetailFarmSuccess(detailFarm),
    populasiChickInSuccess: getPopulasiChickIn(populasiChickIn),
    populasiChickInPending: getPopulasiChickInPending(populasiChickIn),
    populasiChickInError: getPopulasiChickInError(populasiChickIn),
  };
};

const mapDispatchToProps = {
  FeedReport,
  DocReport,
  CheckKumulatif,
  detailReport,
  editReport,
  KumulatifChickIn,
  detailFarm,
  syncDashboard,
  populasiChickIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
