import {actionSuccess , actionError , actionPending} from '../action_types'
import API from "../API";
import {toastSuccess , toastError} from "../../../components/toast"
import { history } from "../../../utils"
import { LOCAL_STORAGE_KEY } from '../../../utils/constants';
import { CHANGE_PROFILE_OPTION } from '../profile';

export const LOGIN_USER_PENDING = `LOGIN_USER_PENDING`
export const LOGIN_USER_SUCCESS = `LOGIN_USER_SUCCESS`
export const LOGIN_USER_ERROR   = `LOGIN_USER_ERROR`

export const LOGOUT_USER_PENDING = `LOGOUT_USER_PENDING`
export const LOGOUT_USER_SUCCESS = `LOGOUT_USER_SUCCESS`
export const LOGOUT_USER_ERROR   = `LOGOUT_USER_ERROR`

const LOGIN_USER_URL = `/v1/authentications/login`

export const loginUser = ({email , pwd}, callbackSuccess, callbackFailed) => dispatch => {
    dispatch(actionPending(LOGIN_USER_PENDING))
    API.post(LOGIN_USER_URL , {email , pwd})
        .then(res => {
            dispatch(actionSuccess(LOGIN_USER_SUCCESS , res.data.data))
            localStorage.setItem(LOCAL_STORAGE_KEY , JSON.stringify(res.data.data))
            toastSuccess(res.data.message)
            callbackSuccess();
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        })
        .catch(error => {
            dispatch(actionError(LOGIN_USER_ERROR, error));
            toastError(error, "");
            callbackFailed();
        })
}

export const logoutUser = (param = {}) => dispatch => {
    dispatch(actionPending(LOGOUT_USER_PENDING))
    try {
        localStorage.removeItem(LOCAL_STORAGE_KEY)
        dispatch(actionSuccess(CHANGE_PROFILE_OPTION , false))
        toastSuccess("Logout berhasil");
    }catch(error){
        dispatch(actionError(LOGOUT_USER_ERROR , error))
        toastError(error, "");
    }
    dispatch(actionPending(LOGOUT_USER_SUCCESS))
    history.push("/")
}