import React from "react";
import NumberFormat from "react-number-format";
import InputTree from "../commons/forms/report/InputTree";
import InputSecond from "../commons/forms/input";

const CustomNumericFormat = ({ label, placeholder, value, error, rule, onChange, readOnly = false, second = false, isComma = false }) => {
  return (
    <NumberFormat
      label={label}
      customInput={second ? InputSecond : InputTree}
      placeholder={placeholder}
      thousandSeparator="."
      decimalSeparator=","
      value={value}
      onChange={onChange}
      onKeyPress={(e) => {
        if (isComma) !/[0-9,]/.test(e.key) && e.preventDefault();
        else !/[0-9]/.test(e.key) && e.preventDefault();
      }}
      rule={rule}
      error={error}
      readOnly={readOnly}
    />
  );
};

export default CustomNumericFormat;
