import { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { SidebarItem } from "./sidebarItem";
import Profile from "./profile";
import { connect, useDispatch } from "react-redux";

const Index = (props) => {

  const {profileOption} = props?.profile

  return (
    <nav className={`nav-container ${profileOption ? 'nav-container-active' : ''}`}>
      <Profile />
      <div className="line-ul"></div>
      <ul className="nav-ul">
        <li className="nav-items">
          <NavLink to={'/dashboard'}>
            <span className="material-icons-round sidebar-icon">
              grid_view
            </span>
            <span className="sidebar-title .text-bold">Dashboard</span>
          </NavLink>
        </li>
        <li className="nav-items">
          <NavLink to={'/usermanagement'}>
            <span className="material-icons-round sidebar-icon">
            manage_accounts
            </span>
            <span className="sidebar-title .text-bold">User Management</span>
          </NavLink>
        </li>
        <li className="nav-items">
          <NavLink to={'/farmmanagement'}>
            <span className="material-icons-round sidebar-icon">
            holiday_village
            </span>
            <span className="sidebar-title .text-bold">Farm Management</span>
          </NavLink>
        </li>
        <li className="nav-items">
         <NavLink to={'/feedmanagement'}>
            <span className="material-icons-round sidebar-icon">
              panorama_vertical_select
            </span> 
            <span className="sidebar-title .text-bold">Feed Management</span>
          </NavLink>
        </li>
        <li className="nav-items">
          <NavLink to={'/docmanagement'}>
              <span className="material-icons-round sidebar-icon">
                article
              </span>
              <span className="sidebar-title .text-bold">DOC Management</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(Index));
