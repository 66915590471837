import { connect } from "react-redux";
import { Event } from "@mui/icons-material";
import DateRangePicker from "../../components/commons/forms/datepicker/DateRangePicker";
import { useEffect, useState, useRef } from "react";
import { months } from "../../utils/constants";
import ChartJS from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { numberWithCommas } from "../../utils/constants";
import { customDecimal } from "../../utils/report/reportCalculation";

// load actions
import { chartBeratAyam, updateBAFilter } from "../../store/actions/dashboard";

// load reducers
import { getChart, getChartPending, getChartError, getChartFilter, getFilter } from "../../store/reducers/dashboard";
import { isEmpty, range } from "lodash";

const Index = (props) => {
  // setup useRef
  const BAChartRef = useRef();

  // setup chart color
  const { BAChartSuccess, BAChartPending, BAChartError, BAChartFilter, filterKandang } = props;

  const toPersen = (num) => {
    if (isNaN(parseFloat(num))) return 0;
    return Intl.NumberFormat("en-US").format(parseFloat(num).toFixed(2)).toString().replace(",", " ").replace(".", ",").replace(" ", ".");
  };
  const numFormat = (num) => {
    return numberWithCommas(customDecimal(num));
  };

  const cvDateToString = (d) => new Date(d).toLocaleDateString("fr-FR").toString();
  const handleChangeBA = (e) => {
    const m = cvDateToString(e).split("/")[1];
    const y = cvDateToString(e).split("/")[2];
    props.updateBAFilter({ bulan: m, tahun: y });
  };

  // custom tooltip handles

  // get data based on date label
  const getDataByDate = (date) => {
    let result = null;
    if (BAChartSuccess && BAChartSuccess?.beratAyam.length > 0) {
      BAChartSuccess.beratAyam.map((d) => {
        if (parseInt(d.date.split("/")[0]) == parseInt(date)) {
          result = d;
        }
      });
    }
    return result;
  };

  const parsingDate = (date) => {
    const ds = date.split("/");
    return `${ds[0]} ${months[ds[1] - 1]} ${ds[2]}`;
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div.main-tooltip-wrapper");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.className = "main-tooltip-wrapper";
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const handleToolTip = (context) => {
    if (!context.tooltip?.dataPoints) return;

    const date = context.tooltip?.dataPoints[0]?.label;
    const data = getDataByDate(date);

    // // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      tooltipEl.style.zIndex = -1;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    // create title element
    const hw = document.createElement("div");
    hw.className = "head-wrapper";

    // fill the header data
    // tanggal
    const tgItem = document.createElement("div");
    tgItem.className = "hr-item";
    const tgTl = document.createElement("span");
    const tgTv = document.createElement("span");
    tgTl.className = "title-label";
    tgTv.className = "title-value";
    tgTl.innerHTML = "Tanggal";
    tgTv.innerHTML = parsingDate(data.date);
    tgItem.appendChild(tgTl);
    tgItem.appendChild(tgTv);
    hw.appendChild(tgItem);
    // jumlah
    const jmItem = document.createElement("div");
    jmItem.className = "hr-item";
    const jmTl = document.createElement("span");
    const jmTv = document.createElement("span");
    jmTl.className = "title-label";
    jmTv.className = "title-value";
    jmTl.innerHTML = "Jumlah";
    jmTv.innerHTML = toPersen(data.jumlah);
    jmItem.appendChild(jmTl);
    jmItem.appendChild(jmTv);
    hw.appendChild(jmItem);
    // berat
    const brItem = document.createElement("div");
    brItem.className = "hr-item";
    const brTl = document.createElement("span");
    const brTv = document.createElement("span");
    brTl.className = "title-label";
    brTv.className = "title-value";
    brTl.innerHTML = "Berat";
    brTv.innerHTML = toPersen(data.berat);
    brItem.appendChild(brTl);
    brItem.appendChild(brTv);
    hw.appendChild(brItem);

    // create body element
    const bw = document.createElement("div");
    bw.className = "body-wrapper";

    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }
    tooltipEl.appendChild(hw);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.zIndex = 99999;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY - tooltipEl.offsetHeight + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  // setup chart options
  const beratAyamOpt = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          padding: 15,
        },
      },
      y: {
        title: {
          display: true,
          text: "Berat (kg)",
          color: "#919392",
          font: {
            family: "PlusJakartaSans-Regular",
            size: 14,
            weight: "500",
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        suggestedMin: 0,
        ticks: {
          padding: 15,
          callback: (val) => {
            return numFormat(val);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: "nearest",
        external: handleToolTip,
      },
      legend: { display: false },
    },
    layout: {
      padding: 15,
    },
  };
  let BAChartColor;
  // load props data

  const [beratAyamR, setBeratAyamR] = useState(0);
  const [BALabels, setBALabels] = useState(["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Dec"]);
  const baseChartData = {
    labels: BALabels,
    datasets: [
      {
        data: range(0, 30, 0),
        fill: true,
        tension: 0.1,
        borderJoinStyle: "bevel",
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(0,0,0,0)",
        borderColor: "#ed3636",
        backgroundColor: BAChartColor,
        lineTension: 0.3,
      },
    ],
  };
  const [BAData, setBAData] = useState({
    ...baseChartData,
    datasets: [{ ...baseChartData.datasets[0], borderColor: "#1C37BE" }],
  });

  // use effect hook for rendering berat ayam rata rata chart
  useEffect(() => {
    const BAChart = BAChartRef.current;
    let labels = undefined;
    let data = undefined;
    if (BAChartSuccess) {
      labels = [...BAChartSuccess?.beratAyam?.map((v, i) => i + 1)];
      data = [...BAChartSuccess?.beratAyam?.map((v) => v.berat)];
      setBALabels(labels);
      setBeratAyamR(BAChartSuccess?.rata_rata);
    }
    if (BAChart) {
      BAChartColor = BAChart.ctx.createLinearGradient(0, BAChart.chartArea.bottom, 0, BAChart.chartArea.top);
      BAChartColor.addColorStop(1, "#1C37BE");
      BAChartColor.addColorStop(0, "#ffffff");
      setBAData({
        labels: labels ? labels : range(1, 31),
        datasets: [
          {
            ...BAData.datasets[0],
            backgroundColor: BAChartColor,
            data: data ? data : BAData.datasets[0].data,
          },
        ],
      });
    }
  }, [BAChartRef.current, BAChartSuccess]);

  // use Effect hook for update chart berat ayam
  useEffect(() => {
    if (!isEmpty(filterKandang)) props.chartBeratAyam({ farm: filterKandang.value, bulan: BAChartFilter.bulan, tahun: BAChartFilter.tahun });
  }, [BAChartFilter]);

  return (
    <div className="c-card">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div className="c-card-title">Berat Ayam Rata-Rata</div>
        <DateRangePicker
          onChange={(e) => handleChangeBA(e)}
          selectsRange={false}
          placeholder={""}
          value={`${months[BAChartFilter?.bulan - 1]} ${BAChartFilter?.tahun}`}
          leftIcon={<Event style={{ fontSize: "25px", color: "#A5A5C0", marginTop: "5px" }} />}
          format={"M yyyy"}
          wrapperClass="date-picker-wrapper"
          groupClass="date-picker-group"
          showMonthYearPicker={true}
          wrapperStyle={{ width: "200px" }}
          selected={new Date(`${BAChartFilter?.tahun}-${BAChartFilter?.bulan}-01`)}
          isClearable={false}
        />
      </div>
      <div>
        <Chart ref={BAChartRef} type="line" labels={BALabels} data={BAData} options={beratAyamOpt} id="canvas" />
      </div>
      <div className="d-flex justify-content-between align-items-center chart-stat-wrapper">
        <div className="chart-stat-title">Berat Ayam Rata-Rata</div>
        <div className="chart-stat-value">{`${toPersen(beratAyamR)} kg`}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ listFarmDB, beratAyam }) => {
  return {
    filterKandang: getFilter(listFarmDB),
    BAChartSuccess: getChart(beratAyam),
    BAChartPending: getChartPending(beratAyam),
    BAChartError: getChartError(beratAyam),
    BAChartFilter: getChartFilter(beratAyam),
  };
};

export default connect(mapStateToProps, {
  chartBeratAyam,
  updateBAFilter,
})(Index);
