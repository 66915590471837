import React from 'react'
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from 'react';
import { connect } from 'react-redux';
import { listFeed, searchFeed } from '../../store/actions/feed';

import { 
  getFilters,
  getPageData,
  getSearchText
 } from '../../store/reducers/feed';

const SearchBoxFeed = (props) => {

  const { pageData, filters, searchText } = props;

  const [searchTextShow, setSearchTextShow] = useState(false);

  const handleSearchChange = (e) => {
    props.searchFeed(e.target.value.toLowerCase());
    props.listFeed({
      length: pageData.lengthItem,
      page: 1,
      search_text: e.target.value.toLowerCase(),
      grade_feed: filters.grade_feed.value,
    });
  };

  return (
    <>
       <div className={`searchbox-wrapper ${searchTextShow ? "searchbox-show" : ""}`}>
        <input
            type="text"
            name="search-text"
            placeholder="Masukkan kata kunci"
            onChange={handleSearchChange}
            defaultValue={searchText}
        />
        <IconButton  onClick={(e) => setSearchTextShow(!searchTextShow)}>
            <Search />
        </IconButton>
        </div>
    </>
  )
}

const mapStateToProps = ({ listFeed }) => {
  return {
    pageData: getPageData(listFeed),
    filters: getFilters(listFeed),
    searchText: getSearchText(listFeed),
  };
};

export default connect(mapStateToProps, { listFeed, searchFeed })(SearchBoxFeed);