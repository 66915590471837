import React from "react";
import { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  MenuItem,
  Menu,
  Fade,
  Divider,
  Button,
  Badge,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import { Container, Navbar } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PageFeed from "./PageFeed";
import SearchBoxFeed from "./SearchBoxFeed";

import ModalTambahFeed from "./ModalTambahFeed";
import ModalEditFeed from "./ModalEditFeed";
import ModalDeleteFeed from "./ModalDeleteFeed";
import ModalFilterFeed from "./ModalFilterFeed";

import { listFeed } from "../../store/actions/feed";
import {
  getListFeed,
  getListFeedPending,
  getListFeedError,
  getFilters,
  getPageData,
  getSearchText,
} from "../../store/reducers/feed";

const Index = (props) => {
  const { pageData, listFeedSuccess, listFeedPending, listFeedError, filters, searchText } = props;

  const [showTambahFeedModal, setShowTambahFeedModal] = useState(false);
  const [showModalEditFeed, setShowModalEditFeed] = useState(false);
  const [showModalDeleteFeed, setShowModalDeleteFeed] = useState(false);
  const [editData, setEditData] = useState(null);
  const [anchorEl, setAnchorEl] = useState([null, null, null, null, null, null, null, null, null, null]);
  const [popOpen, setPopOpen] = useState([false, false, false, false, false, false, false, false, false, false]);
  const [activePopMenu, setActivePopMenu] = useState(null);
  const [showModalFilterFeed, setShowModalFilterFeed] = useState(false);

  const handleTambahFeedButtonClick = () => {
    setShowTambahFeedModal(true);
  };
  const handleEditFeedButtonClick = () => {
    handleMoreButtonClose();
    setShowModalEditFeed(true);
  };
  const handleDeleteFeedButtonClick = () => {
    handleMoreButtonClose();
    setShowModalDeleteFeed(true);
  };
  const handleMoreButtonClick = (data, event, index) => {
    setEditData(data);
    let anchor = anchorEl;
    anchor[index] = event.currentTarget;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[index] = true;
    setPopOpen(temp);
    setActivePopMenu(index);
  };
  const handleMoreButtonClose = () => {
    let anchor = anchorEl;
    anchor[activePopMenu] = null;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[activePopMenu] = false;
    setPopOpen(temp);
    setActivePopMenu(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    props.listFeed({
      length: pageData.lengthItem,
      page: pageData.page,
      search_text: searchText,
      grade_feed: filters.grade_feed.value,
    });
  }, [popOpen, anchorEl, filters]);

  const feedLists = (data, error) => {
    if (!data || error) {
      return 
      <TableRow>
        <TableCell colSpan={5} align="center">
          Gagal mengambil data feed
        </TableCell>
      </TableRow>
    } return data.length < 1 ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Data Feed tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>{row.kode_feed}</TableCell>
          {row.name_feed.length > 20 ? (
            <TableCell>{row.name_feed.slice(0, 20) + "..."}</TableCell>
          ) : (
            <TableCell>{row.name_feed}</TableCell>
          )}
          <TableCell>{row.grade_feed}</TableCell>
          <TableCell align="center">
            <IconButton
              onClick={(e) => handleMoreButtonClick(row, e, i)}
              variant="contained"
              aria-controls={popOpen[i] ? id : undefined}
              aria-haspopup="true"
              aria-expanded={popOpen[i] ? "true" : undefined}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              elevation={1}
              id={popOpen[i] ? "simple-popover" : undefined}
              open={popOpen[i]}
              anchorEl={anchorEl[i]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleMoreButtonClose}
              TransitionComponent={Fade}>
              <MenuItem test={row.id} onClick={(e) => handleEditFeedButtonClick()} sx={styleMenuItem}>
                Edit
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem test={row.id} onClick={(e) => handleDeleteFeedButtonClick()} sx={styleMenuItem}>
                Hapus
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      {editData ? (
        <>
          <ModalEditFeed show={showModalEditFeed} setShow={setShowModalEditFeed} data={editData} />
          <ModalDeleteFeed show={showModalDeleteFeed} setShow={setShowModalDeleteFeed} data={editData} />
        </>
      ) : (
        ""
      )}
      <ModalTambahFeed show={showTambahFeedModal} setShow={setShowTambahFeedModal} />
      <ModalFilterFeed show={showModalFilterFeed} setShow={setShowModalFilterFeed} />
      <Navbar>
        <>
          <Navbar.Brand>
            <h1 className="judul-header">Feed Management</h1>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <SearchBoxFeed />

            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={(e) => setShowModalFilterFeed(!showModalFilterFeed)}>
              {filters.grade_feed.value ? <span className="dot"></span> : <></>}
              Filter
            </Button>
            <div className="custom-devider" />
            <Button
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "800",
                borderRadius: "24px",
                width: "163px",
                height: "48px",
                "&:hover": {
                  background: "#5269D9",
                  boxShadow: "0px 4px 14px rgba(28, 55, 190, 0.75)",
                  borderRadius: "24px",
                },
              }}
              onClick={handleTambahFeedButtonClick}>
              Tambah Feed
            </Button>
          </Navbar.Collapse>
        </>
      </Navbar>
      <Divider />
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>KODE FEED</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA FEED</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              GRADE FEED
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listFeedPending ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Mengambil data feed ...
              </TableCell>
            </TableRow>
          ) : (
            feedLists(listFeedSuccess, listFeedError)
          )}
        </TableBody>
      </Table>
      <PageFeed pageData={pageData} />
      <div style={{ marginBottom: "210px" }}></div>
    </Container>
  );
};

const mapStateToProps = ({ listFeed }) => {
  return {
    listFeedSuccess: getListFeed(listFeed),
    listFeedPending: getListFeedPending(listFeed),
    listFeedError: getListFeedError(listFeed),
    pageData: getPageData(listFeed),
    filters: getFilters(listFeed),
    searchText: getSearchText(listFeed),
  };
};

export default connect(mapStateToProps, { listFeed })(Index);
