import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ModalDelete from "../../../../components/report/ModalDelete";

export const ModalDeleteDoc = (props) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const handleDelete = () => {
    setDisableSubmit(true);
    props.setDoc(
      props.doc.map((item, index) => {
        if (index !== props.data.index) return item;

        if (item?.id) {
          return {
            ...item,
            action: "DELETE"
          };
        } else {
          return null;
        }
      }).filter(item => item !== null)
    );
    props.setError(props.error.filter((error, _index) => props.data.index !== _index));
    props.setShow(false);
    setTimeout(() => setDisableSubmit(false), 1000);
  };
  return <ModalDelete show={props.show} setShow={props.setShow} handleDelete={handleDelete} disableSubmit={disableSubmit} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteDoc);
