import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalEditFarm from "./ModalEditFarm";
import { listFarm, detailFarm, listKepKan, editFarm } from "../../store/actions/farm";
import { getListKepKan, getFilters, getPageData, getSearchText, getDetailFarmSuccess, getDetailFarmPending, getDetailFarmError } from "../../store/reducers/farm";
import { numberWithCommas } from "../../utils/constants";

function PageDetailFarm(props) {
  const history = useHistory();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editData, setEditData] = useState(null);

  const { detailFarmSuccess, detailFarmPending, detailFarmError, listKepKanSuccess, pageData, filters, searchText } = props;

  useEffect(() => {
    props.detailFarm(props.match.params.id);
  }, []);

  useEffect(() => {
    if (detailFarmSuccess) setEditData(detailFarmSuccess);
  }, [detailFarmSuccess]);

  return (
    <>
      {editData ? <ModalEditFarm show={showModalEdit} setShow={setShowModalEdit} data={editData} fromDetail={true} /> : null}
      {detailFarmPending ? (
        <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "50%" }}>
          Mengambil data kandang...
        </div>
      ) : detailFarmError ? (
        <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "50%" }}>
          Gagal mengambil data kandang...
        </div>
      ) : (
        <>
          <ListGroup className="cek" style={{ borderRadius: "12px" }}>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Nama Kandang</div>
                  <div className="col-md-8">{detailFarmSuccess == null ? "" : detailFarmSuccess.name}</div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Kepala Kandang</div>
                  <div className="col-md-8">{detailFarmSuccess?.kepala_kandang == null && detailFarmSuccess?.kepala_kandang == undefined ? "" : detailFarmSuccess.kepala_kandang.name}</div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Status Kandang</div>
                  <div className="col-md-8">{detailFarmSuccess == null ? "" : detailFarmSuccess.status_farm}</div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Kapasitas Kandang</div>
                  <div className="col-md-8">{detailFarmSuccess == null ? "" : numberWithCommas(String(detailFarmSuccess.kapasitas_kandang + " ekor"))}</div>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item style={{ padding: "1rem" }}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-extra-bold">Lokasi Kandang</div>
                  <div className="col-md-8">{detailFarmSuccess == null ? "" : detailFarmSuccess.lokasi_kandang}</div>
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex flex-row-reverse">
            <Button
              variant="contained"
              disableElevation
              className="btn-edit-farm"
              onClick={() => {
                setShowModalEdit(true);
              }}
            >
              Edit
            </Button>

            <Button variant="outlined" disableElevation className="btn-kembali-farm" onClick={(e) => history.goBack()}>
              Kembali
            </Button>
          </div>
        </>
      )}
    </>
  );
}

function mapStateToProps({ listFarm, listKepKan, detailFarm }) {
  return {
    detailFarmSuccess: getDetailFarmSuccess(detailFarm),
    detailFarmPending: getDetailFarmPending(detailFarm),
    detailFarmError: getDetailFarmError(detailFarm),
    listKepKanSuccess: getListKepKan(listKepKan),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
}

export default connect(mapStateToProps, { listFarm, detailFarm, listKepKan, editFarm })(withRouter(PageDetailFarm));
