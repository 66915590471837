import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  MenuItem,
  Menu,
  Fade,
  Divider,
  Button,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";

import { Container, Navbar } from "react-bootstrap";
import { connect } from "react-redux";

import ModalTambahDoc from "./ModalTambahDoc";
import ModalEditDoc from "./ModalEditDoc";
import ModalDeleteDoc from "./ModalDeleteDoc";
import ModalFilterDoc from "./ModalFilterDoc";
import PageDoc from "./PageDoc";
import SearchBoxDoc from "./SearchBoxDoc";

import { listDoc } from "../../store/actions/doc";
import {
  getListDoc,
  getListDocPending,
  getListDocError,
  getPageData,
  getFilters,
  getSearchText,
} from "../../store/reducers/doc";

const Index = (props) => {
  const { pageData, listDocSuccess, listDocPending, listDocError, filters, searchText } = props;

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalTambah, setShowModalTambah] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [editData, setEditData] = useState(null);
  const [anchorEl, setAnchorEl] = useState([null, null, null, null, null, null, null, null, null, null]);
  const [popOpen, setPopOpen] = useState([false, false, false, false, false, false, false, false, false, false]);
  const [activePopMenu, setActivePopMenu] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "more-popover" : undefined;

  const handleEditButtonClick = () => {
    handleMoreButtonClose();
    setShowModalEdit(true);
  };
  const handleDeleteButtonClick = () => {
    handleMoreButtonClose();
    setShowModalDelete(true);
  };
  const handleMoreButtonClick = (data, event, index) => {
    setEditData(data);
    let anchor = anchorEl;
    anchor[index] = event.currentTarget;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[index] = true;
    setPopOpen(temp);
    setActivePopMenu(index);
  };
  const handleMoreButtonClose = () => {
    let anchor = anchorEl;
    anchor[activePopMenu] = null;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[activePopMenu] = false;
    setPopOpen(temp);
    setActivePopMenu(null);
  };

  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    props.listDoc({
      length: pageData.lengthItem,
      page: pageData.page,
      search_text: searchText,
      grade_doc: filters.grade_doc.value,
    });
  }, [filters]);

  const docLists = (data, error) => {
    if (!data || error) {
      return 
      <TableRow>
        <TableCell colSpan={5} align="center">
          Gagal mengambil data DOC
        </TableCell>
      </TableRow>
    }
    return data?.length === 0 ? (
      <TableRow>
        <TableCell colSpan={5} align="center">
          Data DOC tidak ditemukan
        </TableCell>
      </TableRow>
    ) : (
      data.map((row, i) => (
        <TableRow key={i}>
          <TableCell align="center">{i + 1}</TableCell>
          <TableCell>{row.kode_doc}</TableCell>
          {row.name_doc.length > 20 ? (
            <TableCell>{row.name_doc.slice(0, 20) + "..."}</TableCell>
          ) : (
            <TableCell>{row.name_doc}</TableCell>
          )}
          <TableCell>{row.grade_doc}</TableCell>
          <TableCell align="center">
            <IconButton
              onClick={(e) => handleMoreButtonClick(row, e, i)}
              variant="contained"
              aria-controls={popOpen[i] ? id : undefined}
              aria-haspopup="true"
              aria-expanded={popOpen[i] ? "true" : undefined}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              elevation={1}
              id={popOpen[i] ? "simple-popover" : undefined}
              open={popOpen[i]}
              anchorEl={anchorEl[i]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleMoreButtonClose}
              TransitionComponent={Fade}>
              <MenuItem onClick={(e) => handleEditButtonClick()} sx={styleMenuItem}>
                Edit
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem onClick={(e) => handleDeleteButtonClick()} sx={styleMenuItem}>
                Hapus
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <Container>
      <ModalTambahDoc show={showModalTambah} setShow={setShowModalTambah} />
      <ModalFilterDoc show={showModalFilter} setShow={setShowModalFilter} />
      {editData ? (
        <>
          <ModalEditDoc data={editData} show={showModalEdit} setShow={setShowModalEdit} />
          <ModalDeleteDoc data={editData} show={showModalDelete} setShow={setShowModalDelete} />
        </>
      ) : (
        ""
      )}
      <Navbar>
        <Container>
          <Navbar.Brand>
            <h1 className="judul-header">DOC Management</h1>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <SearchBoxDoc />
            <Button
              color="secondary"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                color: "black",
                fontWeight: "800",
              }}
              size="large"
              className={`filters`}
              onClick={() => setShowModalFilter(true)}>
              {filters.grade_doc.value ? <span className="dot"></span> : ""}
              Filter
            </Button>
            <div className="custom-devider" />
            <Button
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{
                borderRadius: 28,
                paddingY: "10px",
                textTransform: "capitalize",
                fontWeight: "800",
                borderRadius: "24px",
                width: "163px",
                height: "48px",
                "&:hover": {
                  background: "#5269D9",
                  boxShadow: "0px 4px 14px rgba(28, 55, 190, 0.75)",
                  borderRadius: "24px",
                },
              }}
              onClick={() => setShowModalTambah(true)}>
              Tambah DOC
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Divider />
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>KODE DOC</TableCell>
            <TableCell sx={{ fontWeight: "800" }}>NAMA DOC</TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              GRADE DOC
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listDocPending ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                Mengambil data DOC ...
              </TableCell>
            </TableRow>
          ) : (
            docLists(listDocSuccess, listDocError)
          )}
        </TableBody>
      </Table>
      <PageDoc pageData={pageData} />
      <div style={{ marginBottom: "210px" }}></div>
    </Container>
  );
};

const mapStateToProps = ({ listDoc }) => {
  return {
    listDocSuccess: getListDoc(listDoc),
    listDocPending: getListDocPending(listDoc),
    listDocError: getListDocError(listDoc),
    pageData: getPageData(listDoc),
    filters: getFilters(listDoc),
    searchText: getSearchText(listDoc),
  };
};

export default connect(mapStateToProps, { listDoc })(Index);
