import {
  UPDATE_USER_PAGE,
  LIST_USER_PENDING,
  LIST_USER_SUCCESS,
  LIST_USER_ERROR,
  EDIT_USER_PENDING,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DETAIL_USER_SUCCESS,
  DETAIL_USER_PENDING,
  DETAIL_USER_ERROR,
  DELETE_USER_SUCCESS,
  DELETE_USER_PENDING,
  DELETE_USER_ERROR,
  UPDATE_FILTER_USER,
  UPDATE_SEARCH_USER,
  TAMBAH_USER_SUCCESS,
  TAMBAH_USER_PENDING,
  TAMBAH_USER_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_ERROR,
} from "../../actions/user";

const initialState = {
  listUsers: [],
  pending: false,
  error: null,
  pageData: {
    total: null,
    lengthItem: 10,
    page: 1,
  },
  filters: {
    role: {},
    status: {},
  },
  search_text: "",
};

export const listUser = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case LIST_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        listUsers: action.data.data,
        pageData: {
          ...state.pageData,
          total: action.data.total,
        },
      };
    case LIST_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPDATE_USER_PAGE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          page: action.data,
        },
      };
    case UPDATE_FILTER_USER:
      return {
        ...state,
        filters: {
          role: action.data.role,
          status: action.data.status,
        },
      };
    case UPDATE_SEARCH_USER:
      return {
        ...state,
        search_text: action.data,
      };
    default:
      return state;
  }
};

export const tambahUser = (state = initialState, action) => {
  switch (action.type) {
    case TAMBAH_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case TAMBAH_USER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case TAMBAH_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const editUser = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case EDIT_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const detailUser = (
  state = { ...initialState, detailUser: null },
  action
) => {
  switch (action.type) {
    case DETAIL_USER_PENDING:
      return {
        ...state,
        pending: false,
      };
    case DETAIL_USER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DETAIL_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export function deleteUser(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteUser: action.data,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function resetPassword(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        deleteUser: action.data,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const getListUser = (state) => state.listUsers;
export const getPageData = (state) => state.pageData;
export const getFilters = (state) => state.filters;
export const getSearchText = (state) => state.search_text;
export const getDetailUser = (state) => state.detailUser;
export const getListUserPending = (state) => state.pending;
export const getAddUserPending = (state) => state.pending;
export const getListUserError = (state) => state.error;
export const getAddUserError = (state) => state.error;
export const getDeleteUserPending = (state) => state.pending;
export const getDeleteUser = (state) => state.deleteUser;
export const getDeleteUserError = (state) => state.error;
export const getResetPasswordPending = (state) => state.pending;
export const getResetPassword = (state) => state.resetPassword;
export const getResetPasswordError = (state) => state.error;
