import React from 'react'
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { connect } from 'react-redux';

import { listFarm } from '../../store/actions/farm';
import {
  getFilters,
  getPageData,
  getSearchText
} from '../../store/reducers/farm';

const PageFarm = (props) => {
  const { pageData, filters, searchText } = props;

  const change = (page) => {
    if (page) {
      props.listFarm({
        length: pageData.lengthItem,
        page: page,
        search_text: searchText,
        kepala_kandang: filters.kepala_kandang.value,
        status_farm: filters.status_farm.value,

      });
    }
  }

  const getPage = (length, total, activePage) => {
    const totalPage = Math.ceil(total / length);
    const activePageLessthanThree = activePage < 3 ? 1 : 2;
    const minusOfStartListPage = activePage === totalPage ? activePageLessthanThree : 1;
    const startListPage = activePage > 1 ? activePage - minusOfStartListPage : 1;
    const plusActivePage = activePage === 1 ? 2 : 1;
    const plusOfEndListPage = activePage + 1 > totalPage ? activePage : activePage + plusActivePage;
    const endListPage = totalPage <= 3 ? totalPage : plusOfEndListPage;
    const listPageNum = [];
    for (let i = startListPage; i <= endListPage; i++) {
      listPageNum.push(i);
    }
    return listPageNum.map((v, k) => {
      return (
        <div
          key={k}
          className={`page-item page-num ${activePage === v ? "active" : ""}`}
          onClick={(e) => change(v)}
        >
          {v}
        </div>
      );
    });
  };

  return (
    <>
      <div className="pagination-wrapper">
        <div className="data-count-info">
          {pageData.total < pageData.lengthItem
            ? pageData.total
            : pageData.lengthItem}{" "}
          dari {pageData.total ? pageData.total : 0} data
        </div>
        <div className="page-wrapper">
          <div
            className={`page-item page-btn ${pageData.page === 1 ? "disabled" : ""
              }`}
            onClick={(e) =>
              change(pageData.page === 1 ? undefined : pageData.page - 1)
            }
          >
            <ArrowBack />
          </div>

          {getPage(pageData.lengthItem, pageData.total, pageData.page)}

          <div
            className={`page-item page-btn ${pageData.page === Math.ceil(pageData.total / pageData.lengthItem)
                ? "disabled"
                : ""
              }`}
            onClick={(e) =>
              change(
                pageData.page === Math.ceil(pageData.total / pageData.lengthItem)
                  ? undefined
                  : pageData.page + 1
              )
            }
          >
            <ArrowForward />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ listFarm }) => {
  return {
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, { listFarm })(PageFarm);
