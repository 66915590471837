import { CHANGE_PROFILE_OPTION } from "../../actions/profile";

const initialState = {
  profileOption: false
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PROFILE_OPTION:
      return {
        ...state,
        profileOption: action.data
      }
    default:
      return state;
  }
};

export default profile;
