import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from '@mui/material';
import SelectComponent from "../../components/commons/forms/select";
import Input from "../../components/commons/forms/authentication/input";
import NumericFormat from "react-number-format";
import TextArea from "../../components/commons/forms/textarea";
import { connect } from "react-redux";
import { listKepKan } from '../../store/actions/farm';
import { listFarm, tambahFarm } from '../../store/actions/farm';

import { getListKepKan } from '../../store/reducers/farm';
import {
  getFilters,
  getPageData,
  getSearchText
} from '../../store/reducers/farm';

const ModalTambahFarm = (props) => {

  const [kapasitas_kandang, setKapasitas] = useState();
  const [roleNameKepkan, setRoleNameKepkan] = useState("");
  const [kepala_kandang, setKepalaKandang] = useState("");
  const [lokasi_kandang, setLokasiKandang] = useState("");
  const [name, setName] = useState("");
  const [status_farm, setStatusFarm] = useState("");


  const [errorName, setErrorName] = useState("");
  const [errorKepalaKandang, setErrorKepalaKandang] = useState("");
  const [errorStatusFarm, setErrorStatusFarm] = useState("");
  const [errorKapasitas, setErrorKapasitas] = useState("");
  const [errorLokasiKandang, setErrorLokasiKandang] = useState("");

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

  const statusOptions = [
    { value: "Kosong", label: "Kosong" },
    { value: "Persiapan Kandang", label: "Persiapan Kandang" },
    { value: "Cuci Kandang", label: "Cuci Kandang" },
    { value: "Chick In", label: "Chick In" },
    { value: "Panen", label: "Panen" },
  ];
  const { listKepKanSuccess, pageData, filters, searchText } = props;
  useEffect(() => {
    props.listKepKan();
  }, []);

  const handleInput = (e, set, error, customRule = false) => {
    let value;
    let label = true;

    if (e) value = e.target ? e.target.value : e.value;
    else {
      value = ``;
      label = false;
    }

    const rule = customRule ? customRule : e.target.getAttribute(`rule`);
    if (label) setRoleNameKepkan(e.label ? e.label : roleNameKepkan);
    if (!value) {
      error(`${rule} wajib diisi`);
    } else {
      error(``);
    }
    set(value);
  };

  const handleError = (data) => {
    data.name ? setErrorName("") : setErrorName("Nama Kandang wajib diisi");
    data.kepala_kandang
      ? setErrorKepalaKandang("")
      : setErrorKepalaKandang("Kepala Kandang wajib diisi");
    data.status_farm ? setErrorStatusFarm("") : setErrorStatusFarm("Status Kandang wajib diisi");
    data.kapasitas_kandang ? setErrorKapasitas("") : setErrorKapasitas("Kapasitas Kandang wajib diisi");
    data.lokasi_kandang ? setErrorLokasiKandang("") : setErrorLokasiKandang("Lokasi Kandang wajib diisi");
  };

  const handleButtonBatal = (e) => {
    setDefaultState();
    props.setShow(false);
  };
  const submitForm = () => {

    setDisableBtnSubmit(true);
    const data = {
      kapasitas_kandang,
      kepala_kandang,
      lokasi_kandang,
      name,
      status_farm,
    };

    handleError(data);

    for (var key in data) {
      if (data[key] === "") {
        setDisableBtnSubmit(false);
        return;
      }
    }

    props.tambahFarm(data, successTambah, failedTambah);
  };

  const failedTambah = () => {
    setDisableBtnSubmit(false);
  };

  const successTambah = () => {
    setTimeout(() => {
      props.setShow(false);
      setDefaultState();
      props.listFarm({
        length: pageData.lengthItem,
        page: 1,
        search_text: searchText,
        kepala_kandang: filters.kepala_kandang.value,
        status_farm: filters.status_farm.value
      });
      setDisableBtnSubmit(false);
    }, 1000);
  }

  const setDefaultState = () => {
    setName("");
    setRoleNameKepkan("");
    setKepalaKandang("");
    setStatusFarm("");
    setKapasitas(0);
    setLokasiKandang("");

    setErrorName("");
    setErrorKepalaKandang("");
    setErrorLokasiKandang("");
    setErrorStatusFarm("");
    setErrorKapasitas("");
  };



  return (
    <>
      <Modal
        size={"md"}
        onHide={handleButtonBatal}
        show={props.show}
        centered>
        <ModalHeader closeButton>Tambah Kandang</ModalHeader>
        <ModalBody>
          <Input
            label={"Nama Kandang"}
            placeholder={"Masukkan nama kandang"}
            onChange={(e) => handleInput(e, setName, setErrorName)}
            value={name || ""}
            rule={"Nama Kandang"}
            error={errorName}
          />

          <SelectComponent
            options={listKepKanSuccess ? listKepKanSuccess : {}}
            placeholder={"Masukkan kepala kandang"}
            label={"Kepala Kandang"}
            isAsync={true}
            asyncUrl={"/v1/master_data/user?role_code=kepalakandang&status=Aktif"}
            onChange={(e) => handleInput(e, setKepalaKandang, setErrorKepalaKandang, "Kepala Kandang")}
            isError={errorKepalaKandang !== ``}
            error={errorKepalaKandang}
          />
          <div className='mb-3'></div>
          <SelectComponent
            options={statusOptions}
            placeholder={"Masukkan status kandang"}
            label={"Status Kandang"}
            error={errorStatusFarm}
            isError={errorStatusFarm !== ``}
            onChange={(e) => handleInput(e, setStatusFarm, setErrorStatusFarm, "Status Kandang")}
          />

          <NumericFormat
            label={"Kapasitas Kandang"}
            customInput={Input}
            placeholder={"Masukkan kapasitas kandang"}
            thousandSeparator="."
            decimalSeparator=","
            onChange={(e) => handleInput(e, setKapasitas, setErrorKapasitas)}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            rule={`Kapasitas Kandang`}
            error={errorKapasitas}
          />

          <TextArea
            label={"Lokasi Kandang"}
            placeholder={"Masukkan lokasi kandang"}
            onChange={(e) => handleInput(e, setLokasiKandang, setErrorLokasiKandang, "Lokasi Kandang")}
            error={errorLokasiKandang}
            isError={errorLokasiKandang !== ``}
          />
        </ModalBody>
        <ModalFooter>

          <Button variant="outlined" disableElevation className="btn-btl-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={handleButtonBatal}
          >
            Batal
          </Button>

          <Button variant="contained" disableElevation className="btn-simpan-ubah-pass"
            sx={{
              paddingY: "10px",
            }}
            onClick={(e) => submitForm()}
            disabled={disableBtnSubmit}
          >
            Simpan
          </Button>

        </ModalFooter>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ listFarm, listKepKan }) => {
  return {
    listKepKanSuccess: getListKepKan(listKepKan),
    pageData: getPageData(listFarm),
    filters: getFilters(listFarm),
    searchText: getSearchText(listFarm),
  };
};

export default connect(mapStateToProps, { listKepKan, listFarm, tambahFarm })(ModalTambahFarm);