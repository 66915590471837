import { width } from "@mui/system";
import { useEffect, useState } from "react";


const Index = (props) => {
    // load props data
    const { color , num , total , name } = props;

    const [width,setWidth] = useState("0%");

    useEffect(() => {
        if(num != 0 && total != 0){
            setWidth(`${((num / total) * 100).toFixed(2)}%`)
        }
    } , [color , num , total])

    return (
        <div className="stat-item">
            <div className="d-flex justify-content-between align-items-center">
                <span className="status-kandang-title">{name ? name : "?"}</span>
                <span className="status-kandang-value">{num ? num : 0}</span>
            </div>
            <div className="progress-bar-wrapper">
                <div></div>
                <div style={{width: width , backgroundColor: color}}></div>
            </div>
        </div>
    );
}

export default Index;
