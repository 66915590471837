import React from "react";

const AccordionListGroup = ({ backgroundColor, children }) => {
  return (
    <div className="accordion-list-custom" style={backgroundColor ? { background: backgroundColor } : {}}>
      {children}
    </div>
  );
};

export default AccordionListGroup;
