import { actionSuccess, actionError, actionPending } from "../../action_types";
import API, { download, cancelToken } from "../../API";
import { toastSuccess, toastError } from "../../../../components/toast";

export const UPDATE_REPORT_PAGE = `UPDATE_REPORT_PAGE`;
export const LIST_REPORT_PENDING = `LIST_REPORT_PENDING`;
export const LIST_REPORT_SUCCESS = `LIST_REPORT_SUCCESS`;
export const LIST_REPORT_ERROR = `LIST_REPORT_ERROR`;
export const UPDATE_FILTER_REPORT = `UPDATE_FILTER_REPORT`;
export const DETAIL_REPORT_PENDING = `DETAIL_REPORT_PENDING`;
export const DETAIL_REPORT_SUCCESS = `DETAIL_REPORT_SUCCESS`;
export const DETAIL_REPORT_ERROR = `DETAIL_REPORT_ERROR`;
export const LIST_FEED_PENDING = `LIST_FEED_PENDING`;
export const LIST_FEED_SUCCESS = `LIST_FEED_SUCCESS`;
export const LIST_FEED_ERROR = `LIST_FEED_ERROR`;
export const LIST_DOC_PENDING = `LIST_DOC_PENDING`;
export const LIST_DOC_SUCCESS = `LIST_DOC_SUCCESS`;
export const LIST_DOC_ERROR = `LIST_DOC_ERROR`;
export const CHECK_UMUR_PENDING = `CHECK_UMUR_PENDING`;
export const CHECK_UMUR_SUCCESS = `CHECK_UMUR_SUCCESS`;
export const CHECK_UMUR_ERROR = `CHECK_UMUR_ERROR`;
export const TAMBAH_REPORT_PENDING = `TAMBAH_REPORT_PENDING`;
export const TAMBAH_REPORT_SUCCESS = `TAMBAH_REPORT_SUCCESS`;
export const TAMBAH_REPORT_ERROR = `TAMBAH_REPORT_ERROR`;
export const KUMULATIF_PANEN_PENDING = `KUMULATIF_PANEN_PENDING`;
export const KUMULATIF_PANEN_SUCCESS = `KUMULATIF_PANEN_SUCCESS`;
export const KUMULATIF_PANEN_ERROR = `KUMULATIF_PANEN_ERROR`;
export const EDIT_REPORT_PENDING = `EDIT_REPORT_PENDING`;
export const EDIT_REPORT_SUCCESS = `EDIT_REPORT_SUCCESS`;
export const EDIT_REPORT_ERROR = `EDIT_REPORT_ERROR`;
export const DELETE_OBAT_PENDING = `DELETE_OBAT_PENDING`;
export const DELETE_OBAT_SUCCESS = `DELETE_OBAT_SUCCESS`;
export const DELETE_OBAT_ERROR = `DELETE_OBAT_ERROR`;
export const DELETE_PENJUALAN_PENDING = `DELETE_PENJUALAN_PENDING`;
export const DELETE_PENJUALAN_SUCCESS = `DELETE_PENJUALAN_SUCCESS`;
export const DELETE_PENJUALAN_ERROR = `DELETE_PENJUALAN_ERROR`;
export const CHECK_LAST_REPORT_PENDING = `CHECK_LAST_REPORT_PENDING`;
export const CHECK_LAST_REPORT_SUCCES = `CHECK_LAST_REPORT_SUCCES`;
export const CHECK_LAST_REPORT_ERROR = `CHECK_LAST_REPORT_ERROR`;
export const KUMULATIF_CHICK_IN_PENDING = `KUMULATIF_CHICK_IN_PENDING`;
export const KUMULATIF_CHICK_IN_SUCCES = `KUMULATIF_CHICK_IN_SUCCES`;
export const KUMULATIF_CHICK_IN_ERROR = `KUMULATIF_CHICK_IN_ERROR`;
//ini untuk new formula
export const POPULASI_CHICK_IN_PENDING = `POPULASI_CHICK_IN_PENDING`;
export const POPULASI_CHICK_IN_SUCCESS = `POPULASI_CHICK_IN_SUCCESS`;
export const POPULASI_CHICK_IN_ERROR = `POPULASI_CHICK_IN_ERROR`;
export const DOWNLOAD_REPORT_PENDING = `DOWNLOAD_REPORT_PENDING`;
export const DOWNLOAD_REPORT_SUCCESS = `DOWNLOAD_REPORT_SUCCESS`;
export const DOWNLOAD_REPORT_ERROR = `DOWNLOAD_REPORT_ERROR`;
export const VIEW_FOTO_PENDING = `VIEW_FOTO_PENDING`;
export const VIEW_FOTO_SUCCESS = `VIEW_FOTO_SUCCESS`;
export const VIEW_FOTO_ERROR = `VIEW_FOTO_ERROR`;
export const VIEW_FOTOS_PENDING = `VIEW_FOTOS_PENDING`;
export const VIEW_FOTOS_SUCCESS = `VIEW_FOTOS_SUCCESS`;
export const VIEW_FOTOS_ERROR = `VIEW_FOTOS_ERROR`;
export const DOWNLOAD_FOTO_PENDING = `DOWNLOAD_FOTO_PENDING`;
export const DOWNLOAD_FOTO_SUCCESS = `DOWNLOAD_FOTO_SUCCESS`;
export const DOWNLOAD_FOTO_ERROR = `DOWNLOAD_FOTO_ERROR`;

export let cancelRequestFoto;

const REPORT_URL = `/v1/master_data/report`;
const REPORT_V2_URL = `/v2/master_data/report`;
const DETAIL_REPORT_URL = `/v2/master_data/report/detail`;
const FEED_URL = `/v1/master_data/feed`;
const DOC_URL = `/v1/master_data/doc`;
const CHECK_UMUR_URL = `/v1/master_data/report/check-umur`;
const KUMULATIF_CHICK_IN_URL = "/v1/master_data/report/komulatif-chick-in";
const POPULASI_CHICK_IN_URL = "/v1/master_data/report/populasi-chick-in";

const VIEW_FOTO_URL = (folder, file) => `/v1/file/${folder}/${file}/view`;
const DOWNLOAD_FOTO_URL = (folder, file) => `/v1/file/${folder}/${file}`;
const DOWNLOAD_REPORT_URL = "/v1/file/report-to-excel";
const CHECK_KUMULATIF_URL = `/v1/master_data/report/komulatif-panen`;
const DELETE_OBAT_URL = `/v1/master_data/obat`;
const DELETE_PENJUALAN_URL = `/v1/master_data/penjualan`;
const CHECK_LAST_REPORT_URL = `/v1/master_data/report/check`;

export const listReport =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_REPORT_PENDING));
    API.get(REPORT_URL, { params: param })
      .then((res) => {
        dispatch(actionSuccess(LIST_REPORT_SUCCESS, res.data));
        if (param.page) {
          dispatch(actionSuccess(UPDATE_REPORT_PAGE, param.page));
        }
      })
      .catch((error) => {
        dispatch(actionError(LIST_REPORT_ERROR, error));
        toastError(error);
      });
  };

export const filterReport =
  (param = {}) =>
  (dispatch) => {
    const tanggal = param.tanggal ? param.tanggal : "";
    const status = param.status ? param.status : {};
    dispatch(actionSuccess(UPDATE_FILTER_REPORT, { tanggal, status }));
  };

export const detailReport =
  (id = null) =>
  (dispatch) => {
    dispatch(actionSuccess(DETAIL_REPORT_PENDING));
    API.get(DETAIL_REPORT_URL, { params: { id } })
      .then((res) => {
        dispatch(actionSuccess(DETAIL_REPORT_SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(DETAIL_REPORT_ERROR, error));
      });
  };

export const FeedReport =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_FEED_PENDING));
    API.get(FEED_URL, { params: param })
      .then((res) => {
        const FeedReport = [];
        res.data.data.map((v) => {
          FeedReport.push({
            value: v.id,
            label: v.name_feed,
            kode: v.kode_feed,
          });
        });
        dispatch(actionSuccess(LIST_FEED_SUCCESS, FeedReport));
      })
      .catch((error) => {
        dispatch(actionError(LIST_FEED_ERROR, error));
      });
  };

export const DocReport =
  (param = {}) =>
  (dispatch) => {
    dispatch(actionPending(LIST_DOC_PENDING));
    API.get(DOC_URL, { params: param })
      .then((res) => {
        const DocReport = [];
        res.data.data.map((v) => {
          DocReport.push({
            value: v.id,
            label: v.name_doc,
          });
        });
        dispatch(actionSuccess(LIST_DOC_SUCCESS, DocReport));
      })
      .catch((error) => {
        dispatch(actionError(LIST_DOC_ERROR, error));
      });
  };

export const CheckUmur =
  (id = null) =>
  (dispatch) => {
    dispatch(actionPending(CHECK_UMUR_PENDING));
    API.get(CHECK_UMUR_URL, { params: { id } })
      .then((res) => {
        dispatch(actionSuccess(CHECK_UMUR_SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(CHECK_UMUR_ERROR, error));
      });
  };

export const tambahReport =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(TAMBAH_REPORT_PENDING));
    API.post(REPORT_V2_URL, param)
      .then((res) => {
        dispatch(actionSuccess(TAMBAH_REPORT_SUCCESS, res.data.data));
        toastSuccess(res.data.message);

        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(TAMBAH_REPORT_ERROR, error));
        toastError(error, "");
        if (callbackFailed) callbackFailed();
      });
  };

export const CheckKumulatif =
  (id = null, id_report = null) =>
  (dispatch) => {
    dispatch(actionSuccess(KUMULATIF_PANEN_PENDING));
    API.get(CHECK_KUMULATIF_URL, { params: { id, id_report } })
      .then((res) => {
        dispatch(actionSuccess(KUMULATIF_PANEN_SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(KUMULATIF_PANEN_ERROR, error));
      });
  };

export const viewFoto =
  (foto = {}) =>
  (dispatch) => {
    dispatch(actionPending(VIEW_FOTO_PENDING));
    if (foto) {
      API.get(VIEW_FOTO_URL("penjualan", foto), { responseType: "blob", params: { fileName: foto, folderName: "penjualan" }, cancelToken: new cancelToken((c) => (cancelRequestFoto = c)) })
        .then((res) => {
          dispatch(actionSuccess(VIEW_FOTO_SUCCESS, URL.createObjectURL(res.data)));
        })
        .catch((error) => {
          dispatch(actionError(VIEW_FOTO_ERROR, error));
        });
    } else {
      dispatch(actionSuccess(VIEW_FOTO_SUCCESS, ""));
    }
  };

export const previewPhotos =
  (fotos = {}) =>
  (dispatch) => {
    dispatch(actionPending(VIEW_FOTOS_PENDING));
    Promise.all(
      fotos.map((foto) => {
        if (foto) return API.get(VIEW_FOTO_URL("penjualan", foto), { responseType: "blob", params: { folderName: "penjualan", fileName: foto } });
        else return null;
      })
    ).then((result) => {
      const _result = result.map((val) => val?.data);
      dispatch(actionSuccess(VIEW_FOTOS_SUCCESS, _result));
    });
  };

export const downloadFoto =
  (foto = {}, callbackSuccess) =>
  (dispatch) => {
    dispatch(actionPending(DOWNLOAD_FOTO_PENDING));
    if (foto) {
      API.get(DOWNLOAD_FOTO_URL("penjualan", foto), { responseType: "blob", params: { fileName: foto, folderName: "penjualan" } })
        .then((res) => {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", foto);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          dispatch(actionSuccess(DOWNLOAD_FOTO_SUCCESS));
          if (callbackSuccess) callbackSuccess();
        })
        .catch((error) => {
          dispatch(actionError(DOWNLOAD_FOTO_ERROR, error));
          toastError(error);
        });
    } else {
      dispatch(actionSuccess(DOWNLOAD_FOTO_SUCCESS, ""));
    }
  };

export const editReport =
  (param = {}, callbackSuccess, callbackFailed) =>
  (dispatch) => {
    dispatch(actionPending(EDIT_REPORT_PENDING));
    API.put(REPORT_V2_URL, param)
      .then((res) => {
        dispatch(actionSuccess(EDIT_REPORT_SUCCESS, res.data.data));
        toastSuccess(res.data.message);
        if (callbackSuccess) callbackSuccess();
      })
      .catch((error) => {
        dispatch(actionError(EDIT_REPORT_ERROR, error));
        toastError(error);
        if (callbackFailed) callbackFailed();
      });
  };

export const deleteObat =
  (id_obat = null) =>
  (dispatch) => {
    dispatch(actionPending(DELETE_OBAT_PENDING));
    API.delete(DELETE_OBAT_URL, { params: { id: id_obat } })
      .then((res) => {
        dispatch(actionSuccess(DELETE_OBAT_SUCCESS));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DELETE_OBAT_ERROR, error));
        toastError(error);
      });
  };

export const deletePenjualan =
  (id_penjualan = null) =>
  (dispatch) => {
    dispatch(actionPending(DELETE_PENJUALAN_PENDING));
    API.delete(DELETE_PENJUALAN_URL, { params: { id: id_penjualan } })
      .then((res) => {
        dispatch(actionSuccess(DELETE_PENJUALAN_SUCCESS));
        toastSuccess(res.data.message);
      })
      .catch((error) => {
        dispatch(actionError(DELETE_PENJUALAN_ERROR, error));
        toastError(error);
      });
  };

export const CheckLastReport =
  (id = null) =>
  (dispatch) => {
    dispatch(actionPending(CHECK_LAST_REPORT_PENDING));
    API.get(CHECK_LAST_REPORT_URL, { params: { id } })
      .then((res) => {
        dispatch(actionSuccess(CHECK_LAST_REPORT_SUCCES, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(CHECK_LAST_REPORT_ERROR, error));
      });
  };

export const KumulatifChickIn =
  (id = null, id_report = null) =>
  (dispatch) => {
    dispatch(actionPending(KUMULATIF_CHICK_IN_PENDING));
    API.get(KUMULATIF_CHICK_IN_URL, { params: { id, id_report } })
      .then((res) => {
        dispatch(actionSuccess(KUMULATIF_CHICK_IN_SUCCES, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(KUMULATIF_CHICK_IN_ERROR, error));
      });
  };

// ini untuk new formula
export const populasiChickIn =
  (id_kandang = null, id_report = null) =>
  (dispatch) => {
    dispatch(actionPending(POPULASI_CHICK_IN_PENDING));
    API.get(POPULASI_CHICK_IN_URL, { params: { id: id_kandang, id_report } })
      .then((res) => {
        dispatch(actionSuccess(POPULASI_CHICK_IN_SUCCESS, res.data.data));
      })
      .catch((error) => {
        dispatch(actionError(POPULASI_CHICK_IN_ERROR, error));
      });
  };

export const downloadReport = (farm, start, end) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  download
    .post(DOWNLOAD_REPORT_URL, { farm, start, end })
    .then((res) => {
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS));
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Laporan ${start}-${end}.xlsx`);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((error) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR, error));
      toastError("Gagal mengunduh report");
    });
};
