import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Button } from "@mui/material";
import { getFilters, getLastReport } from "../../../store/reducers/farm/report";
import { listReport, CheckLastReport } from "../../../store/actions/farm/report";
import AddIcon from "@mui/icons-material/Add";
import { toastWarning } from "../../../components/toast";
import { convertDate } from "../../../utils/constants";
import ModalFilter from "./ModalFilterReport";
import ModalUnduhReport from "./ModalUnduhReport";

export const HeaderDaftarReport = (props) => {
  const { filters, lastReport } = props;
  const history = useHistory();
  const id_farm = props.match.params.id;
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalUnduhReport, setShowModalUnduhReport] = useState(false);

  const buttonStyle = {
    borderRadius: 28,
    paddingY: "10px",
    textTransform: "capitalize",
    fontWeight: "800",
  };

  const dateFormat = (date) => {
    const newDate = new Date(date);
    const hari = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    const bulan = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"][newDate.getMonth()];
    return hari + " " + bulan + " " + newDate.getFullYear();
  };

  const handleAddButton = () => {
    let todayDate = convertDate(new Date()).split("/").reverse().join("-");
    let lastReportDate = convertDate(new Date(lastReport.date)).split("/").reverse().join("-");
    if (lastReport.status === false) {
      toastWarning("Daily report untuk hari ini telah terisi, silahkan menunggu pengisian di hari berikutnya");
    } else if (lastReportDate < todayDate) {
      toastWarning(
        `Daily Report Kandang ${lastReport.nama_kandang} terlewat! 
                Yuk isi kembali daily report kandang ${lastReport.nama_kandang} 
                yang terlewat pada tanggal ${dateFormat(lastReportDate)}`,
        {
          onClose: (e) => history.push({ pathname: `/farmmanagement/${id_farm}/report/tambah` }),
        }
      );
    } else {
      history.push({
        pathname: `/farmmanagement/${id_farm}/report/tambah`,
      });
    }
  };

  const handleUnduhReportClick = () => {
    setShowModalUnduhReport(true);
  };

  useEffect(() => {
    props.CheckLastReport(id_farm);
  }, []);

  return (
    <div className="d-flex justify-content-end">
      <ModalUnduhReport
        show={showModalUnduhReport}
        setShow={setShowModalUnduhReport}
        id_farm={id_farm}
        minDate={lastReport?.first_report}
        maxDate={lastReport?.date}
        checkTodayReport={lastReport?.status}
      />
      <ModalFilter show={showModalFilter} setShow={setShowModalFilter} id_farm={id_farm} />
      <Button color="secondary" variant="outlined" disableElevation sx={buttonStyle} size="large" className={`filters`} onClick={() => setShowModalFilter(true)}>
        {filters.tanggal || filters.status.value ? <span className="dot"></span> : ``}
        Filter
      </Button>
      <Button
        color="secondary"
        variant="outlined"
        disableElevation
        sx={{
          ...buttonStyle,
          marginLeft: "12px",
          width: "inherit !important",
        }}
        size="large"
        className={`filters`}
        onClick={handleUnduhReportClick}
      >
        Unduh Report
      </Button>
      <div className="custom-devider" />
      <Button
        color="primary"
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        sx={{
          ...buttonStyle,
          width: "163px",
          height: "48px",
          "&:hover": {
            background: "#5269D9",
            boxShadow: "0px 4px 14px rgba(28, 55, 190, 0.75)",
            borderRadius: "24px",
          },
        }}
        onClick={() => handleAddButton()}
      >
        Tambah Report
      </Button>
    </div>
  );
};

const mapStateToProps = ({ listReport, CheckLastReport }) => ({
  filters: getFilters(listReport),
  lastReport: getLastReport(CheckLastReport),
});

const mapDispatchToProps = { listReport, CheckLastReport };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderDaftarReport));
