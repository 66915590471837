import React from 'react'
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Button } from '@mui/material';
import SelectComponent from "../../components/commons/forms/select";
import Input from "../../components/commons/forms/authentication/input";
import { connect } from 'react-redux';
import { useState } from 'react';

import { listFeed, tambahFeed } from '../../store/actions/feed';
import {
    getListFeed,
    getFilters,
    getPageData,
    getSearchText
} from '../../store/reducers/feed';

const ModalTambahFeed = (props) => {

    const gradeOptions = [
        { value: `Pre starter`, label: "Pre Starter" },
        { value: `Starter`, label: "Starter" },
        { value: `Grower`, label: "Grower" },
        { value: `Finisher`, label: "Finisher" },
    ];

    const [kode_feed, setKodeFeed] = useState("");
    const [name_feed, setNameFeed] = useState("");
    const [grade_feed, setGradeFeed] = useState(gradeOptions[0].value);

    const [errorKodeFeed, setErrorKodeFeed] = useState("");
    const [errorNameFeed, setErrorNameFeed] = useState("");
    const [errorGradeFeed, setErrorGradeFeed] = useState("");

    const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);

    const { listFeedSuccess, pageData, filters, searchText } = props;

    const handleInput = (e, set, error, customRule) => {
        let value;

        if (e) value = e.target ? e.target.value : e.value;
        else {
            value = ``;
        }
        const rule = customRule ? customRule : e.target.getAttribute(`rule`);
        const kodeFeed = [...kode_feed];
        if (!value) {
            error(`${rule} wajib diisi`);
        } else {
            error(``);
        }
        set(value);
    };
    const handleError = (data) => {
        data.kode_feed ? setErrorKodeFeed("") : setErrorKodeFeed("Kode Feed wajib diisi");
        data.name_feed ? setErrorNameFeed("") : setErrorNameFeed("Nama Feed wajib diisi");
        data.grade_feed ? setErrorGradeFeed("") : setErrorGradeFeed("Grade Feed wajib diisi");
    };

    const handleButtonBatal = (e) => {
        setDefaultState();
        props.setShow(false);
    };

    const submitForm = () => {

        setDisableBtnSubmit(true);
        const data = {
            name_feed,
            kode_feed,
            grade_feed
        };

        handleError(data);

        for (var key in data) {
            if (data[key] === "") {
                setDisableBtnSubmit(false);
                return;
            }
        }

        props.tambahFeed(data, successTambah, failedTambah);
    };

    const failedTambah = () => {
        setDisableBtnSubmit(false);
    };

    const successTambah = () => {
        setTimeout(() => {
            props.setShow(false);
            setDefaultState();
            props.listFeed({
                length: pageData.lengthItem,
                page: 1,
                search_text: searchText,
                grade_feed: filters.grade_feed.value,
            });
            setDisableBtnSubmit(false);
        }, 1000);
    }

    const setDefaultState = () => {
        setGradeFeed(gradeOptions[0].value);
        setNameFeed("");
        setKodeFeed("");

        setErrorGradeFeed("");
        setErrorKodeFeed("");
        setErrorNameFeed("");
    };

    return (
        <>
            <Modal
                size={"md"}
                onHide={handleButtonBatal}
                show={props.show}
                centered>
                <ModalHeader closeButton>Tambah Feed</ModalHeader>
                <ModalBody>
                    <Input
                        label={"Kode Feed"}
                        placeholder={"Masukkan kode feed"}
                        onChange={(e) => handleInput(e, setKodeFeed, setErrorKodeFeed)}
                        value={kode_feed || ""}
                        rule={"Kode Feed"}
                        error={errorKodeFeed}
                    />
                    <Input
                        label={"Nama Feed"}
                        placeholder={"Masukkan nama feed"}
                        onChange={(e) => handleInput(e, setNameFeed, setErrorNameFeed)}
                        value={name_feed || ""}
                        rule={"Nama Feed"}
                        error={errorNameFeed}
                    />

                    <SelectComponent
                        options={gradeOptions}
                        label={"Grade Feed"}
                        isClearable={false}
                        placeholder={"Masukkan grade feed"}
                        error={errorGradeFeed}
                        isError={errorGradeFeed !== ``}
                        defaultValue={gradeOptions[0]}
                        onChange={(e) => handleInput(e, setGradeFeed, setErrorGradeFeed, "Grade Feed")}
                    />


                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" className="btn-btl-ubah-pass"
                        sx={{
                            paddingY: "10px",
                        }}
                        onClick={handleButtonBatal}
                    >
                        Batal
                    </Button>

                    <Button variant="contained" className="btn-simpan-ubah-pass"
                        sx={{
                            paddingY: "10px",
                        }}
                        onClick={(e) => submitForm()}
                        disabled={disableBtnSubmit}
                    >
                        Simpan
                    </Button>

                </ModalFooter>
            </Modal>
        </>
    )
}

function mapStateToProps({ listFeed }) {
    return {
        listFeedSuccess: getListFeed(listFeed),
        pageData: getPageData(listFeed),
        filters: getFilters(listFeed),
        searchText: getSearchText(listFeed),
    };
}

export default connect(mapStateToProps, { tambahFeed, listFeed })(ModalTambahFeed)
