import { ColorizeRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastError = (error, msg = "" , opt) => {
  let errMessage = "";
  if (error?.response && error?.response.status === 500) {
    errMessage = error?.response.data.message;
  } else {
    errMessage = error?.toString();
  }
  return toast(msg + errMessage, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    type: `error`,
    ...opt
  });
};

export const toastSuccess = (msg , opt) => {
  return toast(msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    type: `success`,
    ...opt
  });
};

export const toastWarning = (msg, opt) => {
  return toast(msg, {
    position: "top-right",
    autoClose: 1800,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    type: `warning`,
    ...opt
  });
};
