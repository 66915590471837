import React from "react";
import { bool } from "prop-types";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import API from "../../../../store/actions/API";
import { primary, danger, inactive } from "../../../../assets/styles/ui-kit/export_var.module.scss";

const colourStyles = {
  control: (style, state) => {
    let isError = state.selectProps.isError;
    return {
      ...style,
      backgroundColor: state.isDisabled ? inactive : isError ? "#fff6f7" : "#FFF",
      color: state.isDisabled ? "#b8b8d6" : state.hasValue ? "#222d17" : "",
      // match with the menu
      borderRadius: 4,
      // Overwrittes the different states  of border
      borderColor: isError ? danger : state.isFocused ? primary : state.hasValue ? primary : "#ced4da",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: isError ? danger : state.isFocused ? primary : state.hasValue ? primary : "#ced4da",
      },
      // Removes weird border around container
      fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
      fontSize: 14,
      width: "100%",
      height: "100%",
      // padding: "5px",
      paddingLeft: "5px",
      paddingTop: "3px",
      paddingBottom: "3px",
      outline: "none !important",
      boxShadow: "none",
      border: "1px solid #E5E5F0",
    };
  },
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
    fontSize: 14,
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  input: (styles) => {
    return {
      ...styles,
      fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
      "& input": {
        font: "inherit",
      },
    };
  },
  placeholder: (styles) => ({
    ...styles,
    fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
    fontSize: 14,
    color: "#495057",
    opacity: 0.8,
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
    fontSize: 14,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#9cc6a0",
      borderRadius: "4px",
      height: 30,
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
    fontSize: 14,
    // color: "#119E74",
    textAlign: "center",
    padding: "7px 5px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#119E74",
  }),
};

const SelectComponent = (props) => {
  const { asyncUrl, isAsync, options, isClearable, label, placeholder, error, isError, param = {}, wrapperStyle , customstyle } = props;
  const delayedQuery = _.debounce((inputValue, callback) => {
    param.search_text = inputValue;
    API.get(asyncUrl, { params: param }).then((res) => {
      callback(
        res.data.data.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    });
  }, 500);

  const loadOptions = (inputValue, callback) => {
    delayedQuery(inputValue, callback);
  };
  let newStyle = {};
  if(customstyle){
    newStyle = {
      ...colourStyles,
      control: (style, state) => {
        let isError = state.selectProps.isError;
        return {
          ...style,
          backgroundColor: state.isDisabled ? inactive : isError ? "#fff6f7" : "#FFF",
          color: state.isDisabled ? "#b8b8d6" : state.hasValue ? "#222d17" : "",
          // match with the menu
          borderRadius: 4,
          // Overwrittes the different states  of border
          borderColor: isError ? danger : state.isFocused ? primary : state.hasValue ? primary : "#ced4da",
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: isError ? danger : state.isFocused ? primary : state.hasValue ? primary : "#ced4da",
          },
          // Removes weird border around container
          fontFamily: `"PlusJakartaSans-Regular", "Open Sans", sans-serif`,
          fontSize: 14,
          width: "100%",
          height: "100%",
          // padding: "5px",
          paddingLeft: "5px",
          paddingTop: "3px",
          paddingBottom: "3px",
          outline: "none !important",
          boxShadow: "none",
          border: "1px solid #E5E5F0",
          ...customstyle,
        };
      },
    }
  }

  return (
    <div className={`input-wrapper ${error ? "error" : ""}`}>
      {/* {label && <label className="form-label">{label}</label>} */}
      <span style={{ fontWeight: "800" }}>{label}</span>
      {isAsync ? (
        <AsyncSelect placeholder={placeholder} isClearable={isClearable} styles={customstyle ? { ...newStyle } : { ...colourStyles }} loadOptions={loadOptions} defaultOptions={options} isError={isError} {...props} />
      ) : (
        <Select placeholder={placeholder} isClearable={isClearable} styles={customstyle ? { ...newStyle } : { ...colourStyles }} isError={isError} {...props} />
      )}
      {isError ? <span className="error-msg error-msg-show">{error}</span> : ``}
    </div>
  );
};

SelectComponent.propsTypes = {
  isClearable: bool,
};
SelectComponent.defaultProps = {
  isClearable: true,
};

export default SelectComponent;
