import React from "react";
import { useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton, MenuItem, Menu, Fade, Divider } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

import Page from "./PageReport";

import { listReport, CheckLastReport } from "../../../store/actions/farm/report";
import { getListReport, getListReportPending, getListReportError, getPageData, getFilters } from "../../../store/reducers/farm/report";
import { numberWithCommas } from "../../../utils/constants";

const Index = (props) => {
  const history = useHistory();
  const { pageData, listReportSuccess, listReportPending, listReportError, filters } = props;
  const id_farm = props.match.params.id;
  const [anchorEl, setAnchorEl] = useState([null, null, null, null, null, null, null, null, null, null]);
  const [popOpen, setPopOpen] = useState([false, false, false, false, false, false, false, false, false, false]);
  const [activePopMenu, setActivePopMenu] = useState(null);

  const handleEditButtonClick = (id_report) => {
    history.push({
      pathname: "/farmmanagement/" + id_farm + "/report/" + id_report + "/edit",
    });
    handleMoreButtonClose();
  };

  const handleDetailButtonClick = (id_report) => {
    history.push({
      pathname: "/farmmanagement/" + id_farm + "/report/" + id_report,
    });
    handleMoreButtonClose();
  };

  const handleMoreButtonClick = (data, event, index) => {
    let anchor = anchorEl;
    anchor[index] = event.currentTarget;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[index] = true;
    setPopOpen(temp);
    setActivePopMenu(index);
  };
  const handleMoreButtonClose = () => {
    let anchor = anchorEl;
    anchor[activePopMenu] = null;
    setAnchorEl(anchor);
    let temp = popOpen;
    temp[activePopMenu] = false;
    setPopOpen(temp);
    setActivePopMenu(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const styleMenuItem = {
    width: "147px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    props.listReport({
      id_farm,
      length: pageData.lengthItem,
      page: pageData.page,
      tanggal: filters.tanggal,
      status: filters.status.value,
    });
  }, [popOpen, anchorEl, filters]);

  useEffect(() => {
    props.CheckLastReport(props.match.params.id);
  }, []);

  const dateFormat = (date) => {
    const newDate = new Date(date);
    const hari = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    const bulan = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"][newDate.getMonth()];
    return hari + " " + bulan + " " + newDate.getFullYear();
  };

  const isCanEdit = (timestamp) => {
    let date = new Date(0);
    date.setUTCSeconds(timestamp);
    return date.toDateString() === new Date().toDateString();
  };

  const reportLists = (pending, data, error) => {
    if (pending)
      return (
        <TableRow>
          <TableCell colSpan={8} align="center">
            Mengambil data report
          </TableCell>
        </TableRow>
      );
    if (error)
      return (
        <TableRow>
          <TableCell colSpan={8} align="center">
            Gagal mengambil data report
          </TableCell>
        </TableRow>
      );
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={8} align="center">
            Data report tidak ditemukan
          </TableCell>
        </TableRow>
      );
    return data.map((row, i) => (
      <TableRow key={i}>
        <TableCell align="center">{i + 1}</TableCell>
        <TableCell>{dateFormat(row.tanggal)}</TableCell>
        <TableCell align="center">{numberWithCommas(row.jml_pakan_masuk)}</TableCell>
        <TableCell align="center">{numberWithCommas(row.jml_habis_pakan)}</TableCell>
        <TableCell align="center">{numberWithCommas(row.kematian)}</TableCell>
        <TableCell align="center">{numberWithCommas(row.ayam_terjual)}</TableCell>
        <TableCell align="center">{numberWithCommas(row.sisa_stok_ayam)}</TableCell>
        <TableCell>
          <div className={row.status_pengisian === "Ontime" ? "btn btn-status btn-sm bg-success rounded" : "btn btn-status btn-sm bg-warning rounded"}>{row.status_pengisian}</div>
        </TableCell>
        <TableCell align="center">
          <IconButton
            onClick={(e) => handleMoreButtonClick(row, e, i)}
            variant="contained"
            aria-controls={popOpen[i] ? id : undefined}
            aria-haspopup="true"
            aria-expanded={popOpen[i] ? "true" : undefined}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            elevation={1}
            id={popOpen[i] ? "simple-popover" : undefined}
            open={popOpen[i]}
            anchorEl={anchorEl[i]}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleMoreButtonClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleDetailButtonClick(row.id);
              }}
              sx={styleMenuItem}
            >
              Detail
            </MenuItem>
            <Divider variant="middle" />
            <MenuItem onClick={() => handleEditButtonClick(row.id)} sx={styleMenuItem} disabled={isCanEdit(row.created_at) ? false : true}>
              Edit
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <div className="align-items-center">
      <Divider />
      <Table size="small" className="table-user">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "800", paddingY: "16px" }}>
              NO
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }}>TANGGAL</TableCell>
            <TableCell sx={{ fontWeight: "800" }} align="center">
              PAKAN MASUK
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }} align="center">
              PAKAN TERPAKAI
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }} align="center">
              KEMATIAN AYAM
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }} align="center">
              AYAM TERJUAL
            </TableCell>
            <TableCell sx={{ fontWeight: "800" }} align="center">
              SISA STOK AYAM
            </TableCell>
            <TableCell colSpan={2} sx={{ fontWeight: "800" }}>
              STATUS PENGISIAN
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{reportLists(listReportPending, listReportSuccess, listReportError)}</TableBody>
      </Table>
      <Page pageData={pageData} />
    </div>
  );
};

const mapStateToProps = ({ listReport, CheckLastReport }) => {
  return {
    listReportSuccess: getListReport(listReport),
    listReportPending: getListReportPending(listReport),
    listReportError: getListReportError(listReport),
    pageData: getPageData(listReport),
    filters: getFilters(listReport),
  };
};

export default connect(mapStateToProps, { listReport, CheckLastReport })(withRouter(Index));
