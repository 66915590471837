import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { ListGroup, Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { InsertInvitation } from "@mui/icons-material";
import Accordion from "react-bootstrap/Accordion";
import InputSecond from "../../../../components/commons/forms/input";
import InputTree from "../../../../components/commons/forms/report/InputTree";
import NumericFormat from "react-number-format";
import { numberWithCommas, statusOptions, pemanasOptions } from "../../../../utils/constants";
import SelectReport from "../../../../components/commons/forms/report/SelectReport";
import SelectReportSecond from "../../../../components/commons/forms/report/SelecReportSecond";
import { useHistory } from "react-router-dom";
import CustomDatePicker from "../../../../components/commons/forms/datepicker/CustomDatePicker";
import { listReport, tambahReport, FeedReport, DocReport, CheckUmur, CheckKumulatif, CheckLastReport, KumulatifChickIn, populasiChickIn } from "../../../../store/actions/farm/report";
import {
  getListReport,
  getFeed,
  getFeedPending,
  getFeedError,
  getDoc,
  getDocPending,
  getDocError,
  getUmur,
  getUmurPending,
  getUmurError,
  getKumulatifPanen,
  getKumulatifPanenPending,
  getKumulatifPanenError,
  getKumulatifChickIn,
  getKumulatifChickInPending,
  getKumulatifChickInError,
  getLastReport,
  getLastReportPending,
  getLastReportError,
  getPopulasiChickIn,
  getPopulasiChickInPending,
  getPopulasiChickInError,
} from "../../../../store/reducers/farm/report";
import { convertTime, convertDate } from "../../../../utils/constants";
import AccordionBase from "../../../../components/report/AccordionBase";
import AccordionListGroup from "../../../../components/report/AccordionListGroup";
import BaseFormReport from "../../../../components/report/BaseFormReport";
import ModalDeleteObat from "./ModalDeleteObat";
import ModalDeletePenjualan from "./ModalDeletePenjualan";
import InputFoto from "../../../../components/commons/forms/report/InputFoto";
import * as calc from "../../../../utils/report/reportCalculation";
import { toastError } from "../../../../components/toast";
import { handleInput, handlePenjualanAdd, handlePenjualanChange, handlePenjualanDelete, handleObatAdd, handleObatChange, handleObatDelete, handleDOCAdd, handleDocChange, handleDOCDelete } from "../../../../utils/report";
import { syncDashboard } from "../../../../store/actions/dashboard";
import { formatDate } from "../../../../utils";
import ModalDeleteDoc from "./ModalDeleteDoc";
import DateRangePicker from "../../../../components/commons/forms/datepicker/DateRangePicker";

const Index = (props) => {
  const history = useHistory();
  const id_farm = props.match.params.id;
  const {
    listReportSuccess,
    umurSuccess,
    umurPending,
    umurError,
    feedSuccess,
    feedPending,
    feedError,
    docSuccess,
    docPending,
    docError,
    kumulatifPanen,
    kumulatifPanenPending,
    getKumulatifPanenError,
    KumulatifChickInSuccess,
    KumulatifChickInPending,
    KumulatifChickInError,
    lastReport,
    lastReportPending,
    lastReportError,
    populasiChickInSuccess,
    populasiChickInPending,
    populasiChickInError,
  } = props;
  // define variable tambah report for table daily report
  const [nama_kandang, setNamaKandang] = useState("");
  const [jam_pengisian, setJamPengisian] = useState(new Date());
  const [umur_ayam, setUmurAyam] = useState("");
  const [tanggal, setTanggal] = useState(new Date());
  const [populasi_chick_in, setPopulasiChickIn] = useState("");
  const [kematian, setKematian] = useState("");
  const [pakan, setPakan] = useState("");
  const [kode_pakan, setKodePakan] = useState("");
  const [jml_pakan_masuk, setJmlPakanMasuk] = useState("");
  const [jml_habis_pakan, setJmlHabisPakan] = useState("");
  const [populasi_saat_ini, setPopulasiSaatIni] = useState("0");
  const [berat_seluruh_ayam, setBeratSeluruhAyam] = useState("");
  const [deplesi, setDeplesi] = useState(0);
  const [fcr, setFcr] = useState(0);
  const [kuantitas, setKuantitas] = useState("");
  // define variable tambah report for table doc
  const [doc, setDoc] = useState([]);
  // define variable tambah report for table obat
  const [obat, setObat] = useState([]);
  // define variable tambah report for table panen
  const [penjualan, setPenjualan] = useState([]);
  const [berat_panen, setBeratPanen] = useState("");
  const [cum_umur, setCumUmur] = useState(0);
  const [ip, setIp] = useState(0);
  const [rup, setRup] = useState(0);
  const [stok_ayam, setStokAyam] = useState(0);
  //define varibale status kandang
  const [status_kandang, setStatusKandang] = useState("");
  // define variable tambah report for table pemanas
  const [nama_pemanas, setNamaPemanas] = useState("");
  const [jumlah_pemanas, setJumlahPemanas] = useState("");

  //define set erorr daily report
  const [errorBeratSeluruhAyam, setErrorBeratSeluruhAyam] = useState("");
  const [errorJmlPakanMasuk, setErrorJmlPakanMasuk] = useState("");
  const [errorJmlHabisPakan, setErrorJmlHabisPakan] = useState("");
  const [errorKematian, setErrorKematian] = useState("");
  const [errorPakan, setErrorPakan] = useState("");

  const [errorStatusKandang, setErrorStatusKandang] = useState("");
  const [errorNamaPemanas, setErrorNamaPemanas] = useState("");
  const [errorJmlPemanas, setErrorJmlPemanas] = useState("");
  const [errorPenjualan, setErrorPenjualan] = useState([]);
  const [errorObat, setErrorObat] = useState([]);
  const [errorDoc, setErrorDoc] = useState([]);

  const [disableBtnSubmit, setDisableBtnSubmit] = useState(false);
  const [showModalDeleteObat, setShowModalDeleteObat] = useState(false);
  const [showModalDeletePenjualan, setShowModalDeletePenjualan] = useState(false);
  const [showModalDeleteDoc, setShowModalDeleteDoc] = useState(false);
  const [dataDeleteObat, setDataDeleteObat] = useState({ index: "" });
  const [dataDeletePenjualan, setDataDeletePenjualan] = useState({ index: "" });
  const [disableTambahPenjualan, setDisableTambahPenjualan] = useState(false);
  const [dataDeleteDoc, setDataDeleteDoc] = useState({ index: "" });

  const dailyRef = useRef(null);
  const panenRef = useRef(null);
  const docRef = useRef(null);
  const obatRef = useRef(null);
  const pemanasRef = useRef(null);
  const sekamRef = useRef(null);
  const statusRef = useRef(null);

  useEffect(() => {
    props.DocReport();
    props.FeedReport();
    props.CheckUmur(id_farm);
    props.CheckKumulatif(id_farm);
    props.CheckLastReport(id_farm);
    props.KumulatifChickIn(id_farm);
    props.populasiChickIn(id_farm);
  }, []);

  // calculate populasi chick in
  useEffect(() => {
    let jumlahDoc = 0;
    if (doc?.length) {
      doc?.forEach((item) => {
        jumlahDoc += calc.toNum(item?.jumlah)
      })
    }
    setPopulasiChickIn(calc.toNum(jumlahDoc) + populasiChickInSuccess);
  }, [doc, populasiChickInSuccess]);

  useEffect(() => {
    if (status_kandang === "Panen") setDisableTambahPenjualan(false);
    else setDisableTambahPenjualan(true);
  }, [status_kandang]);

  //setDate and nama kandang
  useEffect(() => {
    if (lastReport) {
      const lastReportDate = new Date(lastReport.date);
      setNamaKandang(lastReport.nama_kandang);
      setTanggal(lastReportDate);
      setStatusKandang(lastReport.status_kandang);
    }
  }, [lastReport]);

  //setUmurAyam
  useEffect(() => {
    if (umurSuccess) {
      setUmurAyam(umurSuccess);
    } else {
      setUmurAyam(0);
    }
  }, [umurSuccess]);

  //calculate populasi saat ini
  useEffect(() => {
    setPopulasiSaatIni(calc.calculatePopulasiSaatIni(populasi_chick_in, KumulatifChickInSuccess.kum_kematian, kematian));
  }, [populasi_chick_in, kematian, KumulatifChickInSuccess]);

  const ayamTerjualHariIni = () => {
    return penjualan.reduce((sum, item) => sum + (item.jumlah ? calc.toNum(item.jumlah) : 0), 0);
  };

  const tonaseHariIni = () => {
    return penjualan.reduce((sum, item) => sum + (item.tonase ? calc.toNum(item.tonase) : 0), 0);
  };

  //calculate fcr
  useEffect(() => {
    if (status_kandang === "Panen") setFcr(calc.calculateFCR(KumulatifChickInSuccess.komulatifHabisPakan, jml_habis_pakan, kumulatifPanen.kumTonase, tonaseHariIni()));
    else setFcr(0);
  }, [status_kandang, KumulatifChickInSuccess, jml_habis_pakan, penjualan]);

  // calculate deplesi
  useEffect(() => {
    if (status_kandang === "Panen") setDeplesi(calc.calculateDeplesi(populasi_chick_in, kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
    else setDeplesi(0);
  }, [status_kandang, populasi_chick_in, kumulatifPanen, penjualan]);

  //calculate berat rata-rata
  useEffect(() => {
    if (status_kandang === "Panen") setBeratPanen(calc.calculateBeratRataRata(kumulatifPanen.kumTonase, tonaseHariIni(), kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
    else setBeratPanen(0);
  }, [status_kandang, kumulatifPanen, penjualan]);

  //calculate sisa stok ayam
  useEffect(() => {
    setStokAyam(calc.calculateSisaStokAyam(populasi_saat_ini, kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
  }, [kumulatifPanen, penjualan, populasi_saat_ini]);

  //calculate cum umur
  useEffect(() => {
    if (status_kandang === "Panen") setCumUmur(calc.calculateCumUmur(umur_ayam, ayamTerjualHariIni()));
    else setCumUmur(0);
  }, [status_kandang, penjualan]);

  //calculate RUP
  useEffect(() => {
    if (status_kandang === "Panen") setRup(calc.calculateRUP(kumulatifPanen.kumCumUmur, cum_umur, kumulatifPanen.kumAyamTerjual, ayamTerjualHariIni()));
  }, [status_kandang, kumulatifPanen, cum_umur]);

  // calculate IP
  useEffect(() => {
    if (status_kandang === "Panen") setIp(calc.calculateIP(deplesi, berat_panen, fcr, rup));
    else setIp(0);
  }, [status_kandang, deplesi, penjualan, fcr, rup]);

  const defaultDailyReport = () => {
    setNamaKandang(lastReport?.nama_kandang);
    setUmurAyam(umurSuccess);
    setPopulasiChickIn(String(populasi_chick_in));
    setKematian("");
    setPakan("");
    setKodePakan("");
    setJmlPakanMasuk("");
    setJmlHabisPakan("");
    setBeratSeluruhAyam("");
    setPopulasiSaatIni("");

    setErrorKematian("");
    setErrorPakan("");
    setErrorJmlPakanMasuk("");
    setErrorJmlHabisPakan("");
    setErrorBeratSeluruhAyam("");
  };
  const defaultPanen = () => {
    setPenjualan([]);
    setErrorPenjualan(
      penjualan.map(() => {
        return { jumlah: "", berat: "", nama: "" };
      })
    );
  };
  const defaultPemanas = () => {
    setNamaPemanas("");
    setJumlahPemanas("");

    setErrorNamaPemanas("");
    setErrorJmlPemanas("");
  };

  const setterError = (state, setError, fieldName) => {
    const errorMessage = state ? "" : `${fieldName} wajib diisi`;
    setError(errorMessage);
  };

  const validateDailyReport = (daily_report) => {
    for (const key in daily_report) {
      if (daily_report[key] === "" || daily_report[key] === null) {
        return false;
      }
    }
    return true;
  };

  const validatePenjualan = (penjualan) => {
    for (let i = 0; i < penjualan.length; i++) {
      if (penjualan[i].berat === "" || penjualan[i].jumlah === "" || penjualan[i].nama === "") {
        return false;
      }
    }
    return true;
  };

  const validateDocReport = (doc_report) => {
    if (doc_report === null || !doc_report?.length) {
      if (populasiChickInSuccess === 0 && status_kandang === "Chick In") return false;
      return true;
    }

    for (let i = 0; i < doc_report.length; i++) {
      for (const key in doc_report[i]) {
        if (doc_report[i][key] === "" || doc_report[i][key] === null) {
          return false;
        }
      }
    }

    return true;
  };

  const validateObat = (obat) => {
    for (let i = 0; i < obat.length; i++) {
      for (const key in obat[i]) {
        if (obat[i][key] === "") {
          return false;
        }
      }
    }
    return true;
  };

  const validatePemanas = (jumlah_pemanas, nama_pemanas) => {
    return !(jumlah_pemanas === "" || nama_pemanas === "");
  };

  const submitForm = () => {
    setDisableBtnSubmit(true);
    // Validate daily report fields
    const daily_report = {
      populasi_chick_in: calc.toNum(populasi_chick_in),
      kematian,
      pakan,
      jml_habis_pakan,
      jml_pakan_masuk,
      berat_seluruh_ayam,
    };
    setterError(kematian, setErrorKematian, "Kematian");
    setterError(pakan, setErrorPakan, "Pakan");
    setterError(jml_pakan_masuk, setErrorJmlPakanMasuk, "Jumlah pakan masuk");
    setterError(jml_habis_pakan, setErrorJmlHabisPakan, "Jumlah habis pakan");
    setterError(berat_seluruh_ayam, setErrorBeratSeluruhAyam, "Berat seluruh ayam");

    // Validate penjualan fields
    let panenObject = {
      stok_ayam,
      cum_umur,
      rup,
      ip,
      berat: numberWithCommas(berat_panen),
      penjualan,
    };
    let tempErrorPenjualan = [...errorPenjualan];
    let newErrPenjualan = [];
    penjualan.map((jual, i) => {
      let newErr = {
        jumlah: "",
        berat: "",
        nama: "",
        tonase: "",
      };
      if (jual.jumlah === "") {
        newErr.jumlah = "Jumlah wajib diisi";
      }
      if (jual.berat === "") {
        newErr.berat = "Berat rata-rata wajib diisi";
      }
      if (jual.tonase === "") {
        newErr.tonase = "Tonase wajib diisi";
      }
      if (jual.nama === "") {
        newErr.nama = "Nama pembeli wajib diisi";
      }
      newErrPenjualan = [
        ...tempErrorPenjualan.map((v, j) => {
          return i === j ? newErr : v;
        }),
      ];
      tempErrorPenjualan = newErrPenjualan;
    });
    setErrorPenjualan(newErrPenjualan);

    // Validate doc report fields
    let doc_report = [];
    if (doc.length !== 0) {
      let tempErrorDoc = [...errorDoc];
      let newErrDoc = [];
      doc.map((item, i) => {
        let newErr = { tanggal_masuk: "", merk: "", jumlah: "", bobot: "", kondisi: "" };
        if (!item.tanggal_masuk) newErr.tanggal_masuk = "Tanggal Masuk wajib diisi";
        if (!item.merk) newErr.merk = "Merk wajib diisi";
        if (!item.jumlah) newErr.jumlah = "Jumlah wajib diisi";
        if (!item.bobot) newErr.bobot = "Bobot wajib diisi";
        if (!item.kondisi) newErr.kondisi = "Kondisi wajib diisi";
        newErrDoc = [
          ...tempErrorDoc.map((v, j) => {
            return i === j ? newErr : v;
          }),
        ];
        tempErrorDoc = newErrDoc;
      });
      setErrorDoc(newErrDoc);

      doc_report = doc?.map((item) => ({
        ...item,
        id_doc : item?.merk?.value,
        tanggal_masuk: convertDate(item.tanggal_masuk)
      }));
    }

    // Validate obat fields
    if (obat.length !== 0) {
      let tempErrorObat = [...errorObat];
      let newErrObat = [];
      obat.map((obt, i) => {
        let newErr = { merk: "", jumlah: "" };
        if (obt.merk === "") newErr.merk = "Merk wajib diisi";
        if (obt.jumlah === "") newErr.jumlah = "Jumlah wajib diisi";
        newErrObat = [
          ...tempErrorObat.map((v, j) => {
            return i === j ? newErr : v;
          }),
        ];
        tempErrorObat = newErrObat;
      });
      setErrorObat(newErrObat);
    }

    // Validate pemanas fields
    let pemanasObject = null;
    if (validatePemanas(jumlah_pemanas, nama_pemanas)) {
      pemanasObject = {
        nama: nama_pemanas,
        jumlah: jumlah_pemanas,
      };

      setterError(nama_pemanas, setErrorNamaPemanas, "Nama pemanas");
      setterError(jumlah_pemanas, setErrorJmlPemanas, "Jumlah pemanas");
    } else {
      pemanasObject = null;
      setErrorNamaPemanas("");
      setErrorJmlPemanas("");
    }

    // Validate all sections
    const isDailyReportValid = validateDailyReport(daily_report);
    const isPenjualanValid = validatePenjualan(penjualan);
    const isDocReportValid = validateDocReport(doc_report);
    const isObatValid = validateObat(obat);

    if (!(isDailyReportValid && isPenjualanValid && isDocReportValid && isObatValid)) {
      setDisableBtnSubmit(false);
      toastError("Silakan periksa kembali isian form");
      return;
    }

    const payload = {
      id_farm,
      daily_report: {
        ...daily_report,
        deplesi: numberWithCommas(deplesi),
        fcr: numberWithCommas(fcr),
        id_kandang: id_farm,
        jam_pengisian: convertTime(jam_pengisian),
        kematian,
        kode_pakan,
        populasi_saat_ini,
        tanggal: convertDate(tanggal),
        umur_ayam,
      },
      panen: panenObject,
      doc_report,
      kuantitas: calc.toNum(kuantitas),
      obat,
      pemanas: pemanasObject,
      status: status_kandang,
    };
    props.tambahReport(payload, callbackSuccess, () => setDisableBtnSubmit(false));
  };

  const callbackSuccess = () => {
    setTimeout(() => {
      const payload = { date: formatDate(tanggal).replace(/\//g, "-"), farm: id_farm };
      props.syncDashboard(payload);
      setDisableBtnSubmit(false);
      history.push({ pathname: "/farmmanagement/" + id_farm + "/report" });
    }, 1000);
  };

  const handleBack = () => {
    history.push("/farmmanagement/" + id_farm + "/report");
  };

  const handleButtonBatalMerah = (type) => {
    switch (type) {
      case "daily":
        dailyRef.current.click();
        defaultDailyReport();
        break;
      case "panen":
        panenRef.current.click();
        defaultPanen();
        break;
      case "doc":
        docRef.current.click();
        setDoc([]);
        setErrorDoc([]);
        break;
      case "obat":
        obatRef.current.click();
        setObat([]);
        setErrorObat([]);
        break;
      case "pemanas":
        pemanasRef.current.click();
        defaultPemanas();
        break;
      case "sekam":
        sekamRef.current.click();
        setKuantitas("");
        break;
      case "status_kandang":
        statusRef.current.click();
        setStatusKandang(lastReport?.status_kandang);
        setErrorStatusKandang("");
        break;
    }
  };

  if (umurPending || feedPending || docPending || kumulatifPanenPending || KumulatifChickInPending || lastReportPending || populasiChickInPending) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ minWidth: "100%", minHeight: "100vh" }}>
        Mengambil data report...
      </div>
    );
  } else if (umurError || feedError || docError || getKumulatifPanenError || KumulatifChickInError || lastReportError || populasiChickInError) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ minWidth: "100%", minHeight: "100vh" }}>
        Gagal mengambil data report...
      </div>
    );
  } else {
    return (
      <BaseFormReport title={"Tambah Report"} handleBack={handleBack}>
        <ModalDeleteObat data={dataDeleteObat} error={errorObat} setError={setErrorObat} obat={obat} setObat={setObat} show={showModalDeleteObat} setShow={setShowModalDeleteObat} />
        <ModalDeletePenjualan
          data={dataDeletePenjualan}
          error={errorPenjualan}
          setError={setErrorPenjualan}
          penjualan={penjualan}
          setPenjualan={setPenjualan}
          show={showModalDeletePenjualan}
          setShow={setShowModalDeletePenjualan}
        />
        <ModalDeleteDoc
          data={dataDeleteDoc}
          error={errorDoc}
          setError={setErrorDoc}
          doc={doc}
          setDoc={setDoc}
          show={showModalDeleteDoc}
          setShow={setShowModalDeleteDoc} 
        />

        {/* untuk acordion Daily Report */}
        <AccordionBase title={"Daily Report"} accordionRef={dailyRef}>
          <AccordionListGroup>
            <InputSecond label={"Nama Kandang"} value={nama_kandang} readOnly={true} />
            <CustomDatePicker
              label={"Tanggal"}
              type={"date"}
              leftIcon={<InsertInvitation style={{ color: "A5A5C0" }} />}
              placeholder={"Masukkan tanggal"}
              selected={tanggal}
              defaultValue={tanggal || ""}
              wrapperClassName="datepicker"
              className="form-control"
              dateFormat="d MMMM yyyy"
              onChange={(e) => setTanggal(e)}
              readOnly={lastReport?.first_report}
            />
            <CustomDatePicker
              label={"Jam Pengisian"}
              leftIcon={<InsertInvitation style={{ color: "A5A5C0" }} />}
              selected={jam_pengisian.getTime()}
              value={jam_pengisian}
              wrapperClassName="datepicker"
              className="form-control"
              dateFormat="HH:mm"
              readOnly={true}
            />
            <InputSecond label={"Umur Ayam (hari)"} style={{ color: "black" }} value={umurSuccess} readOnly={true} />
          </AccordionListGroup>
          <AccordionListGroup>
            <div className="row">
              <div className="col-md-6">
                <NumericFormat
                  label={"Populasi Saat Chick In (ekor)"}
                  customInput={InputTree}
                  placeholder={"Masukkan populasi saat chick in"}
                  thousandSeparator="."
                  decimalSeparator=","
                  value={populasi_chick_in}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6">
                <NumericFormat
                  label={"Kematian (ekor)"}
                  customInput={InputTree}
                  placeholder={"Masukkan Kematian Hari Ini"}
                  thousandSeparator="."
                  decimalSeparator=","
                  value={kematian || ""}
                  onChange={(e) => handleInput(e, setKematian, setErrorKematian, undefined, undefined, false)}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  rule={`Kematian`}
                  error={errorKematian}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectReport
                  options={feedSuccess == null ? {} : feedSuccess}
                  placeholder={"Masukkan Pakan"}
                  label={"Pakan"}
                  isAsync={true}
                  asyncUrl={"/v1/master_data/feed"}
                  isPakan={true}
                  value={pakan ? { value: pakan, label: pakan } : ""}
                  onChange={(e) => handleInput(e, setPakan, setErrorPakan, "Pakan", (v) => setKodePakan(v ? v.kode : ""), false)}
                  error={errorPakan}
                  isError={errorPakan !== ``}
                />
              </div>
              <div className="col-md-6">
                <InputTree style={{ color: "black" }} label={"Kode Pakan"} placeholder={"Masukkan kode pakan"} readOnly={true} value={kode_pakan} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <NumericFormat
                  label={"Jumlah Pakan Masuk (sak)"}
                  customInput={InputTree}
                  placeholder={"Masukkan jumlah pakan masuk"}
                  thousandSeparator="."
                  decimalSeparator=","
                  value={jml_pakan_masuk || ""}
                  onChange={(e) => handleInput(e, setJmlPakanMasuk, setErrorJmlPakanMasuk, undefined, undefined, false)}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  rule={`Jumlah pakan masuk`}
                  error={errorJmlPakanMasuk}
                />
              </div>
              <div className="col-md-6">
                <NumericFormat
                  label={"Jumlah Habis Pakan (sak)"}
                  customInput={InputTree}
                  placeholder={"Masukkan jumlah habis pakan"}
                  thousandSeparator="."
                  decimalSeparator=","
                  value={jml_habis_pakan || ""}
                  onChange={(e) => handleInput(e, setJmlHabisPakan, setErrorJmlHabisPakan, undefined, undefined, false)}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  rule={`Jumlah habis pakan`}
                  error={errorJmlHabisPakan}
                />
              </div>
            </div>
            <div className="row">
              <NumericFormat
                label={"Berat Seluruh Ayam (kg)"}
                customInput={InputTree}
                placeholder={"Masukkan berat seluruh ayam"}
                thousandSeparator="."
                decimalSeparator=","
                value={berat_seluruh_ayam || ""}
                onChange={(e) => handleInput(e, setBeratSeluruhAyam, setErrorBeratSeluruhAyam, undefined, undefined, false)}
                onKeyPress={(e) => !/[0-9,]/.test(e.key) && e.preventDefault()}
                rule={`Berat seluruh ayam`}
                error={errorBeratSeluruhAyam}
              />
            </div>
          </AccordionListGroup>
          <AccordionListGroup backgroundColor={"#F4F5F7"}>
            <Row style={{ marginBottom: "43px" }}>
              <Col md={5}>
                <span className="text-bold">Populasi Saat Ini</span>
              </Col>
              {listReportSuccess[0]?.populasi_chick_in ? <Col>{numberWithCommas(populasi_saat_ini) + " ekor" || 0}</Col> : <Col>{numberWithCommas(populasi_saat_ini) + " ekor" || 0}</Col>}
            </Row>
            <Row style={{ marginBottom: "43px" }}>
              <Col md={5}>
                <span className="text-bold">Food Conversion Ratio (FCR)</span>
              </Col>
              <Col>{numberWithCommas(fcr) || "0"}</Col>
            </Row>
            <Row style={{ marginBottom: "43px" }}>
              <Col md={5}>
                <span className="text-bold">Deplesi</span>
              </Col>
              <Col md={5}>
                <span className={parseFloat(deplesi) <= 0 ? "text-hijau" : "text-merah"}>{numberWithCommas(deplesi) + " %"}</span>
              </Col>
            </Row>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("daily")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Penjualan hasil panen */}
        <AccordionBase title={"Penjualan Hasil Panen"} accordionRef={panenRef}>
          <AccordionListGroup>
            {penjualan.map((jual, index) => (
              <ListGroup style={{ borderRadius: "12px" }} className="me-5 mt-5" key={index}>
                <ListGroup.Item className="pt-4 pb-4 ps-5 pe-5">
                  <span
                    className="position-absolute top-0 start-100 translate-middle p-2 bg-danger-2 border border-white shadow-sm rounded-circle"
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePenjualanDelete(index, jual.id, setDataDeletePenjualan, setShowModalDeletePenjualan)}
                  >
                    <Close style={{ color: "white" }} />
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <NumericFormat
                        label={"Jumlah (ekor)"}
                        placeholder={"Masukkan jumlah ayam terjual"}
                        customInput={InputTree}
                        thousandSeparator="."
                        decimalSeparator=","
                        onChange={(e) => handlePenjualanChange(e, index, "jumlah", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        value={jual?.jumlah || ""}
                        rule={`Jumlah Ayam Terjual`}
                        error={errorPenjualan[index]?.jumlah}
                      />
                    </div>

                    <div className="col-md-6">
                      <NumericFormat
                        label={"Berat Rata-Rata (kg)"}
                        placeholder={"Masukkan berat rata-rata (kg)"}
                        customInput={InputTree}
                        thousandSeparator="."
                        decimalSeparator=","
                        onChange={(e) => handlePenjualanChange(e, index, "berat", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        onKeyPress={(e) => !/[0-9,]/.test(e.key) && e.preventDefault()}
                        rule={`Berat Rata-Rata`}
                        error={errorPenjualan[index]?.berat}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <NumericFormat
                        label={"Tonase (kg)"}
                        placeholder={"Masukkan tonase"}
                        customInput={InputTree}
                        thousandSeparator="."
                        decimalSeparator=","
                        onChange={(e) => handlePenjualanChange(e, index, "tonase", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        onKeyPress={(e) => !/[0-9,]/.test(e.key) && e.preventDefault()}
                        value={jual?.tonase || ""}
                        rule={`Tonase`}
                        error={errorPenjualan[index]?.tonase}
                      />
                    </div>

                    <div className="col-md-6">
                      <InputTree
                        label={"Nama Pembeli"}
                        placeholder={"Masukkan nama pembeli"}
                        onChange={(e) => handlePenjualanChange(e, index, "nama", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        value={jual?.nama || ""}
                        error={errorPenjualan[index]?.nama}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <InputFoto
                        label={"Foto"}
                        onChange={(e) => handlePenjualanChange(e, index, "foto", penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)}
                        prevImage={penjualan[index].foto}
                        error={errorPenjualan[index]?.foto}
                      />
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
            <div className="row">
              <div className="col-md-12 text-center">
                <Button variant="outlined" className="mt-5 mb-5 btn-tambah-data" onClick={() => handlePenjualanAdd(penjualan, setPenjualan, errorPenjualan, setErrorPenjualan)} disabled={disableTambahPenjualan}>
                  Tambah Data
                </Button>
              </div>
            </div>
          </AccordionListGroup>
          <Accordion.Body style={{ padding: "0px" }}>
            <AccordionListGroup backgroundColor={"#F4F5F7"}>
              <Row style={{ marginBottom: "38px" }}>
                <Col md={5}>
                  <span className="text-bold">Berat Rata-Rata</span>
                </Col>
                <Col md={5}>{numberWithCommas(berat_panen) + " kg"}</Col>
              </Row>
              <Row style={{ marginBottom: "38px" }}>
                <Col md={5}>
                  <span className="text-bold">Sisa Stok Ayam</span>
                </Col>
                <Col>{numberWithCommas(stok_ayam) + " ekor"}</Col>
              </Row>
              <Row style={{ marginBottom: "38px" }}>
                <Col md={5}>
                  <span className="text-bold">Kumulatif Umur</span>
                </Col>
                <Col>{numberWithCommas(cum_umur)}</Col>
              </Row>
              <Row style={{ marginBottom: "38px" }}>
                <Col md={5}>
                  <span className="text-bold">RUP</span>
                </Col>
                <Col>{numberWithCommas(rup)}</Col>
              </Row>
              <Row style={{ marginBottom: "38px" }}>
                <Col md={5}>
                  <span className="text-bold">Indeks Performance (IP)</span>
                </Col>
                <Col md={2}>
                  <div className={parseFloat(ip) > 350 ? "badge-sangatBaik" : parseFloat(ip) > 300 && parseFloat(ip) < 350 ? "badge-baik" : "badge-kurangBaik"} style={{ inlineSize: "min-content" }}>
                    <span> {numberWithCommas(ip)} </span>
                  </div>
                </Col>
              </Row>
            </AccordionListGroup>
          </Accordion.Body>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("panen")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion DOC */}
        <AccordionBase title={"DOC"} accordionRef={docRef}>
          <AccordionListGroup>
          {doc?.map((item, i) => (
            <ListGroup style={{ borderRadius: "12px" }} className="me-5 mt-5" key={i}>
              <ListGroup.Item className="pt-4 pb-4 ps-5 pe-5">
                <span
                  className="position-absolute top-0 start-100 translate-middle p-2 bg-danger-2 border border-white shadow-sm rounded-circle"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDOCDelete(i, item.id, setDataDeleteDoc, setShowModalDeleteDoc)}
                >
                  <Close style={{ color: "white" }} />
                </span>
                <div className="row">
                  <div className="col-md-6">
                    <CustomDatePicker
                      displayColumn={true}
                      label={"Tanggal Masuk"}
                      type={"date"}
                      leftIcon={<InsertInvitation style={{ color: "A5A5C0" }} />}
                      placeholder={"Masukkan tanggal masuk"}
                      wrapperClassName="datepicker"
                      className="form-control"
                      dateFormat="d MMMM yyyy"
                      onChange={(e) => handleDocChange(e, i, "tanggal_masuk", doc, setDoc, errorDoc, setErrorDoc)}
                      selected={item?.tanggal_masuk}
                      defaultValue={item?.tanggal_masuk || ""}
                      error={errorDoc[i]?.tanggal_masuk}
                    />
                  </div>
                  <div className="col-md-6">
                    <SelectReport
                      options={docSuccess == null ? {} : docSuccess}
                      placeholder={"Masukkan merk"}
                      label={"Merk"}
                      isAsync={true}
                      asyncUrl={"/v1/master_data/doc"}
                      onChange={(e) => handleDocChange(e, i, "merk", doc, setDoc, errorDoc, setErrorDoc)}
                      value={item?.merk || ""}
                      error={errorDoc[i]?.merk}
                      isError={errorDoc[i]?.merk !== ``}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <NumericFormat
                      label={"Jumlah"}
                      placeholder={"Masukkan jumlah"}
                      customInput={InputTree}
                      thousandSeparator="."
                      decimalSeparator=","
                      rule={`Jumlah DOC`}
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      onChange={(e) => handleDocChange(e.target.value, i, "jumlah", doc, setDoc, errorDoc, setErrorDoc)}
                      value={item?.jumlah || ""}
                      error={errorDoc[i]?.jumlah}
                    />
                  </div>
                  <div className="col-md-6">
                    <NumericFormat
                      label={"Bobot (gr)"}
                      placeholder={"Masukkan bobot (gr)"}
                      customInput={InputTree}
                      thousandSeparator="."
                      decimalSeparator=","
                      rule={"Bobot"}
                      onKeyPress={(e) => !/[0-9,]/.test(e.key) && e.preventDefault()}
                      onChange={(e) => handleDocChange(e.target.value, i, "bobot", doc, setDoc, errorDoc, setErrorDoc)}
                      value={item?.bobot || ""}
                      error={errorDoc[i]?.bobot}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <InputTree
                      style={{ color: "black" }}
                      label={"Kondisi"}
                      placeholder={"Masukkan kondisi"}
                      rule={"Kondisi"}
                      onChange={(e) => handleDocChange(e.target.value, i, "kondisi", doc, setDoc, errorDoc, setErrorDoc)}
                      value={item?.kondisi || ""}
                      error={errorDoc[i]?.kondisi}
                    />
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          ))}
            <div className="row">
              <div className="col-md-12 text-center">
                <Button variant="outlined" className="mt-5 mb-5 btn-tambah-data" onClick={() => handleDOCAdd(doc, setDoc, errorDoc, setErrorDoc)}>
                  Tambah Data
                </Button>
              </div>
            </div>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("doc")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Pemakaian obat dan vitamin */}
        <AccordionBase title={"Pemakaian Obat & Vitamin"} accordionRef={obatRef}>
          <AccordionListGroup>
            {obat?.map((o, i) => (
              <ListGroup style={{ borderRadius: "12px" }} className="me-5 mt-5" key={i}>
                <ListGroup.Item className="pt-4 pb-4 ps-5 pe-5">
                  <span
                    className="position-absolute top-0 start-100 translate-middle p-2 bg-danger-2 border border-white shadow-sm rounded-circle"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleObatDelete(i, o.id, setDataDeleteObat, setShowModalDeleteObat)}
                  >
                    <Close style={{ color: "white" }} />
                  </span>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTree
                        label={"Merk Obat"}
                        placeholder={"Masukkan merk obat"}
                        onChange={(e) => handleObatChange(e, i, "merk", obat, setObat, errorObat, setErrorObat)}
                        value={o?.merk || ""}
                        error={errorObat[i]?.merk}
                      />
                    </div>

                    <div className="col-md-6">
                      <NumericFormat
                        label={"Jumlah"}
                        placeholder={"Masukkan jumlah"}
                        customInput={InputTree}
                        thousandSeparator="."
                        decimalSeparator=","
                        onChange={(e) => handleObatChange(e, i, "jumlah", obat, setObat, errorObat, setErrorObat)}
                        value={o?.jumlah || ""}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        error={errorObat[i]?.jumlah}
                      />
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
            <div className="row">
              <div className="col-md-12 text-center">
                <Button variant="outlined" className="mt-5 mb-5 btn-tambah-data" onClick={() => handleObatAdd(obat, setObat, errorObat, setErrorObat)}>
                  Tambah Data
                </Button>
              </div>
            </div>
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("panen")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Pemanas */}
        <AccordionBase title={"Pemanas"} accordionRef={pemanasRef}>
          <AccordionListGroup>
            <SelectReportSecond
              options={pemanasOptions}
              placeholder={"Masukkan jenis pemanas"}
              label={"Jenis Pemanas"}
              value={nama_pemanas ? { value: nama_pemanas, label: nama_pemanas } : ""}
              onChange={(e) => handleInput(e, setNamaPemanas, setErrorNamaPemanas, "Jenis pemanas", undefined, true)}
              error={errorNamaPemanas}
            />

            <NumericFormat
              label={"Jumlah"}
              placeholder={"Masukkan jumlah pemanas"}
              customInput={InputSecond}
              thousandSeparator="."
              decimalSeparator=","
              onChange={(e) => handleInput(e, setJumlahPemanas, setErrorJmlPemanas, undefined, undefined, true)}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              value={jumlah_pemanas || ""}
              error={errorJmlPemanas}
            />
          </AccordionListGroup>

          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("pemanas")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Sekam */}
        <AccordionBase title={"Sekam"} accordionRef={sekamRef}>
          <AccordionListGroup>
            <NumericFormat
              label={"Kuantitas (sak)"}
              placeholder={"Masukkan kuantitas"}
              customInput={InputSecond}
              thousandSeparator="."
              decimalSeparator=","
              onChange={(e) => handleInput(e, setKuantitas)}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              value={kuantitas || ""}
            />
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("sekam")}>
            Batal
          </div>
        </AccordionBase>
        {/* untuk acordion Status Kandang */}
        <AccordionBase title={"Status Kandang"} accordionRef={statusRef}>
          <AccordionListGroup>
            <SelectReportSecond
              options={statusOptions}
              label={"Status Kandang"}
              placeholder={"Masukkan status kandang"}
              value={lastReport?.status_kandang ? { value: status_kandang, label: status_kandang } : { value: "", label: "Masukkan status kandang" }}
              isAsync={true}
              asyncUrl={"/v1/master_data/farm"}
              onChange={(e) => handleInput(e, setStatusKandang, setErrorStatusKandang, "Status Kandang")}
              isError={errorStatusKandang !== ``}
              error={errorStatusKandang}
            />
          </AccordionListGroup>
          <div className="accordion-list-button-batal" onClick={() => handleButtonBatalMerah("status_kandang")}>
            Batal
          </div>
        </AccordionBase>
        <div className="row mt-5 mb-5 pb-5 d-flex flex-row-reverse">
          <Button variant="contained" className="btn-simpan-report" onClick={(e) => submitForm()} disabled={disableBtnSubmit}>
            Simpan
          </Button>

          <Button variant="outlined" className="btn-btl-report" onClick={handleBack}>
            Batal
          </Button>
        </div>
      </BaseFormReport>
    );
  }
};

const mapStateToProps = ({ FeedReport, DocReport, CheckUmur, listReport, CheckKumulatif, CheckLastReport, KumulatifChickIn, populasiChickIn }) => {
  return {
    listReportSuccess: getListReport(listReport),
    feedSuccess: getFeed(FeedReport),
    feedPending: getFeedPending(FeedReport),
    feedError: getFeedError(FeedReport),
    docSuccess: getDoc(DocReport),
    docPending: getDocPending(DocReport),
    docError: getDocError(DocReport),
    umurSuccess: getUmur(CheckUmur),
    umurPending: getUmurPending(CheckUmur),
    umurError: getUmurError(CheckUmur),
    kumulatifPanen: getKumulatifPanen(CheckKumulatif),
    kumulatifPanenPending: getKumulatifPanenPending(CheckKumulatif),
    kumulatifPanenError: getKumulatifPanenError(CheckKumulatif),
    KumulatifChickInSuccess: getKumulatifChickIn(KumulatifChickIn),
    KumulatifChickInPending: getKumulatifChickInPending(KumulatifChickIn),
    KumulatifChickInError: getKumulatifChickInError(KumulatifChickIn),
    lastReport: getLastReport(CheckLastReport),
    lastReportPending: getLastReportPending(CheckLastReport),
    lastReportError: getLastReportError(CheckLastReport),
    populasiChickInSuccess: getPopulasiChickIn(populasiChickIn),
    populasiChickInPending: getPopulasiChickInPending(populasiChickIn),
    populasiChickInError: getPopulasiChickInError(populasiChickIn),
  };
};

export default connect(mapStateToProps, {
  listReport,
  FeedReport,
  DocReport,
  CheckUmur,
  tambahReport,
  CheckKumulatif,
  KumulatifChickIn,
  CheckLastReport,
  populasiChickIn,
  syncDashboard,
})(Index);
