import {FormCheck} from "react-bootstrap"

const Index = (props) => {

    const handleRadio = e => {
        // console.log(props.status,props.status === 'aktif' , props.status === 'nonaktif')
        props.setStatus(e.target.value)
    }

    return (
        <div className="form-group radio-wrapper">
            <div className="form-label">Status</div>
            <div className="radio-value">
                <div className="radio-item">
                    <div>
                        <input value={"Aktif"} name={"status"} checked={props.status === 'Aktif'} type={"radio"} onChange={e => handleRadio(e)} />
                    </div>
                    <span>Aktif</span>
                </div>
                <div className="radio-item">
                    <div>
                        <input value={"Nonaktif"} name={"status"} checked={props.status === 'Nonaktif'} type={"radio"} onChange={e => handleRadio(e)} />
                    </div>
                    <span>Nonaktif</span>
                </div>
            </div>
        </div>
    )
}

export default Index;